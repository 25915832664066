import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { CreateContactUsDto } from '../../types/support/CreateContactUsDto';
import { UpdateContactUsDto } from '../../types/support/UpdateContactUsDto';

export const GET_NOTICES = 'api/GET_NOTICES';
export const GET_NOTICES_SUCCESS = 'api/GET_NOTICES_SUCCESS';
export const GET_NOTICES_ERROR = 'api/GET_NOTICES_ERROR';
export const GET_NOTICE_BY_ID = 'api/GET_NOTICE';
export const GET_NOTICE_BY_ID_SUCCESS = 'api/SET_NOTICES_SUCCESS';
export const GET_NOTICE_BY_ID_ERROR = 'api/SET_NOTICES_ERROR';

export const GET_FAQS = 'api/GET_FAQS';
export const GET_FAQS_SUCCESS = 'api/GET_FAQS_SUCCESS';
export const GET_FAQS_ERROR = 'api/GET_FAQS_ERROR';

export const GET_CONTACT_US = 'api/GET_CONTACT_US';
export const GET_CONTACT_US_SUCCESS = 'api/GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_ERROR = 'api/GET_CONTACT_US_ERROR';
export const GET_CONTACT_US_BY_ID = 'api/GET_CONTACT_US_BY_ID';
export const GET_CONTACT_US_BY_ID_SUCCESS = 'api/GET_CONTACT_US_BY_ID_SUCCESS';
export const GET_CONTACT_US_BY_ID_ERROR = 'api/GET_CONTACT_US_BY_ID_ERROR';

export const POST_CONTACT_US = 'api/POST_CONTACT_US';
export const POST_CONTACT_US_SUCCESS = 'api/POST_CONTACT_US_SUCCESS';
export const POST_CONTACT_US_ERROR = 'api/POST_CONTACT_US_ERROR';

export const PUT_CONTACT_US = 'api/PUT_CONTACT_US';
export const PUT_CONTACT_US_SUCCESS = 'api/PUT_CONTACT_US_SUCCESS';
export const PUT_CONTACT_US_ERROR = 'api/PUT_CONTACT_US_ERROR';

export const DELETE_CONTACT_US = 'api/DELETE_CONTACT_US';
export const DELETE_CONTACT_US_SUCCESS = 'api/DELETE_CONTACT_US_SUCCESS';
export const DELETE_CONTACT_US_ERROR = 'api/DELETE_CONTACT_US_ERROR';

export const getNoticesAsync = createAsyncAction(
  GET_NOTICES,
  GET_NOTICES_SUCCESS,
  GET_NOTICES_ERROR
)<SearchSupportDto, any , AxiosError>();

export const getNoticeAsync = createAsyncAction(
  GET_NOTICE_BY_ID,
  GET_NOTICE_BY_ID_SUCCESS,
  GET_NOTICE_BY_ID_ERROR
)<SearchSupportDto, any  , AxiosError>();

export const getFaqsAsync = createAsyncAction(
  GET_FAQS,
  GET_FAQS_SUCCESS,
  GET_FAQS_ERROR
)<SearchSupportDto, any  , AxiosError>();

export const getContactUsListAsync = createAsyncAction(
  GET_CONTACT_US,
  GET_CONTACT_US_SUCCESS,
  GET_CONTACT_US_ERROR
)<SearchSupportDto, any  , AxiosError>();

export const getContactUsAsync = createAsyncAction(
  GET_CONTACT_US_BY_ID,
  GET_CONTACT_US_BY_ID_SUCCESS,
  GET_CONTACT_US_BY_ID_ERROR
)<number, any  , AxiosError>();

export const postContactUsAsync = createAsyncAction(
  POST_CONTACT_US,
  POST_CONTACT_US_SUCCESS,
  POST_CONTACT_US_ERROR
)<CreateContactUsDto, any  , AxiosError>();

export const putContactUsAsync = createAsyncAction(
  PUT_CONTACT_US,
  PUT_CONTACT_US_SUCCESS,
  PUT_CONTACT_US_ERROR
)<UpdateContactUsDto, any  , AxiosError>();

export const deleteContactUsAsync = createAsyncAction(
  DELETE_CONTACT_US,
  DELETE_CONTACT_US_SUCCESS,
  DELETE_CONTACT_US_ERROR
)<number, any  , AxiosError>();