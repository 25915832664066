import React,{FC} from 'react';
import { BarChart, Bar, PieChart , Pie , Cell , XAxis, YAxis, Tooltip, CartesianGrid,Legend  } from 'recharts';

type ButtonProps = {
    width: number;
    height: number;
    fillColor :  string
    data: any[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const donutChart : FC<ButtonProps> = ({width,height,fillColor,data}) => { 
    return (
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          cx={140}
          cy={100}
          innerRadius={60}
          outerRadius={80}
          fill={fillColor}
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={fillColor} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    );
}
export default donutChart;