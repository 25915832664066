import { FC } from 'react';
import '../styles/components/tab.scss';

interface TabProbs{
    title: string;
    count?: number;
    controls?: string;
    id: number;

}

interface TabComponentProps {
    tabs: TabProbs[];
    role?: string;
    current?: number;
    onClick?: (index: number, tab: TabProbs) => void;
}

const TabComponent:FC<TabComponentProps> = ({ tabs , current, role, onClick }) => {    
    return(
        <div className='category-list'>
            {tabs.map((tab, index) => (
                <button type='button' 
                className='button-trigger'
                key={index}
                aria-pressed={ tab.id === current  ? 'true' : 'false'}
                {...(role === 'tab' ? { role: 'tab' } : {})}
                {...(tab.controls && { 'aria-controls': tab.controls })}
                onClick={() => onClick && onClick(index, tab)}
                >
                    <span>{tab.title}</span>
                    {/* {tab.count && <em>({tab.count})</em>} */}
                </button>
            ))}
        </div>
    )
}

export default TabComponent;