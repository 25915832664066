import { FC, useEffect,useState } from "react";
import '../styles/components/sort-display.scss';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import { useDispatch, useSelector } from "react-redux"


interface sortFormProps {
    total? : number;
    currentTotal? : number;
    defSearchParams : SearchSupportDto;
    onSearch: (newSearchParams: SearchSupportDto) => void;
}

const SortDisplay:FC<sortFormProps> = ({ total, currentTotal , defSearchParams , onSearch }) => {
    const dispatch = useDispatch();
   
    const [searchParams, setFormData] = useState<SearchSupportDto>({});
    
    const handleSelectChangeByOrderBy = async (e: React.ChangeEvent<HTMLSelectElement>) => { 
        const newSearchOrderBy = e.target.value;
            // 상태 업데이트
       setFormData((searchParams) => {
          const updatedParams = { ...searchParams, searchOrderBy: newSearchOrderBy };
          // 상태가 업데이트된 후 onSearch 호출
          onSearch(updatedParams);
          return updatedParams; // 업데이트된 값을 반환
       })
        
    };

    const handleSelectChangeBypageUnit = async (e: React.ChangeEvent<HTMLSelectElement>) => {      
       const newPageUnit = Number(e.target.value);
            // 상태 업데이트
       setFormData((searchParams) => {
          const updatedParams = { ...searchParams, pageUnit: newPageUnit };
          // 상태가 업데이트된 후 onSearch 호출
          onSearch(updatedParams);
          return updatedParams; // 업데이트된 값을 반환
       })
    };
    
    return (
        <div className={`board-controls ${total  !== undefined ? '' : 'end' }`}>
        {total !== undefined &&(
            <span className='board-display'><span>총 게시물</span> <em>({currentTotal}건</em>/{total}건)</span>
        )
        }
        <div className='board-sort'>
            <select id="searchOrderBy" name="searchOrderBy"  className='form-select' title='정렬 선택' onChange={handleSelectChangeByOrderBy} defaultValue={defSearchParams.searchOrderBy}>
                <option value="REG_DT">최신순</option>
                <option value="TYPE_CD">구분</option>
                <option value="TTL">제목</option>
            </select>
            <select id="pageUnit" name="pageUnit" className='form-select' title='게시판 목록 수 선택' onChange={handleSelectChangeBypageUnit} defaultValue={defSearchParams.pageUnit}>
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="30">30개씩 보기</option>
                <option value="40">40개씩 보기</option>
                <option value="50">50개씩 보기</option>
            </select>
        </div>
    </div>
    )
}

export default SortDisplay;