import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { UserDto } from '../../types/UserDto';
import { ErrorResponse, SuccessResponse } from '../../types/Response';

export const GET_USER = 'api/GET_USER';
export const GET_USER_SUCCESS = 'api/GET_USER_SUCCESS';
export const GET_USER_ERROR = 'api/GET_USER_ERROR';
export const SET_USER = 'api/SET_USER';
export const SET_USER_SUCCESS = 'api/SET_USER_SUCCESS';
export const SET_USER_ERROR = 'api/SET_USER_ERROR';
export const GET_USER_BY_ID = 'api/GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'api/GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_ERROR = 'api/GET_USER_BY_ID_ERROR';
export const GET_PASSWORD = 'api/GET_PASSWORD';
export const GET_PASSWORD_SUCCESS = 'api/GET_PASSWORD_SUCCESS';
export const GET_PASSWORD_ERROR = 'api/GET_PASSWORD_ERROR';
export const POST_CHECK_USERID = 'api/POST_CHECK_USERID';
export const POST_CHECK_USERID_SUCCESS = 'api/POST_CHECK_USERID_SUCCESS';
export const POST_CHECK_USERID_ERROR = 'api/POST_CHECK_USERID_ERROR';

export const getUsersAsync = createAsyncAction(
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR
)<any, UserDto[] | null, AxiosError>();

export const setUserAsync = createAsyncAction(
  SET_USER,
  SET_USER_SUCCESS,
  SET_USER_ERROR
)<UserDto, any | null, AxiosError>();

export const getUserAsync = createAsyncAction(
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR
)<string, UserDto|null, AxiosError>();

export const postUserIdAsync = createAsyncAction(
  POST_CHECK_USERID,
  POST_CHECK_USERID_SUCCESS,
  POST_CHECK_USERID_ERROR
)<string, UserDto|null, AxiosError>();

export const getPasswordAsync = createAsyncAction(
  GET_PASSWORD,
  GET_PASSWORD_SUCCESS,
  GET_PASSWORD_ERROR
)<{ name: string, email: string, id: string }, SuccessResponse<{ password: string }> | ErrorResponse, AxiosError>();