import { FC } from 'react';
import { Link } from "react-router-dom";
import '../../styles/layout/default-navigation.scss';

interface GNBProps {
onHoverChange: (isActive: boolean) => void;
}

const GNB:FC<GNBProps> = ({onHoverChange}) => {
    const handleMouseEnter = () => {
        onHoverChange(true);
    };
    const handleMouseLeave = () => {
        onHoverChange(false);
    };
    
    return(
        <nav id="nav"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            <ul className="gnb">
                <li className='gnb-li1'><Link to={'/overview'}>서비스 소개</Link>
                    <div className="gnb-drop-menu">
                        <ul>
                            <li><Link to={'/overview'}>서비스 개요</Link></li>
                            <li><Link to={'/features'}>주요 기능</Link></li>
                        </ul>
                    </div>
                </li>
                <li className='gnb-li1'><Link to={'/product'}>제품 안내</Link>
                    <div className="gnb-drop-menu">
                        <ul>
                            <li><Link to={'/product'}>제품 안내</Link></li>
                        </ul>
                    </div>
                </li>
                <li className='gnb-li1'><Link to={'/project'}>프로젝트</Link>
                    <div className="gnb-drop-menu">
                        <ul>
                            <li><Link to={'/project?tab=2'}>영상촬영 서비스</Link></li>
                            <li><Link to={'/project?tab=3'}>AI 데이터 생성관리 서비스</Link></li>
                            <li><Link to={'/project?tab=4'}>디지털트윈 지도 서비스</Link></li>
                        </ul>
                    </div>
                </li>
                <li className='gnb-li1'><Link to={'/notice'}>고객지원</Link>
                    <div className="gnb-drop-menu">
                        <ul>
                            <li><Link to={'/notice'}>공지사항</Link></li>
                            <li><Link to={'/faq'}>FAQ</Link></li>
                            <li><Link to={'/contact'}>문의하기</Link></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default GNB;