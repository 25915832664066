import axios from 'axios';
import config from '../config';
import { PotalCommDto } from '../types/PotalCommDto';
import { ErrorResponse, SuccessResponse } from '../types/Response';
import { OgnzDto, SelectOgnzDto } from '../types/OgnzDto';

export async function findOgnzList(selectOgnzDto: SelectOgnzDto): Promise<SuccessResponse<OgnzDto[], PotalCommDto> | ErrorResponse> {
  try {
    const response = await axios.get<SuccessResponse<OgnzDto[], PotalCommDto> | ErrorResponse>(
      `${config.portalApiUrl}/ognz`,
      {
        params: selectOgnzDto,
      }
    );
    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching ognz:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch ognz list',
      },
    };
  }
}

export async function findOgnz(ognzId: string): Promise<SuccessResponse<OgnzDto> | ErrorResponse> {
  try {
    const response = await axios.get<SuccessResponse<OgnzDto> | ErrorResponse>(
      `${config.portalApiUrl}/ognz/${ognzId}`,
    );
    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching ognz:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to fetch ognz list',
      },
    };
  }
}


export async function updateOgnz({ognzId, ognzDto}:{ognzId: string, ognzDto: OgnzDto}): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await axios.put<SuccessResponse<null> | ErrorResponse>(
      `${config.portalApiUrl}/ognz/${ognzId}`,
      ognzDto
    );

    if (response.data.status === 'success') {
      console.log('Ognz updated successfully');
      return response.data; // 업데이트된 사용자 데이터를 반환
    } else {
      console.log('Update failed with message:', response.data.error.message);
      return response.data; // 에러 데이터를 반환
    }
  } catch (error) {
    console.error('Error updated ognz:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to updated ognz',
      },
    };
  }
}

export async function deleteOgnz(ognzIds: string[]): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const response = await axios.delete<SuccessResponse<null> | ErrorResponse>(
      `${config.portalApiUrl}/ognz`,
      {
        data: { ognzIds }
      }
    );

    if ((await response.data).status === 'success') {
      console.log('success');
      return response.data; // ognzId를 반환
    } else if ((await response.data).status === 'error') {
      console.log((await response.data));
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete ognz',
        },
      };
    } else {
      console.log('else');
      return {
        status: 'error',
        error: {
          code: 'INTERNAL_SERVER_ERROR',
          message: 'Failed to delete ognz',
        },
      };
    }
  } catch (error) {
    console.error('Error delete ognz:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: 'Failed to delete ognz',
      },
    };
  }
}