import { getProductAsync , getProductItemAsync , getBuyKeyAsync , postBuyAsync, putBuyAsync, getBuyScrtnListAsync, getMapprimeDashboardAsync , getBuyUseAsync , getBuyUseListAsync  } from './actions';
import { GET_PRODUCT , GET_PRODUCT_ITEM , GET_BUY_KEY , POST_BUY , PUT_BUY , GET_BUY_LIST , GET_MAPP_DASH, GET_BUY_USE , GET_BUY_USE_LIST } from './actions';
import { getProduct , getProductItem , getBuyKey , setBuyInfo, putBuy , getBuyScrtnList, getMapprimeDashboard , getBuyUse , getBuyUseList } from '../../api/product';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';

const getProductSaga = createAsyncSaga(getProductAsync, getProduct);
const getProductItemSaga = createAsyncSaga(getProductItemAsync, getProductItem);
const getBuyKeySaga = createAsyncSaga(getBuyKeyAsync, getBuyKey);
const postBuySaga = createAsyncSaga(postBuyAsync, setBuyInfo);
const putBuySaga = createAsyncSaga(putBuyAsync, putBuy);
const getBuyScrtnListSaga = createAsyncSaga(getBuyScrtnListAsync , getBuyScrtnList);
const getMapprimeDashboardAsyncSaga = createAsyncSaga(getMapprimeDashboardAsync , getMapprimeDashboard);
const getBuyUseSaga = createAsyncSaga(getBuyUseAsync, getBuyUse);
const getBuyUseListSaga = createAsyncSaga(getBuyUseListAsync, getBuyUseList);

export function* productSaga() {
  yield takeEvery(GET_PRODUCT, getProductSaga);
  yield takeEvery(GET_PRODUCT_ITEM, getProductItemSaga);

  yield takeEvery(GET_BUY_KEY, getBuyKeySaga);
  yield takeEvery(POST_BUY, postBuySaga);
  yield takeEvery(PUT_BUY, putBuySaga);

  yield takeEvery(GET_BUY_LIST, getBuyScrtnListSaga);
  yield takeEvery(GET_MAPP_DASH, getMapprimeDashboardAsyncSaga);

  yield takeEvery(GET_BUY_USE      , getBuyUseSaga);
  yield takeEvery(GET_BUY_USE_LIST , getBuyUseListSaga);
  
}
