import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import MypageLayout from "./MypageLayout";import TabComponent from '../../components/Tab';
import ProjectManage1 from "./ProjectManage1";
import ProjectManage2 from "./ProjectManage2";
import ProjectManage3 from "./ProjectManage3";
import ProjectManage4 from "./ProjectManage4";
import ProjectManage5 from "./ProjectManage5";
import ProjectManage6 from "./ProjectManage6";
import ProjectManage7 from "./ProjectManage7";
import ProjectManage8 from "./ProjectManage8";
import ProjectManage9 from "./ProjectManage9";
import ProjectManage10 from "./ProjectManage10";
import ProjectManage11 from "./ProjectManage11";
import ProjectManage12 from "./ProjectManage12";
import ProjectManage13 from "./ProjectManage13";
import ProjectManage14 from "./ProjectManage14";

import { getMapprimeDashboardAsync , getBuyUseListAsync } from '../../modules/product';
import { getNeospectraProjectCntAsync , getNeospectraProjectStatsAsync , getNeospectraJobsStatsAsync , getNeospectraUsagesCurrentAsync 
	   , getNeospectraUsagesServiesLeftAsync , getInfoseedMypageAsync
} from '../../modules/myPage';
import { useNavigate } from 'react-router-dom';
import { getCurrentDate , getAddDays } from "../../pages/common/dateUtil";
import { getBytesToSize } from "../../pages/common/stringUtil";

interface PageProps {
title: string;
pageTitle: string;
}

interface totalDataMapProps {
	useData: string;
	totalData: string;
	usePer:number;
}

interface projectData {
    project:number;
    asset : {
        progress : number;
        done : number;
    }
}

const tabs = [
    { id: 1, title: '영상촬영 서비스'           , control : 'content-1'  , key: 'GEO_NIC' },
    { id: 2, title: 'AI 데이터 생성관리 서비스' , control : 'content-2'  , key: 'SIMMETA' },
    { id: 3, title: '디지털트윈 지도 서비스'    , control : 'content-3'  , key: 'MAPPRIME' },
];

const MypageProjectManage: FC<PageProps> = ({ title, pageTitle }) => {
	const dispatch = useDispatch();
    const navigate = useNavigate();

    const buyUserList = useSelector((state:RootState) => state.product.buyUserList);
	const mapprimeDashboard = useSelector((state:RootState) => state.product.dashboardInfo);

	const neoProjCnt       = useSelector((state:RootState) => state.myPage.neoProjCnt);
	const neoProjStat      = useSelector((state:RootState) => state.myPage.neoProjStat);
	const neoJobsStat      = useSelector((state:RootState) => state.myPage.neoJobsStat);
	const neoUsagesCurrent = useSelector((state:RootState) => state.myPage.neoUsagesCurrent);
	const neoUsagesServies = useSelector((state:RootState) => state.myPage.neoUsagesServies);
	const infoseedMypage = useSelector((state:RootState) => state.myPage.infoseedMypage);

	
	const [totalDataLogMap, setTotalDataLogMap] = useState();
	const [projectDataMap, setProjectDataMap] = useState<any[]>([]);
	const [projectCntDataMap, setProjectCntDataMap] = useState<any>();

	const [infoDataMap, setInfoDataMap] = useState<any>();
	const [infoPrjDataAmountMap, setInfoPrjDataAmountMap] = useState<any[]>([]);
	
	const [buyUser , setBuyUser] = useState<any[]>([]);
	const [sltnBuy , setSltnBuy] = useState<any[]>([]);
	const [sltnId  , setSltnId]   = useState<string>(tabs[0].key);
	

	const [totalDataMap1, setTotalDataMap1] = useState<totalDataMapProps>({
        useData : "0GB",
        totalData : "0GB",
		usePer : 0.0
    });
	const [totalDataMap2, setTotalDataMap2] = useState<totalDataMapProps>({
        useData : "0GB",
        totalData : "0GB",
		usePer : 0.0
    });
	const [totalDataMap3, setTotalDataMap3] = useState<totalDataMapProps>({
        useData : "0GB",
        totalData : "0GB",
		usePer : 0.0
    });

	


	useEffect(() => {
		
		if (buyUserList && buyUserList.data && buyUserList.data.data) {

			setBuyUser(buyUserList.data.data);
	
			console.log('buyUser 리턴 결과:', JSON.stringify(buyUser, null, 2));
		}
    }, [buyUserList]);

	useEffect(() => {
		if (buyUser && buyUser.length > 0) {
			setSltnBuy(buyUser.filter((item : any) => item.sltnId === sltnId));
	
			console.log('buyUser 리턴 결과:', JSON.stringify(buyUser, null, 2));
		}
    }, [buyUser,sltnId]);

	console.log('sltnBuy 리턴 결과:', JSON.stringify(sltnBuy, null, 2));
    
	const [startDt, setStartDt] = useState<string>("");
	const [endDt, setEndDt] = useState<string>("");

	const [currentTab, setCurrentTab] = useState<number>(tabs[0].id);
    const handleTabClick = (id: number) => {
        setCurrentTab(id);
		setSltnId(tabs[id-1].key);
    };

	useEffect(() => {
        const fetchBuyUser = async () => {
            await dispatch(getBuyUseListAsync.request(""));

			if(sessionStorage.getItem("isMapprimeSubscribe")) {
				await dispatch(getMapprimeDashboardAsync.request({
					mapprimeToken: sessionStorage.getItem("mapprimeToken"),
				}));
			}
			
			if(sessionStorage.getItem("isInfoseedSubscribe")) {
				await dispatch(getInfoseedMypageAsync.request(null));
			}

			if(sessionStorage.getItem("isNeospectraSubscribe")) {
				await dispatch(getNeospectraProjectCntAsync.request({
					access_token: sessionStorage.getItem("access_token"),
				}));

				const endDate   = getCurrentDate("YYYY-MM-DD");
				const startDate = getAddDays(new Date() , -10 , "YYYY-MM-DD");

				setStartDt(startDate);
				setEndDt(endDate);

				await dispatch(getNeospectraProjectStatsAsync.request({
					access_token: sessionStorage.getItem("access_token"),
					param: {
						start_date : startDate,
						end_date : endDate,
						category : "OBJECT_DETECTION"
					}
				}));

				await dispatch(getNeospectraJobsStatsAsync.request({
					access_token: sessionStorage.getItem("access_token"),
					param: {
						start_date : startDate,
						end_date   : endDate,
						category   : "OBJECT_DETECTION"
					}
				}));

				await dispatch(getNeospectraUsagesCurrentAsync.request({
					access_token: sessionStorage.getItem("access_token"),
				}));

				await dispatch(getNeospectraUsagesServiesLeftAsync.request({
					access_token: sessionStorage.getItem("access_token"),
				}));
			}

        };

        fetchBuyUser();
    }, []);

    useEffect(() => {
        if (mapprimeDashboard && mapprimeDashboard.data && mapprimeDashboard.data.result != "error") {
			
			
			const totalData = Number(mapprimeDashboard.data.size.total);
			const currentData = Number(mapprimeDashboard.data.size.current);

			const dataMap = {
				totalData : getBytesToSize(totalData),
				useData : getBytesToSize(currentData),
				usePer : Math.round(currentData/totalData*100)
			}
			
			setTotalDataMap3(dataMap);

			setTotalDataLogMap(mapprimeDashboard.data.log);

			setProjectDataMap(mapprimeDashboard.data.topProjects);

			setProjectCntDataMap(mapprimeDashboard.data.count);

		}
    }, [mapprimeDashboard]);

	useEffect(() => {
        if (infoseedMypage && infoseedMypage.data && infoseedMypage.data.status != "error") {
			
			
			const totalData = Number(infoseedMypage.data.data.TOTAL_MEMORY);
			const currentData = Number(infoseedMypage.data.data.USE_MEMORY);

			const dataMap = {
				totalData : totalData+"GB",
				useData : currentData+"GB",
				usePer : Math.round(currentData/totalData*100)
			}

			console.log('infoseedMypage 리턴 결과:', JSON.stringify(infoseedMypage, null, 2));
			
			setTotalDataMap1(dataMap);

			setInfoDataMap(infoseedMypage.data.data);

			setInfoPrjDataAmountMap(infoseedMypage.data.data?.PRJ_DATA_AMOUNT);

		}
    }, [infoseedMypage]);

	useEffect(() => {
        if (neoUsagesCurrent && neoUsagesCurrent.data && neoUsagesCurrent.data.result != "error") {
			
			
			const totalData = Number(neoUsagesCurrent.data?.available);
			const currentData = Number(neoUsagesCurrent.data?.used);

			if (totalData) {
				const dataMap = {
					totalData : totalData+" GB",
					useData : currentData+" GB",
					usePer : Math.round(currentData/totalData*100)
				}
				
				setTotalDataMap2(dataMap);
			} else {
				const dataMap = {
					totalData : "0",
					useData : "0",
					usePer : 0
				}
				
				setTotalDataMap2(dataMap);
			}

			

		}
    }, [neoUsagesCurrent]);

	return (
		<MypageLayout bannerTitle={title} pageTitle={pageTitle}>
			<TabComponent 
			tabs={tabs} 
			role='tab' 
			current={ currentTab } 
			onClick={(index, tab) => handleTabClick(tab.id)}
			/>
			<div className="tabpanel" id='content-1' role='tabpanel' hidden={currentTab  !== 1}>
				{sltnBuy && sltnBuy.length > 0 ? (
					<div>
						<div className="grid">
							<ProjectManage1 tabSn={0} sltnId="GEO_NIC" buyUserList={buyUser} totalDataMap={totalDataMap1}  />
							
							<ProjectManage11 infoDataMap = {infoDataMap} />
						</div>
						<div className="grid col-row2-item">
							<div className="col-row2-item01 col-3 col-lg-4 col-md-12">
								<ProjectManage12 infoDataMap = {infoDataMap} />
							</div>
							<div className="col-row2-item02 col-9 col-lg-8 col-md-12">
								<div>
									<ProjectManage13 infoDataMap = {infoDataMap}/>
								</div>
								<div>
									<ProjectManage14 infoPrjDataAmountMap = {infoPrjDataAmountMap} />
								</div>
							</div>
						</div>
					</div>
					
				) : (
				<div className="cm-no-data">
					<div className="cm-no-data-wrap">
						<i className="cs-icon-warning_gray"></i>
						<div>프로젝트 구독정보가 없습니다</div>
					</div>
				</div>
				)
				}
				
			</div>
			<div className="tabpanel" id='content-2' role='tabpanel' hidden={currentTab  !== 2}>
			{sltnBuy && sltnBuy.length > 0 ? (
				<div>
					<div className="grid">
						<ProjectManage1 tabSn={1} sltnId="SIMMETA" buyUserList={buyUser} totalDataMap={totalDataMap2}/>
						<ProjectManage8 neoProjCnt = {neoProjCnt.data} />
					</div>
					<div className="grid">
						<ProjectManage9 neoUsagesServies = {neoUsagesServies?.data}  />
					</div>
					<div className="grid">
						<ProjectManage10 neoJobsStat = {neoJobsStat?.data} neoProjStat = {neoProjStat?.data} startDt={startDt} endDt={endDt} />
					</div>
				</div>
			) : (
				<div className="cm-no-data">
					<div className="cm-no-data-wrap">
						<i className="cs-icon-warning_gray"></i>
						<div>프로젝트 구독정보가 없습니다</div>
					</div>
				</div>
			) }
			</div>
			<div className="tabpanel" id='content-3' role='tabpanel' hidden={currentTab  !== 3}>
			{sltnBuy && sltnBuy.length > 0 ? (
				<div>
					<div className="grid">
						<ProjectManage1 tabSn={2} sltnId="MAPPRIME" buyUserList={buyUser} totalDataMap={totalDataMap3} />
						<ProjectManage2 projectCntDataMap={projectCntDataMap} />
					</div>
					<div className="grid">
						<ProjectManage3 totalDataLogMap={totalDataLogMap} />
						<ProjectManage4 projectDataMap={projectDataMap} />
						<ProjectManage5 projectCntDataMap={projectCntDataMap} />
					</div>
					<div className="grid">
						<ProjectManage6 />
						<ProjectManage7 projectCntDataMap={projectCntDataMap} />
					</div>
				</div>
			) : (
				<div className="cm-no-data">
					<div className="cm-no-data-wrap">
						<i className="cs-icon-warning_gray"></i>
						<div>프로젝트 구독정보가 없습니다</div>
					</div>
				</div>
			)
			}
				
			</div>
		</MypageLayout>
	);
};

export default MypageProjectManage;
