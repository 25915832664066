import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';

import { CreateBuyDto }  from '../../types/CreateBuyDto';

export const GET_PRODUCT = 'api/GET_PRODUCT'
export const GET_PRODUCT_SUCCESS = 'api/GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_ERROR = 'api/GET_PRODUCT_ERROR'

export const GET_PRODUCT_ITEM = 'api/GET_PRODUCT_ITEM'
export const GET_PRODUCT_ITEM_SUCCESS = 'api/GET_PRODUCT_ITEM_SUCCESS'
export const GET_PRODUCT_ITEM_ERROR = 'api/GET_PRODUCT_ITEM_ERROR'

export const GET_BUY_KEY = 'api/GET_BUY_KEY'
export const GET_BUY_KEY_SUCCESS = 'api/GET_BUY_KEY_SUCCESS'
export const GET_BUY_KEY_ERROR = 'api/GET_BUY_KEY_ERROR'

export const POST_BUY = 'api/POST_BUY'
export const POST_BUY_SUCCESS = 'api/POST_BUY_SUCCESS'
export const POST_BUY_ERROR = 'api/POST_BUY_ERROR'

export const PUT_BUY = 'api/PUT_BUY'
export const PUT_BUY_SUCCESS = 'api/PUT_BUY_SUCCESS'
export const PUT_BUY_ERROR = 'api/PUT_BUY_ERROR'

export const GET_BUY_LIST = 'api/GET_BUY_LIST'
export const GET_BUY_LIST_SUCCESS = 'api/GET_BUY_LIST_SUCCESS'
export const GET_BUY_LIST_ERROR = 'api/GET_BUY_LIST_ERROR'

export const GET_MAPP_DASH = 'api/GET_MAPP_DASH'
export const GET_MAPP_DASH_SUCCESS = 'api/GET_MAPP_DASH_SUCCESS'
export const GET_MAPP_DASH_ERROR = 'api/GET_MAPP_DASH_ERROR'

export const GET_BUY_USE          = 'api/GET_BUY_USE'
export const GET_BUY_USE_SUCCESS  = 'api/GET_BUY_USE_SUCCESS'
export const GET_BUY_USE_ERROR    = 'api/GET_BUY_USE_ERROR'

export const GET_BUY_USE_LIST         = 'api/GET_BUY_USE_LIST'
export const GET_BUY_USE_LIST_SUCCESS = 'api/GET_BUY_USE_LIST_SUCCESS'
export const GET_BUY_USE_LIST_ERROR   = 'api/GET_BUY_USE_LIST_ERROR'

export const getProductAsync = createAsyncAction(
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR
)<string, any, AxiosError>();

export const getProductItemAsync = createAsyncAction(
  GET_PRODUCT_ITEM,
  GET_PRODUCT_ITEM_SUCCESS,
  GET_PRODUCT_ITEM_ERROR
)<string, any, AxiosError>();

export const getBuyKeyAsync = createAsyncAction(
  GET_BUY_KEY,
  GET_BUY_KEY_SUCCESS,
  GET_BUY_KEY_ERROR
)<string, any, AxiosError>();

export const postBuyAsync = createAsyncAction(
  POST_BUY,
  POST_BUY_SUCCESS,
  POST_BUY_ERROR
)<CreateBuyDto, any, AxiosError>();

export const putBuyAsync = createAsyncAction(
  PUT_BUY,
  PUT_BUY_SUCCESS,
  PUT_BUY_ERROR
)<CreateBuyDto, any, AxiosError>();

export const getMapprimeDashboardAsync = createAsyncAction(
  GET_MAPP_DASH,
  GET_MAPP_DASH_SUCCESS,
  GET_MAPP_DASH_ERROR
)<any, any, AxiosError>();

export const getBuyScrtnListAsync = createAsyncAction(
  GET_BUY_LIST,
  GET_BUY_LIST_SUCCESS,
  GET_BUY_LIST_ERROR
)<any, any, AxiosError>();

export const getBuyUseAsync = createAsyncAction(
  GET_BUY_USE,
  GET_BUY_USE_SUCCESS,
  GET_BUY_USE_ERROR
)<any, any, AxiosError>();

export const getBuyUseListAsync = createAsyncAction(
  GET_BUY_USE_LIST,
  GET_BUY_USE_LIST_SUCCESS,
  GET_BUY_USE_LIST_ERROR
)<any, any, AxiosError>();
