import { createReducer } from 'typesafe-actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { deleteSltnAsync, getSltnByIdAsync, getSltnListAsync, postCheckSltnIdAsync, postCreateSltnAsync, putUpdateSltnAsync } from './actions';
import { SltnAction, SltnState } from './types';
import { AnyAction } from 'redux';

const initialState: SltnState = {
  data: asyncState.initial(),
};

const ognzReducer = createReducer<SltnState, AnyAction>(initialState)
.handleAction(
  transformToArray(postCheckSltnIdAsync),
  createAsyncReducer(postCheckSltnIdAsync, 'data')
).handleAction(
  transformToArray(getSltnListAsync),
  createAsyncReducer(getSltnListAsync, 'data')
).handleAction(
  transformToArray(getSltnByIdAsync),
  createAsyncReducer(getSltnByIdAsync, 'data')
).handleAction(
  transformToArray(postCreateSltnAsync),
  createAsyncReducer(postCreateSltnAsync, 'data')
).handleAction(
  transformToArray(putUpdateSltnAsync),
  createAsyncReducer(putUpdateSltnAsync, 'data')
).handleAction(
  transformToArray(deleteSltnAsync),
  createAsyncReducer(deleteSltnAsync, 'data')
);

export default ognzReducer;