import { FC } from 'react';
import '../styles/components/dialog.scss';

interface SmallDialogProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
    footChildren?: React.ReactNode;
}

const AlertSmallDialog:FC<SmallDialogProps> = ( { id, isOpen, onClose, title, children , footChildren } ) => {
    
    if (!isOpen) return null;

    return (
    <>
        <div className="dialog-backdrop" onClick={onClose}></div>
        <div className="dialog" id={id}>
            <div className="dialog-container">
                <div className="dialog-body">
                    <div className='dialog-inner'>
                            {children}
                    </div>
                </div>     
            </div>
            {/* dialog-container */}
        </div>
    </>
    )
}

export default AlertSmallDialog;