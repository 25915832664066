import React,{FC} from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid , ResponsiveContainer  } from 'recharts';

type ButtonProps = {
    width: string;
    height: number;
    data: any[];
}
// 사용자 정의 tick 컴포넌트
const CustomTick: React.FC<{ x: number; y: number; payload: { value: string } }> = ({ x, y, payload }) => (
  <text 
    x={x} 
    y={y} 
    textAnchor="end" 
    fontSize={15}
    fill="#666" 
    transform={`rotate(-50, ${x}, ${y})`} // 세로로 회전
    dy={10} // y축 위치 조정
  >
    {payload.value}
  </text>
);

const barChart: FC<ButtonProps> = ({ width,height,data }) => { 
  return (
    <ResponsiveContainer width={width} height={height}>
        <BarChart  data={data} margin={{ top: 20, bottom: 150 }} > // 여백 조정
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" 
          interval={0} // 모든 레이블 표시
          tick={(props) => <CustomTick {...props} />} // 사용자 정의 tick 사용
          label={{
            value: '',
            position: 'insideBottomRight',
            angle: -50,
            offset: 10,
          }} 
          />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      );
}
export default barChart;