import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import '../../styles/components/icon.scss';


interface ShareList {
    id: number;
    title: string;
    link: string; 
}

const ShareListItem: ShareList[] = [
    { id: 1 , title : '영상촬영<br/>데이터' , link : '/views'},
    { id: 2 , title : 'AI 데이터' , link : '/views'},
    { id: 3 , title : '맵프라임<br/>에셋' , link : '/views'}
]

const ProjectManage6:FC = () => {
    
    return (
        <ProjectSections title={`공유 폴더 바로가기`} className="col-3 col-lg-4 col-md-12 user-share-list">
            <div className="share-link-list">
            {ShareListItem.map(share => (
                <div className="share-link" key={share.id}>
                    <Link to={share.link}>
                        <i className="icon--folder"></i>
                        <span dangerouslySetInnerHTML={{ __html: share.title }} />
                    </Link>
                </div>
            ))}
            </div>
        </ProjectSections>
    )
}
export default ProjectManage6;
