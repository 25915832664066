import './styles/App.scss';
// import Users from './containers/Users';
// import StateTest from './containers/StateTest';
// import StoreTest from './containers/StoreTest';
//import PotalHeader from './containers/PotalHeader';
//import UserDesc from './containers/UserDesc';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import NotFound from './containers/NotFound';
import Header2 from './containers/header';
import FooterContainer from './containers/footer';
import Login from './pages/login/Login';
import ForgotId from './pages/forgot/Id';
import ForgotPassword from './pages/forgot/Password';
import RegistrationForm from './pages/register';
import MypageProjectManage from './pages/mypage/ProjectManage';
import MypageProductManage from './pages/mypage/ProductManage';
import MypageContactUsManage from './pages/mypage/MypageContactUs';
import ProjectList from './pages/project/ProjectList';
import ServiceOverview from './pages/service/ServiceOverview';
import MainFeatures from './pages/service/MainFeatures';
import MainPage from './pages/main';
import Notice from './pages/support/Notice';
import FAQ from './pages/support/FAQ';
import ContactUs from './pages/support/ContactUs';
import DetailView from './pages/support/DetailView';
import ContactUsWrite from './pages/support/ContactUsWrite';
import ContactUsDetail from './pages/support/ContactUsDetail';
import ProductGuide from './pages/product/ProductGuide';
import MypageUserUpdate from './pages/mypage/MypageUserUpdate';
import Privacy from './pages/policy/privacy';
import Term from './pages/policy/term';
import Email from './pages/policy/email';

import { useState } from 'react';

import BuySuccess from './pages/mypage/BuySuccess';
import BuyFail from './pages/mypage/BuyFail';
import MypageUserManage from './pages/mypage/MypageUserManage';

function App() {
  // 새로고침 했을 때만 실행됨
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    const token = sessionStorage.getItem('access_token');
    return token !== null; // 토큰이 있으면 true, 없으면 false
  });

  return (
    <BrowserRouter>
      <Header2 isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div id="contents">
        <Routes>
          <Route path="/" element={<MainPage isLoggedIn={isLoggedIn} />}></Route>
          <Route
            path="/login"
            element={<Login title="로그인" setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/forgotid"
            element={<ForgotId title="아이디 찾기" />}
          />
          <Route
            path="/forgotpassword"
            element={<ForgotPassword title="비밀번호 찾기" />}
          />
          <Route
            path="/register"
            element={<RegistrationForm title="회원가입" />}
          />
          <Route
            path="/mypage/project"
            element={
              <MypageProjectManage
                title="마이페이지"
                pageTitle="프로젝트 관리"
              />
            }
          />
          <Route
            path="/mypage/product"
            element={
              <MypageProductManage
                title="마이페이지"
                pageTitle="제품선택"
              />
            }
          />
          <Route
            path="/mypage/MypageUserManage"
            element={
              <MypageUserManage
                title="마이페이지"
                pageTitle="사용자 관리"
              />
            }
          />

          <Route
            path="/mypage/MypageUserUpdate"
            element={
              <MypageUserUpdate
                title="마이페이지"
                pageTitle="회원정보수정"
              />
            }
          />

          <Route
            path="/mypage/MypageContactUs"
            element={
              <MypageContactUsManage
                title="마이페이지"
                pageTitle="나의 문의 내역"
              />
            }
          />

          <Route
            path="/overview"
            element={<ServiceOverview title="서비스 개요" />}
          />
          <Route path="/features" element={<MainFeatures title="주요기능" />} />
          <Route path="/product" element={<ProductGuide title="제품안내" />} />
          <Route
            path="/project"
            element={
              isLoggedIn ? (
                <ProjectList title="프로젝트" />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          <Route path="/notice" element={<Notice title="공지사항" />} />
          <Route path="/notice/:id" element={<DetailView title="공지사항" />} />

          <Route path="/faq" element={<FAQ title="FAQ" />} />

          <Route path="/contact" element={<ContactUs title="문의하기" />} />
          <Route path="/contactUsWrite" element={<ContactUsWrite title="문의하기" />} />
          <Route path="/contactUsWrite/:id" element={<ContactUsWrite title="문의하기" />} />
          <Route path="/contactUsDetail/:id" element={<ContactUsDetail title="문의하기" />} />

          <Route path="/buySuccess" element={<BuySuccess title="결제결과" />} />
          <Route path="/buyFail" element={<BuyFail title="결제결과" />} />
          <Route path="/privacy" element={<Privacy title="개인정보처리방침" />} />
          <Route path="/term" element={<Term title="이용약관" />} />
          <Route path="/email" element={<Email title="이메일무단수집거부" />} />

          

          
          {/* <Route path="/test2" element={<StoreTest />}></Route>
        <Route path="/users" element={<Users />}></Route>
        <Route path="/user/:id" element={<UserDesc />}></Route> */}
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
      <FooterContainer isLoggedIn={isLoggedIn} />
    </BrowserRouter>
  );
}
export default App;
