import { FC } from 'react';
import '../styles/components/button.scss';

type ButtonsProps = {
    text: string;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    variant?: string;
    size?: string;
    block?: boolean;
    disabled?: boolean;
};

const Buttons: FC<ButtonsProps> = ({ 
    text, 
    type = 'button', 
    variant = 'primary',  
    onClick, 
    size = 'lg', 
    block = false, 
    disabled = false 
}) => { 
    return (
        <button
            type={type}
            className={`button button--${variant} button--${size} ${block ? 'button--block' : ''} ${disabled ? 'button--disabled' : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    );
}

export default Buttons;
