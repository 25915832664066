import { FC } from 'react';
import { Link } from 'react-router-dom';
import MainHeaderGroup from './MainHeaderGroup';

import './main-section2.scss';
import { openMapprimePage, openNeospectraPage } from '../common/openSiteUtil';
import { openInfoPage } from '../common/stringUtil';

interface MainSection2Props {
    isLoggedIn: boolean;
}

interface LightListItem {
    id: number;
    title: string;
    list: string[];
    link: string;
}

const lightList: LightListItem[] = [
    {
        id: 1,
        title: 'Map Prime',
        list: [
            'AI 영상 처리',
            '등의 기능 설명 문구'
        ],
        link: '#'
    },
    {
        id: 2,
        title: 'Neospectra',
        list: [
            '3D 생성 서비스',
            '등의 기능 설명 문구'
        ],
        link: '#'
    },
    {
        id: 3,
        title: 'Infoseed',
        list: [
            '영상 데이터 관리',
            '등의 기능 설명 문구'
        ],
        link: '#'
    }
]
const MainSection2: FC<MainSection2Props> = ({ isLoggedIn }) => {
    return (
        <section className='main-section2'>
            <div className="row">
                <div className="container">
                    <MainHeaderGroup>
                        <b>Segmentation AI 기술</b>을 통한 <br />
                        다양한 3D 업무에 활용 가능합니다.
                    </MainHeaderGroup>
                    <div className='main-section2-links'>
                        {lightList.map((item, index) => (
                            <div className='item' key={item.id}>
                                <div className='service-num'>주요기능 0{index + 1}</div>
                                <div className='service-name'><b>{item.title}</b> Service</div>
                                <ul>
                                    {item.list.map((text, idx) => (
                                        <li key={idx}>· {text}</li>
                                    ))}
                                </ul>
                                {
                                    isLoggedIn ? <Link
                                        to={item.link}
                                        className='link'
                                        onClick={(e) => {
                                            if (item.title === 'Map Prime') {
                                                e.preventDefault();
                                                openMapprimePage();
                                            } else if (item.title === 'Neospectra') {
                                                e.preventDefault();
                                                openNeospectraPage();
                                            } else if (item.title === 'Infoseed') {
                                                e.preventDefault();
                                                openInfoPage("P001");
                                            }
                                        }}
                                    >
                                        바로가기 &gt;
                                    </Link> : null
                                }

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainSection2;