import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import Progress from "./Progress";
import { openInfoPage }from "../common/stringUtil";

type props = {
    infoPrjDataAmountMap: any[];
};

const ProjectManage14:FC<props> = ({  infoPrjDataAmountMap }) => {
    
    return (
        <ProjectSections title="프로젝트별 데이터 사용량" className="user-porject-data" onButtonClick={() => openInfoPage("P001")} buttonClassName="icon--more">
            <div>
            {infoPrjDataAmountMap && infoPrjDataAmountMap?.length > 0 ? (infoPrjDataAmountMap.map((project:any) => (
                <Progress 
                    item={{ id: project.category_code, title: project.PRJ_NM, count: Math.round(project.USE_AMOUNT/project.FILE_CNT*100) , text : project.USE_AMOUNT  + " / " + project.FILE_CNT , width : "20%" }} 
                    theme="blue"
                />
             ))):null}
            </div>
        </ProjectSections>
    )
}
export default ProjectManage14;
