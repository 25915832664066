import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import Progress from "./Progress";
import BarChart from "../../components/BarChart";
import { getFormatDate , getDaysDifference } from "../../pages/common/dateUtil";

type props = {
    neoJobsStat: any;
    neoProjStat: any;
    startDt:string;
    endDt:string;
};

const dateCnt = (startDate:string , endDate:string) => {
    const startDay = new Date(startDate);
    const endDay   = new Date(endDate);
    const dayDifference = getDaysDifference(startDay,endDay);

    const defValue = [];

    for (var i=0;i<dayDifference;i++) {
        startDay.setDate(startDay.getDate() + 1);

        defValue.push({
            name : getFormatDate(startDay,"YYYY-MM-DD") ,
            value : 0
        });
    }

    return defValue;
}

const ProjectManage7:FC<props> = ( {neoJobsStat ,neoProjStat , startDt , endDt } ) => {

    const width = "100%";
    const height = 400;
    
    const projectDat1 : {name:string , value:number}[] = dateCnt(startDt,endDt);
    const projectDat2 : {name:string , value:number}[] = dateCnt(startDt,endDt);
    const projectDat3 : {name:string , value:number}[] = dateCnt(startDt,endDt);
    const projectDat4 : {name:string , value:number}[] = dateCnt(startDt,endDt);

    if (startDt != "" && endDt != "") {

        const chart1_data = neoJobsStat?.resultList1 as any[];
        const chart2_data = neoJobsStat?.resultList2 as any[];

        chart1_data?.forEach((item,index) => {
            const project = {name : item.date , value : item.count }    
            projectDat1.push(project);
        });

        chart2_data?.forEach((item,index) => {
            const project = {name : item.date , value : item.count }    
            projectDat2.push(project);
        });

        const chart3_data = neoProjStat?.resultList1 as any[];
        const chart4_data = neoProjStat?.resultList2 as any[];

        chart3_data?.forEach((item,index) => {
            const project = {name : item.date , value : item.count }    
            projectDat3.push(project);
        });

        chart4_data?.forEach((item,index) => {
            const project = {name : item.date , value : item.count }    
            projectDat4.push(project);
        });
    }

   
    return (
        <ProjectSections title="사용 서비스" className="col-12 col-lg-12 col-md-12 user-state" >
            <div style={{display: "flex"}} >
                <div className="" style={{width:"50%"}}>
                    <b>객체 탐지 (프로젝트) </b>
                    <BarChart width={width} height={height} data={projectDat3} />
                </div>

                <div className="" style={{width:"50%"}}>
                    <b>객체 탐지 (작업) </b>
                    <BarChart width={width} height={height} data={projectDat1} />
                </div> 
            </div>

            <div style={{display: "flex"}} >
                <div className="" style={{width:"50%"}}>
                    <b>객체 삭제 (프로젝트) </b>
                    <BarChart width={width} height={height} data={projectDat4} />
                </div>

                <div className="" style={{width:"50%"}}>
                    <b>객체 삭제 (작업) </b>
                    <BarChart width={width} height={height} data={projectDat2} />
                </div> 
            </div>
            
        </ProjectSections>
    )
}
export default ProjectManage7;
