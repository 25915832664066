import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import { openInfoPage }from "../common/stringUtil";

interface project {
    id: number;
    title: string;
    count: number;
}


const ProjectManage11:FC<any> = ({  infoDataMap }) => {
    const projects: project[] = [
        { id: 1, title: "등록된 프로젝트 수"  , count: (infoDataMap?.DRN_PRJ_CNT ? infoDataMap?.DRN_PRJ_CNT : 0 ) },
        { id: 2, title: "촬영요청"           , count: (infoDataMap?.RE_DRN_PRJ_CNT ? infoDataMap?.RE_DRN_PRJ_CNT : 0 )  },
        { id: 3, title: "완료 된 프로젝트 수" , count: (infoDataMap?.COM_DRN_PRJ_CNT ? infoDataMap?.COM_DRN_PRJ_CNT : 0 )  },
    ];

    return (
        <ProjectSections title="비행촬영 프로젝트" className="col-9 col-lg-8 col-md-12 user-project" onButtonClick={() => openInfoPage("P002")} buttonClassName="icon--more" >
            <div className="user-project-list ">
                {projects.map(project => (
                    <div className="project-item" key={project.id}>
                        <div className="project-item-title">{project.title}</div>
                        <div className="project-item-count">{project.count}</div>
                    </div>
                ))}
            </div>
        </ProjectSections>
    )
}
export default ProjectManage11;
