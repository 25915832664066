import { combineReducers } from 'redux';
import authReducer from './authuser/reducer';
import count from './count/reducer';
import user from './user/reducer';
import support from './support/reducer';
import common from './common/reducer';
import sltn from './sltn/reducer'
import product from './product/reducer'
import project from './proj/reducer'
import myPage from './myPage/reducer';
import ognz from './ognz/reducer';
import { createSaga, userSaga } from './user';
import { authSaga, POST_LOGOUT } from './authuser'; 
import { all, call, fork } from 'redux-saga/effects';
import { supportSaga } from './support';
import { commonSaga } from './common';
import { sltnSaga } from './sltn';
import { productSaga } from './product';
import { projectsSaga } from './proj';
import { myPageSaga } from './myPage';


import m from './myPage';
import { ognzSaga } from './ognz';

const appReducer = combineReducers({
  auth: authReducer,
  count,
  user,
  support,
  common,
  sltn,
  product,
  project,
  myPage,
  ognz,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
  if (action.type === POST_LOGOUT) {
    state = undefined; // 전체 상태 초기화
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

//saga
function* rootSaga() {
  yield all([
    userSaga(),
    createSaga(),
    authSaga(),
    supportSaga(),
    commonSaga(),
    sltnSaga(),
    productSaga(),
    projectsSaga(),
    myPageSaga(),
    ognzSaga(),
  ]);
}

export { rootReducer, rootSaga }
export default rootReducer