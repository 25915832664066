import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Banners from "../../containers/banner";
import List from "./List";
import DetailView from "./DetailView";
import { SearchSupportDto } from '../../types/support/SearchSupportDto';

interface PageProps {
    title: string;
}

const Notice: FC<PageProps> = ({ title }) => {
    const navigate = useNavigate();

    const handleSelectItem = (id: number) => {
        navigate(`/notice/${id}`);
    };

    return (
        <div className='board'>
            <Banners title={title} />
            <List />                
        </div>
    );
}

export default Notice;
