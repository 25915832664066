import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import MypageLayout from "./MypageLayout";
import "./MypageUserManage.scss";

import { PotalCommDto } from '../../types/PotalCommDto';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { getMyPageUserListAsync , deleteMyPageUserAsync  , putUserRoleAsync , putUserGroupRoleAsync } from '../../modules/myPage';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/paging';
import MypageUserInviteModal from './MypageUserInviteModal';
import Confirm from '../../components/Confirm';
import Alert from '../../components/Alert';
import SelectBox from '../../components/SelectBox';

interface PageProps {
title: string;
pageTitle: string;

}

const MypageUserManage: FC<PageProps> = ({ title, pageTitle}) => {
	const navigate = useNavigate();

	
	const [pageingDto, setPageing] = useState<PotalCommDto>({
        currentPageNo : 1
      , pageUnit : 10
      , pageSize : 10
      , firstIndex : 0
      , totalCnt   : 0
      , firstPageNo : 0
      , lastPageNo : 0
      , prevPageNo : 0
      , nextPageNo : 0
      , listTotalCnt : 0
    });
    const dispatch = useDispatch();
    const userListInfo  = useSelector((state:RootState) => state.myPage.data);
	const delResult     = useSelector((state:RootState) => state.myPage.delResult);
	const updateUserRole     = useSelector((state:RootState) => state.myPage.updateUserRole);
	const updateUserGroupRole     = useSelector((state:RootState) => state.myPage.updateUserGroupRole);

	const [searchType , setSearchType] = useState("");
	const [searchKeyword , setSearchKeyword] = useState("");
	const [newDialog,  setIsNewProjectCreateOpen] = useState(false);
	const [delDialog,  setIsDelProjectCreateOpen] = useState(false);
	const [alertlDialog,  setIsAlertOpen] = useState(false);
	const [alertContext , setAlertContext] = useState<string[]>([]);
	const [checkItems, setCheckItems] = useState<string[]>([]);
	const [process,setProcess]= useState("");

    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy : ""
      , currentPageNo : 1
      , pageUnit : 10
    });

    var listTotalCnt = 0;
    var totalCnt = 0;
    var pageUnit = 10;
    var currentPageNo = 1;

    if (userListInfo.data ) {
        if (userListInfo.data.meta) {
            totalCnt = Number(userListInfo.data.meta.totalCnt);
            pageUnit = userListInfo.data.meta.pageUnit;
            currentPageNo = userListInfo.data.meta.currentPageNo;
        }
        
        if (userListInfo.data.data) {
           listTotalCnt = Number(userListInfo.data.data.length);
        }

        console.log('리턴 결과:', JSON.stringify(userListInfo.data, null, 2));

		if (userListInfo.data.status == "error") {
			// alert(userListInfo.data.error.message);
		}
    }

	useEffect(() => {
		const userRoleCd = sessionStorage.getItem('userRoleCd');
	
		if (userRoleCd && userRoleCd != "ADMIN") {
			alert("화면에 접근 권한이 없습니다.");
			navigate("/");
		}

		setAlertContext(["기관 담당자는 서비스 별 권한을 설정할 수 있습니다."
					   , "프로젝트 관리자는 프로젝트 생성/수정/삭제 등 모든 권한을 갖고 있습니다."
					   , "프로제트 조회자는 프로젝트 조회 권한을 갖고 있습니다."]
		);

		handleSearch(1);
	},[]);

	const handleSearch = (pageNo : number) => {
		const ognzUserGrpSn = sessionStorage.getItem('ognzUserGrpSn');

		if (!ognzUserGrpSn) {
			alert("로그인 후 사용이 가능합니다.");
			navigate("/login");
		}

		const fetchData = async () => {

			searchParams.searchType = searchType;
			searchParams.searchKeyword = searchKeyword;
			searchParams.currentPageNo = pageNo;

			// console.log('parameter 결과:', JSON.stringify(searchParams, null, 2));
            await dispatch(getMyPageUserListAsync.request(searchParams));
        };

        fetchData();
    };

    useEffect(() => {
       //  handleSearch(1);

        if (userListInfo.data) {
            if (userListInfo.data.meta) {
                setPageing({ 
                    currentPageNo : userListInfo.data.meta.currentPageNo
                  , pageUnit : userListInfo.data.meta.pageUnit
                  , pageSize : userListInfo.data.meta.pageSize
                  , firstIndex : userListInfo.data.meta.firstIndex
                  , totalCnt   : userListInfo.data.meta.totalCnt
                  , firstPageNo : userListInfo.data.meta.firstPageNo
                  , lastPageNo : userListInfo.data.meta.lastPageNo
                  , prevPageNo : userListInfo.data.meta.prevPageNo
                  , nextPageNo : userListInfo.data.meta.nextPageNo
                  , listTotalCnt : userListInfo.data.data.length
              });
            }
        }
      },[searchParams,dispatch]);

   

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

		handleSearch(1);
    };

	const handleSelectTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		setSearchType(value);
    };

	const handleInputKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearchKeyword(value );
    };

	const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleSearch(1);
		}
	  };

	const openDialog = () => {
		if (checkItems.length == 0) {
			alert("삭제할 회원을 선택해 주십시오");
			return;
		}
		setIsDelProjectCreateOpen(true);
    };

	const closeDialog = (value: string) => {
		setIsDelProjectCreateOpen(false);

		if (value == "Y") {
			dispatch(deleteMyPageUserAsync.request(checkItems));  
			setProcess("DEL_USER");
		}
    };

	const openDialog02 = () => {
		setIsNewProjectCreateOpen(true);
    };  

	const closeDialog02 = () => {
		setIsNewProjectCreateOpen(false);
    };

	const openDialog03 = () => {
		setIsAlertOpen(true);
    };

	const closeDialog3 = () => {
		setIsAlertOpen(false);
    };

	 // 체크박스 단일 선택
	 const handleSingleCheck = (checked:boolean, id:string) => {
		if (checked) {
		  // 단일 선택 시 체크된 아이템을 배열에 추가
		  setCheckItems(prev => [...prev, id]);
		} else {
		  // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
		  setCheckItems(checkItems.filter((el) => el !== id));
		}
	  };
	
	  // 체크박스 전체 선택
	  const handleAllCheck = (checked:boolean) => {
		if(checked) {
		  // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
		  const idArray: string[] = userListInfo.data.data.filter((el:any) => el.userRoleCd != 'ADMIN').map((el:any) => el.userId);

		  setCheckItems(idArray);
		}
		else {
		  // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
		  setCheckItems([]);
		}
	  }

	  useEffect(() => {
		if (process == "DEL_USER") {
			if (delResult.data && delResult.data.status == "error") {
				alert(delResult.data.error.message.message);
				setProcess("");
			} else if (delResult.data && delResult.data.status == "success") {
				alert("회원 삭제가 완료되었습니다.");
				setProcess("");
				handleSearch(searchParams.currentPageNo!);
			}
		}
		
	   },[delResult]);

	   	const handleUpdateUserRoleChange = (params : any ,value: string) => {
			
			dispatch(putUserRoleAsync.request({
				userIds: [params.userId],
				role: value
			}));

			setProcess("UPD_USER_ROL");
		};

		useEffect(() => {
			if (process == "UPD_USER_ROL") {
				if (updateUserRole.data && updateUserRole.data.status == "error") {
					alert(updateUserRole.data.error.message.message);
					setProcess("");
				} else if (updateUserRole.data && updateUserRole.data.status == "success") {
					alert("권한 변경이 완료되었습니다.");
					setProcess("");
					handleSearch(searchParams.currentPageNo!);
				}
			}
			
		   },[updateUserRole]);

		   const handleUpdateRoleChange = (params : any , sltnId:string, value: string) => {
        	// 함수 정의
			// 로컬 상태 업데이트
			const ognzUserGrpSn = sessionStorage.getItem('ognzUserGrpSn');
			
			dispatch(putUserGroupRoleAsync.request({
				groupId: String(ognzUserGrpSn),
				sltnId: sltnId,
				userIds: [params.userId],
				role: value
			}));

			setProcess("UPD_GRP_USER_ROL");
	
			// alert("권한이 변경되었습니다");
    	};

		const handleUpdateRoleChange1 = (params : any , value: string) => {
        	handleUpdateRoleChange(params,"GEO_NIC",value);
    	};

		const handleUpdateRoleChange2 = (params : any , value: string) => {
        	handleUpdateRoleChange(params,"SIMMETA",value);
    	};

		const handleUpdateRoleChange3 = (params : any , value: string) => {
        	handleUpdateRoleChange(params,"MAPPRIME",value);
    	};

		useEffect(() => {
			if (process == "UPD_GRP_USER_ROL") {
				if (updateUserGroupRole.data && updateUserGroupRole.data.status == "error") {
					alert(updateUserGroupRole.data.error.message.message);
					setProcess("");
				} else if (updateUserGroupRole.data && updateUserGroupRole.data.status == "success") {
					alert("권한 변경이 완료되었습니다.");
					setProcess("");
					handleSearch(1);
				}
			}
			
		   },[updateUserGroupRole]);

	return (
		<MypageLayout bannerTitle={title} pageTitle={pageTitle}>
			<div className="board-list">
				<div className="row">
					<div className="container">
						
						<div className="form-search">
								<fieldset>
									<legend className="sr-only">프로젝트 검색</legend>
									<div className="search-input">
										<select name="searchType" id="searchType" className="form-select" title="등급 선택" onChange={handleSelectTypeChange} defaultValue={searchParams.searchType}>
										<option value="">구분</option>
											<option value="user_nm">이름</option>
                                            <option value="user_eml">이메일</option>
                                            <option value="ognz_nm">소속 기관</option>
										</select>
										<input type="text" id="searchKeyword" name="searchKeyword" value={searchKeyword} className="form-control" title="검색어 입력" placeholder="검색어를 입력하세요" onChange={handleInputKeywordChange} onKeyUp={handleKeyUp} />
										<button type="submit" onClick={() => handleSearch(1)} className="button button--primary button--sm  ">검색</button>
									</div>
								</fieldset>
						</div>

						<div className="board-controls user-controls">
							<span className="board-display"><span>총 게시물</span> <em>({listTotalCnt}건</em>/{totalCnt}건)</span>
							<div className="board-sort">
								<div>
									<select id="searchOrderByCol" name="searchOrderByCol" className="form-select" title="정렬 선택" onChange={handleSelectChange}>
										<option value="REG_DT">최신순</option>
										<option value="TYPE_CD">구분</option>
										<option value="TTL">제목</option>
									</select>
									<select id="pageUnit" name="pageUnit" className="form-select" title="게시판 목록 수 선택" onChange={handleSelectChange}>
										<option value="10" >10개씩 보기</option>
										<option value="20">20개씩 보기</option>
										<option value="30">30개씩 보기</option>
										<option value="40">40개씩 보기</option>
										<option value="50">50개씩 보기</option>
									</select>
								</div>
								<div>
								<button	onClick={openDialog} className="button button--sm button--outline-black">선택 회원 삭제</button>
								<button onClick={openDialog02} className="button button--sm button--outline-black">신규 회원 초대</button>
								</div>
							</div>
							
						</div>
						
						{/* board-controls */}
						<ul className='board-table user-tbl'>
							<li className="thead">
								<span className='col-th small'><input type="checkbox" id="save" onChange={(e) => handleAllCheck(e.target.checked)}  checked={checkItems.length === listTotalCnt ? true : false}/></span>
								<span className='col-th small'>번호</span>
								<span className='col-th'>등록일</span>
								<span className='col-th'>이름</span>
								<span className='col-th'>소속기관</span>
								<span className='col-th'>아이디</span>
								<span className='col-th'>등급</span>
								<span className='col-th'>영상촬영 권한 <i className="ico-user-btn"><img src="../../../images/icon/ico-user.png" alt="" onClick={openDialog03} /></i></span>
								<span className='col-th'>AI 데이터 권한 <i className="ico-user-btn"><img src="../../../images/icon/ico-user.png" alt="" onClick={openDialog03} /></i></span>
								<span className='col-th'>디지털트윈 권한 <i className="ico-user-btn"><img src="../../../images/icon/ico-user.png" alt="" onClick={openDialog03} /></i></span>
							</li>
							
							{userListInfo.loading ? (
                              <li className="empty"> loading...</li>
                            ) : userListInfo.data && userListInfo.data.data && userListInfo.data.data.length > 0 ? ( 
                            userListInfo.data.data && userListInfo.data.data.map((item: any, idx: number) => 

							<li className="tbody">
								<span className='col-td small' style={{verticalAlign:"middle"}}><input type="checkbox" id="save" onChange={(e) => handleSingleCheck(e.target.checked, item.userId)} checked={checkItems.includes(item.userId) ? true : false} disabled={item.userRoleCd == 'ADMIN' ? true : false} /></span>
								<span className='col-td small' style={{verticalAlign:"middle"}}>{totalCnt - (pageUnit * (currentPageNo -1) + idx)}</span>
								<span className='col-td' style={{verticalAlign:"middle"}}>{item.regDt.substring(0,10)}</span>
								<span className='col-td' style={{verticalAlign:"middle"}}>{item.userName}</span>
								<span className='col-td' style={{verticalAlign:"middle"}}>{item.ognzName}</span>
								<span className='col-td' style={{verticalAlign:"middle"}}>{item.userId}</span>
								
								<span className='col-td'>
									<SelectBox 
										objectId="searchType"
										cdGrpId="USER_ROLE_CD"
										defValue={item.userRoleCd}
										params={item}
										disabled={item.userId == sessionStorage.getItem('userId') ? (true) : (false)}
										onChange={handleUpdateUserRoleChange}
									/>
								</span>
								<span className='col-td'>
									<SelectBox 
										objectId="searchType"
										cdGrpId="ROLE_CD"
										defValue={item.roleCd2}
										params={item}
										disabled={item.userId == sessionStorage.getItem('userId') ? (true) : (false)}
										onChange={handleUpdateRoleChange1}
									/>
								</span>
								<span className='col-td'>
									<SelectBox 
										objectId="searchType"
										cdGrpId="ROLE_CD"
										defValue={item.roleCd1}
										params={item}
										disabled={item.userId == sessionStorage.getItem('userId') ? (true) : (false)}
										onChange={handleUpdateRoleChange2}
									/>
								</span>
								<span className='col-td'>
									<SelectBox 
										objectId="searchType"
										cdGrpId="ROLE_CD"
										defValue={item.roleCd}
										params={item}
										disabled={item.userId == sessionStorage.getItem('userId') ? (true) : (false)}
										onChange={handleUpdateRoleChange3}
									/>
								</span>
								
							</li>)
							) : (<li className="empty"> 등록 된 글이 없습니다. </li>)
						}
						</ul>

						{ userListInfo.data && userListInfo.data.meta  ? ( 
                    <Pagination key="Pagination" pageingDto={userListInfo.data.meta} onClick={handleSearch} />        
                       ) : (<span></span>) }
						
						<Confirm openDialog={delDialog} closeDialog={closeDialog} title="선택 된 회원을 삭제하시겠습니까?" />

                        <MypageUserInviteModal openYn={newDialog} closeDialog={closeDialog02} />

						<Alert openDialog={alertlDialog} closeDialog={closeDialog3} title="권한 안내" context={alertContext} />

						

					</div>
				</div>
				
			</div>
			

		</MypageLayout>
	);
};

export default MypageUserManage;
