import { FC } from 'react';
import Banners from "../../containers/banner";
import MypageTitle from "./MypageTitles";
import './MypageLayout.scss';

interface MypageLayoutProps {
    bannerTitle: string;
    pageTitle: string;
    children: React.ReactNode;
}

const MypageLayout: FC<MypageLayoutProps> = ({ bannerTitle, pageTitle, children }) => {
    return (
        <div className="mypage board">
            <Banners title={bannerTitle} />
            <div className="row">
                <div className="container">
                    <MypageTitle pageTitle={pageTitle} />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default MypageLayout;