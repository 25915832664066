import { Dispatch, FC, SetStateAction, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./logo";
import GNB from "./gnb";
import HeaderUtilContainer from "./HeaderUtilContainer";
import HeaderAlert from "./HeaderAlert";
import HeaderProfile from "./HeaderProfile";
import MobileGNB from "./mgnb";
import MobileGnbControl from "./MobileGnbControl";
import "../../styles/components/icon.scss";
import "../../styles/layout/header.scss";
import "../../styles/layout/content.scss";
import config from "../../config";
import { openMapprimePage, openNeospectraPage } from "../../pages/common/openSiteUtil";
import { openInfoPage } from "../../pages/common/stringUtil";

interface Header2Props {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const Header2: FC<Header2Props> = ({ isLoggedIn, setIsLoggedIn }) => {
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleLinks = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <header
      className={`header ${isHeaderActive ? "active" : ""}`}
      id="header"
      role="banner"
    >
      <div className="header--wrap">
        <div className="header--container">
          <Logo />
          <GNB onHoverChange={setIsHeaderActive} />
          <MobileGNB />
          <HeaderUtilContainer>
            {isLoggedIn ? (
              <>
                <HeaderAlert />
                <HeaderProfile setIsLoggedIn={setIsLoggedIn} />
                <div className="header-util-quick">
                  <button
                    className="icon--quick"
                    onClick={toggleLinks}
                    title={isExpanded ? '목록 닫힘' : '목록 열림'}
                    aria-expanded={isExpanded}
                  >
                    <span className="sr-only">링크 바로가기</span>
                    <i></i>
                  </button>
                  <div className="header-util-quick-inner -toolbox">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        openInfoPage("P001");
                      }}
                      
                      className="vider-servie"
                      rel="noopener noreferrer"
                    >
                      <i></i>
                      <span>영상촬영<br /> 서비스</span>
                    </a>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        openNeospectraPage();
                      }}
                      className="AIdata-srervice"
                      rel="noopener noreferrer"
                    >
                      <i></i>
                      <span>AI 데이터<br /> 생성관리 서비스</span>
                    </a>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        openMapprimePage();
                      }}
                      className="dtMap-service"
                      rel="noopener noreferrer"
                    >
                      <i></i>
                      <span>디지털트윈<br />지도 서비스</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <div className="header-util-link">
                <Link to={"/login"} className="header-util-login">
                  <i className="icon--login"></i>
                  <span>로그인</span>
                </Link>
                <Link to={"/register"} className="header-util-register">
                  <i className="icon--register"></i>
                  <span>회원가입</span>
                </Link>
              </div>
            )}

          </HeaderUtilContainer>
          <MobileGnbControl />
        </div>
      </div>
    </header>
  );
};
export default Header2;
