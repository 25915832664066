import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { useParams, Link } from 'react-router-dom';
import Banners from "../../containers/banner";
import '../../styles/components/input.scss';
import '../../styles/components/icon.scss';
import './board-table.scss';
import './board-view.scss';
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { getNoticeAsync } from '../../modules/support';
import { getFileDownLoad } from '../../api/common';


interface PageProps {
    title: string;
}

const View:FC<PageProps> = ({ title }) => {
    
    const { id  } = useParams<{ id: string }>();

    const [searchParams, setFormData] = useState<SearchSupportDto>({
        ntcSn : Number(id)
    });

    if (searchParams.ntcSn != Number(id)) {
        setFormData({...searchParams, ntcSn : Number(id)});
    }

    const dispatch = useDispatch();
    const supportDetail  = useSelector((state:RootState) => state.support.data);

    let ntcTtl    = "";
    let ntcTypeNm = "";
    let regDt = "";
    let ntcCn = "";
    if (supportDetail.data) {
        console.log('리턴 결과1:', JSON.stringify(supportDetail.data, null, 2));
        ntcTtl    = supportDetail.data.data[0].ntcTtl;
        ntcTypeNm = supportDetail.data.data[0].ntcTypeNm;
        regDt     = supportDetail.data.data[0].regDt;
        ntcCn     = supportDetail.data.data[0].ntcCn;

    }
  
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getNoticeAsync.request(searchParams));
        };

        fetchData();
        
      },[searchParams]);

      let fileList = [];
      let beforNtc = [];
      let afterNtc = [];

     

      const handleClick = (ntcSn : number , atflSn : number , event: React.MouseEvent<HTMLButtonElement> ) => {
       // e.target.attributes();

        getFileDownLoad('ntc' , ntcSn , atflSn );
      }

// <button type='button' title='파일 미리보기'><i className='icon--preview' aria-hidden='true'></i>미리보기</button>

      if (supportDetail.data && supportDetail.data.data && supportDetail.data.data.length > 0) {
          let noticeDetail = supportDetail.data.data[0];
          if (noticeDetail.ntcAtfl && noticeDetail.ntcAtfl.length > 0) {
            
            for (let i=0;i<noticeDetail.ntcAtfl.length;i++) {
                let ntcSn = noticeDetail.ntcAtfl[i].ntcSn;
                let atflSn = noticeDetail.ntcAtfl[i].atflSn;
                
                let paddingSize = "10px";
                if (i == noticeDetail.ntcAtfl.length -1) {
                    paddingSize = "0px";
                }
                
                fileList.push(<li key={i} style={{paddingBottom:paddingSize}}>
                    <button type='button' className='file-name' title='다운로드' onClick={(e)=>{handleClick( ntcSn , atflSn , e )}}>
                        <i className={'icon--'+ noticeDetail.ntcAtfl[i].atflOrgnlNmExt} aria-hidden='true'></i> 
                        <span className="files-name">{noticeDetail.ntcAtfl[i].atflOrgnlNm}</span>
                    </button>
                    <div className='files-utils'>                            
                        <button type='button' title='파일 다운로드' onClick={(e)=>{handleClick( ntcSn , atflSn , e )}}><i className='icon--download' aria-hidden='true' ></i>다운로드</button>
                    </div>
                </li>
                );
            }
          } else {
            fileList.push("첨부파일이 없습니다.");
          }

          if (noticeDetail.beforNtc) {
            beforNtc.push(
                <Link to={'/notice/'+noticeDetail.beforNtc.ntcSn} className='prev-post'>
                    <span className="post-label"><i className='icon--arwup-black' aria-hidden='true'></i> 이전글</span>
                    <span className="post-title">{noticeDetail.beforNtc.ntcTtl}</span>
                    <span className="post-date">{noticeDetail.beforNtc.regDt.substring(0,10)}</span>
                </Link>
            );

          } else {
            beforNtc.push(
               <div className="empty">이전글이 없습니다.</div>
            );
          }

          if (noticeDetail.afterNtc) {
            afterNtc.push (
                <Link to={'/notice/'+noticeDetail.afterNtc.ntcSn} className='next-post'>
                    <span className="post-label"><i className='icon--arw-black' aria-hidden='true'></i> 다음글</span>
                    <span className="post-title">{noticeDetail.afterNtc.ntcTtl}</span>
                    <span className="post-date">{noticeDetail.afterNtc.regDt.substring(0,10)}</span>
                </Link>
            );

          } else {
            afterNtc.push (
               <div className="empty">다음글이 없습니다.</div>
            );
          }
      }

      const MyComponent = (ntcCn:string ) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: ntcCn }}
          />
        );
      };

    return (
        <div className="board-view">
            <Banners title={title} />
            <div className="row">
                <div className="container">
                    <div className="board-view-header">
                        <h3>
                            <em>[{ntcTypeNm}]</em>
                            <span>{ntcTtl}</span>
                        </h3>
                        <div className="date">{regDt}</div>
                    </div>
                
                    <div className='board-view-files'>
                        <ul>
                           {fileList}
                        </ul>
                    </div>

                    <div className='board-view-content'>
                    { MyComponent( ntcCn ) }
                    </div>

                    <div className='post-nav'>
                        {beforNtc}
                        {afterNtc}
                    </div>

                    <div className="board-foot-buttons">
                        <Link to={'/notice'} className='button button--outline-black button--md'>목록으로</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default View;