import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import DonutChart from "../../components/DonutChart";
import { getCurrentDate } from "../../pages/common/dateUtil";

const ProjectManage5:FC<any> = (  projectDataMap ) => {
   
    
    const width  = 300;
    const height = 250;

    const projectDat1 : {name:string , value:number}[] = [];
    const projectDat2 : {name:string , value:number}[] = [];

     const progressCnt = (projectDataMap  && projectDataMap.projectCntDataMap && projectDataMap.projectCntDataMap.asset ? projectDataMap.projectCntDataMap.asset.progress : 0);
     const doneCnt     = (projectDataMap && projectDataMap.projectCntDataMap && projectDataMap.projectCntDataMap.asset ? projectDataMap.projectCntDataMap.asset.done : 0);

    // const progressCnt =100;
    // const doneCnt     =50;


    projectDat1.push({name : "생성중인 에셋 ( " +progressCnt+ " ) " , value : (progressCnt) });
    projectDat2.push({name : "완료된 에셋 ( " +doneCnt+ " ) "       , value : (doneCnt)     });

    const currentDate = getCurrentDate("YYYY-MM-DD");
 
    return (
        <ProjectSections title={`에셋 생성 현황 ${currentDate}`} className="col-3 col-lg-12 user-assets" >
            <div style={{paddingTop:"20px"}}>
                <DonutChart width={width} height={progressCnt == 0 && doneCnt == 0 ? 100 : height} fillColor='#0088FE' data={projectDat1} />
            </div>
            <div style={{paddingBottom:"50px"}}>
                <DonutChart width={width} height={progressCnt == 0 && doneCnt == 0 ? 100 : height} fillColor='#00C49F' data={projectDat2} />
            </div>
        </ProjectSections>
    )
}
export default ProjectManage5;
