import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import BarChart from "../../components/BarChart";
import Progress from "./Progress";

type props = {
    neoUsagesServies: any[];
};

const ProjectManage9:FC<props> = ( { neoUsagesServies } ) => {
    
    return (
        <ProjectSections title="서비스 남은 수량" className="col-12 col-lg-8 col-md-12 user-state" >
             {neoUsagesServies && neoUsagesServies?.length > 0 ? (neoUsagesServies.map((project:any) => (
                <Progress 
                    item={{ id: project.category_code, title: project.category_name, count: project.percent , text : project.used  + "/" + project.available }} 
                    theme="blue"
                />
             ))):null}
            
        </ProjectSections>
    )
}
export default ProjectManage9;
