import axios from 'axios';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import {PotalCommDto} from '../types/PotalCommDto';
import {CreateUserJoinReqDto} from '../types/myPage/CreateUserJoinReqDto';
import { UserDto } from '../types/UserDto';
import { SelectNeospectraReqDto } from '../types/myPage/SelectNeospectraReqDto';
import { accessTokenInfoseedApi, accessTokenNeospectraApi } from './api';
import accessTokenPortalApi from './api';
import config from '../config';
import { stringify } from 'querystring';

interface ApiResponseList {
  status: string;
  data?: any[];
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface ApiResponse {
  status: string;
  data?: any;
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface SuccessResponse<T> {
  status: 'success';
  data: T;
}

interface ErrorResponse {
  status: 'error';
  error: {
    code: string;
    message: string;
  };
}

interface InfoseedDashboardApiResponse {
  status : string;
  data? : any
}
	

export async function getMyPageUserList(param : SearchSupportDto): Promise<any | null> {
  try {
    const ognzUserGrpSn = sessionStorage.getItem('ognzUserGrpSn');
    
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/usergroup/`+ognzUserGrpSn,
        {
          params : param
        }
    );
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      }, 
    };
  }
};

export async function getMyPageUserInvite(): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/myPage/user/invite`,
    );
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function postMyPageUserInvite(param : CreateUserJoinReqDto): Promise<any | null> {
  try {
    const access_token  = sessionStorage.getItem('access_token');
    
    param.returnUrl = window.location.protocol + '//' + window.location.host;

    const response = await accessTokenPortalApi.post<Promise<ApiResponse> | null>(
      `${config.portalApiUrl}/myPage/user/invite`,
        param ,
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '신규 회원 초대시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function deleteMyPageUser(param : string[]): Promise<any | null> {
  try {
    const ognzUserGrpSn = sessionStorage.getItem('ognzUserGrpSn');
    
    const response = await accessTokenPortalApi.delete<Promise<ApiResponse> | null>(
      `${config.portalApiUrl}/usergroup/group/`+ognzUserGrpSn+`/users`,
        {
          data: {
            id: param
          },
        }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '회원 삭제시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function getUserInfo(): Promise<UserDto | null> {
  try {
    const userId  = sessionStorage.getItem('userId');
    const response = await accessTokenPortalApi.get<ApiResponse>(
      `${config.portalApiUrl}/user/${userId}`,
    );

    console.log('response.data 결과:', JSON.stringify(response.data, null, 2));
    if (response.data.status === 'success') {
      return response.data.data || null;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    return null;
  }
}


export async function putUserInfo(userDto: UserDto): Promise<SuccessResponse<ApiResponse> | ErrorResponse> {
  try {
    const userId  = sessionStorage.getItem('userId');
    const response = await accessTokenPortalApi.put<SuccessResponse<ApiResponse> | ErrorResponse>(
      `${config.portalApiUrl}/user/${userId}`,
      userDto,
    );
    

    return response.data; // 업데이트된 사용자 데이터를 반환
    
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '회원정보 수정시 오류가 발생하였습니다.',
      },
    };
  }
}

export async function postUserPwdCheck(userDto: UserDto): Promise<SuccessResponse<ApiResponse> | ErrorResponse> {
  try {
    const access_token  = sessionStorage.getItem('access_token');
    const response = await axios.post<SuccessResponse<ApiResponse> | ErrorResponse>(
      `${config.portalApiUrl}/auth/validate/password`,
      userDto,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
      
    );

    return response.data; // 업데이트된 사용자 데이터를 반환
    
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '기존 비밀번호 유효성 체크시 오류가 발생하였습니다.',
      },
    };
  }
}

export async function getMyPageContactUsList(searchSupportDto: SearchSupportDto): Promise<any | null> {
  try {
    const access_token  = sessionStorage.getItem('access_token');
    const response = await axios.get<ApiResponseList>(
      `${config.portalApiUrl}/contactUs/mypage`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params : searchSupportDto
      }
    );

    return response.data; // 업데이트된 사용자 데이터를 반환
    
  } catch (error) {
    console.error('Error updated user:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
}

// 서비스별 프로젝트 갯수 조회
export async function getNeospectraProjectCnt({ access_token }: { access_token: string }): Promise<any[] | null> {
  try {

      const response = await accessTokenNeospectraApi.get<any[]>(
          `${config.neospectraApiUrl}/project/v1/e/usages/projects/count`,
      );
       console.log('getNeospectraProjectCnt api 리턴 결과:', JSON.stringify(response.data, null, 2));

      return response.data;
  } catch (error) {
      // 토큰 인증 실패 처리
      console.error('Failed to getNeospectraProjectCnt:', error);

      return null;
  }
};

// 날짜별 프로젝트 통계 조회
export async function getNeospectraProjectStats({ access_token , param }: { access_token: string , param : SelectNeospectraReqDto }): Promise<any | null> {
  try {
      
      const returnMap = {
          resultList1 : []
        , resultList2 : []
      };

      param.category = "OBJECT_DETECTION";
      const response = await accessTokenNeospectraApi.get<[]>(
          `${config.neospectraApiUrl}/project/v1/e/usages/projects/stats`,
          {
             params:  param 
          }
      );

      returnMap.resultList1 = response.data;

      // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

      param.category = "OBJECT_DELETION";
      const response2 = await accessTokenNeospectraApi.get<[]>(
        `${config.neospectraApiUrl}/project/v1/e/usages/projects/stats`,
        {
            params:  param 
        }
    );

      console.log('response2 api 리턴 결과:', JSON.stringify(response2.data, null, 2));

    returnMap.resultList2 = response2.data;

      return returnMap;
  } catch (error) {
      // 토큰 인증 실패 처리
      if (axios.isAxiosError(error) && error.response?.status === 401) {
          alert("토큰 인증이 실패하였습니다.");
          console.error('토큰 인증 실패: 로그인 페이지로 리디렉션');
          // 로그아웃 처리 및 리디렉션 또는 리프레시 토큰 요청
          sessionStorage.removeItem('access_token'); // 토큰 삭제
          window.location.href = '/login'; // 로그인 페이지로 리디렉션
      } else {
          console.error('Failed to select:', error);
      }

      return null;
  }
};

// 날짜별 작업 통계 조회
export async function getNeospectraJobsStats({ access_token , param }: { access_token: string , param : SelectNeospectraReqDto }): Promise<any | null> {
  try {

    const returnMap = {
        resultList1 : []
      , resultList2 : []
    };

    param.category = "OBJECT_DETECTION";
    const response = await accessTokenNeospectraApi.get<[]>(
        `${config.neospectraApiUrl}/project/v1/e/usages/jobs/stats`,
        {
            params:  param 
        }
    );

    returnMap.resultList1 = response.data;

    param.category = "OBJECT_DELETION";
    const response2 = await accessTokenNeospectraApi.get<[]>(
        `${config.neospectraApiUrl}/project/v1/e/usages/jobs/stats`,
        {
            params:  param 
        }
    );

    returnMap.resultList2 = response2.data;
    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

    return returnMap;
  } catch (error) {
      console.error('Failed to project/v1/e/usages/jobs/stats:', error);

      return null;
  }
};

// 저장소 사용량 조회
export async function getNeospectraUsagesCurrent({ access_token }: { access_token: string }): Promise<any | null> {
  try {

      const response = await accessTokenNeospectraApi.get<any>(
          `${config.neospectraApiUrl}/storage/v1/e/usages/current`,
      );
      // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

      return response.data;
  } catch (error) {
      console.error('Failed to storage/v1/e/usages/current:', error);

      return null;
  }
};

// 서비스별 남은 사용자 조회
export async function getNeospectraUsagesServiesLeft({ access_token }: { access_token: string }): Promise<any[] | null> {
  try {

      const response = await accessTokenNeospectraApi.get<any[]>(
          `${config.neospectraApiUrl}/project/v1/e/usages/services/left`,
      );
      // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

      return response.data;
  } catch (error) {
      console.error('Failed to project/v1/e/usages/services/left:', error);

      return null;
  }
};


export async function updateUserRole({ userIds, role }: { userIds: string[], role: string }): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const access_token  = sessionStorage.getItem('access_token');

    const response = await axios.put<SuccessResponse<null> | ErrorResponse>(
      `${config.portalApiUrl}/user/role/users`,
      {
        userId: userIds,
        role: role
      },
      {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
      },
    );

    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting user from group:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '권한 변경시 오류가 발생하였습니다.',
      },
    };
  }
}

export async function updateGroupUserRole({ groupId, sltnId ,userIds, role }: { groupId: string, sltnId:string , userIds: string[], role: string }): Promise<SuccessResponse<null> | ErrorResponse> {
  try {
    const access_token  = sessionStorage.getItem('access_token');

    const response = await axios.put<SuccessResponse<null> | ErrorResponse>(
      `${config.portalApiUrl}/usergroup/role/${groupId}`,
      {
        sltnId:sltnId,
        userId: userIds,
        role: role
      },
      {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
        
      },
    );

    if (response.data.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting user from group:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '권한 변경시 오류가 발생하였습니다.',
      },
    };
  }
}

export async function getInfoseedMypage(): Promise<InfoseedDashboardApiResponse | null> {
  try {
    const access_token  = sessionStorage.getItem('access_token');

    const response = await accessTokenInfoseedApi.get<InfoseedDashboardApiResponse | null>(
      `${config.infoseedApiUrl}/v1/common/mypage`,
    );

    if (response.data?.status === 'success') {
      console.log('success');
      return response.data;
    } else {
      console.log('else');
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting user from group:', error);
    return {
      status: 'error',
    };
  }
}
