import { FC } from "react";
import Breadcrumb from "./Breadcrumb";
import "../../styles/layout/banners.scss";

interface BannersProps {
    title: string;
}

const banners:FC<BannersProps> = ({ title }) =>{
    return (
        <div className="banners">
            <div className="row">
                <div className="container">
                    <h2 className="banners--title">{title}</h2>
                    <Breadcrumb />
                </div>
            </div>
        </div>
    )
}

export default banners;