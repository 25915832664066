import { FC } from 'react';
import MainHeaderGroup from './MainHeaderGroup'; 

import './main-section3.scss';


const MainSection3:FC = () => {
    return (
        <section className='main-section3'>
            <div className="row">
                <div className="container">
                    <MainHeaderGroup>
                     3D Recon, 디지털트윈으로 이어지는<br />
                    <b>통합 업무 관리 서비스</b>를 제공합니다.
                    </MainHeaderGroup>
                    <div className="img"><img src="../images/main/ms3-img.png" alt="" /></div>
                </div>
            </div>
        </section>
    )
}

export default MainSection3;