import { createReducer } from 'typesafe-actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { getProductAsync , getProductItemAsync , getBuyKeyAsync , postBuyAsync , putBuyAsync , getBuyScrtnListAsync , getMapprimeDashboardAsync , getBuyUseAsync , getBuyUseListAsync } from './actions';
import { ProductAction, ProductState } from './types';
import { AnyAction } from 'redux';

const initialState: ProductState = {
  data: asyncState.initial(),
  product: asyncState.initial(),
  productItem: asyncState.initial(),
  buyKey: asyncState.initial(),
  buyInfo: asyncState.initial(),
  dashboardInfo: asyncState.initial(),
  buyUser: asyncState.initial(),
  buyUserList: asyncState.initial(),
};

const ognzReducer = createReducer<ProductState, ProductAction>(initialState)
.handleAction(
  getProductAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getProductAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    product: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getProductAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    product: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  getProductItemAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getProductItemAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    productItem: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getProductItemAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    productItem: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  getBuyKeyAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getBuyKeyAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    buyKey: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getBuyKeyAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    buyKey: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  postBuyAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  postBuyAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    buyInfo: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  postBuyAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    buyInfo: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  putBuyAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  putBuyAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  putBuyAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  getBuyScrtnListAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getBuyScrtnListAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    data: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getBuyScrtnListAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    data: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  getMapprimeDashboardAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getMapprimeDashboardAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    dashboardInfo: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getMapprimeDashboardAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    dashboardInfo: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  getBuyUseAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getBuyUseAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    buyUser: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getBuyUseAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    buyUser: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  getBuyUseListAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getBuyUseListAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    buyUserList: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getBuyUseListAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    buyUserList: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)

export default ognzReducer;