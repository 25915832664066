import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logoutAsync } from '../../modules/authuser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';

interface HeaderProfile2Props {
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}

const HeaderProfile: FC<HeaderProfile2Props> = ({ setIsLoggedIn }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [userName, setUserName] = useState<string | null>(null);
  const [ognzName, setOgnzName] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // 컴포넌트가 렌더링될 때 localStorage에서 userName 가져오기
    const storedUserName = sessionStorage.getItem('userName');
    const storedOgnzName = sessionStorage.getItem('ognzName');
    setUserName(storedUserName);
    setOgnzName(storedOgnzName);
  }, []);

  const toggleLinks = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLogout = () => {
    // 로그아웃 API 호출 (필요에 따라 서버에 로그아웃 요청)
    const refreshToken = sessionStorage.getItem('refresh_token');
    if (refreshToken) {
      dispatch(logoutAsync.request({ accessToken: refreshToken }));
      navigate('/');
    } else {
      console.error('No refresh token found in local storage.');
      // 필요시 추가적인 처리 로직 (예: 사용자 로그아웃 처리 또는 토큰 재발급 시도)
    }


    // 로그인 상태를 false로 설정
    setIsLoggedIn(false);
    // 메뉴 닫기
    setIsExpanded(false);
  };

  return (
    <div className="header--profile">
      <button
        type="button"
        className="header--profile-button"
        onClick={toggleLinks}
        title={isExpanded ? '목록 닫힘' : '목록 열림'}
        aria-expanded={isExpanded}
      >
        <span className="sr-only">사용자 메뉴</span>
        <i arai-hidden="true"></i>
      </button>
      <div className="header--profile--detail -toolbox">
        <div className="user-profile">
          <div className="user--avatar"></div>
          <div className="user--info">
            {userName && ognzName ? (
              <>
                <div className="name">{ognzName}</div>
                <div className="company">{userName}</div>
              </>
            ) : (
              <div>Loding...</div>
            )}
          </div>
        </div>
        <div className="buttons">
          <div>
            <Link to={'/mypage/project'} className="em" onClick={() => setIsExpanded(false)}>
              나의 프로젝트
            </Link>
          </div>
          <div>
            <button type="button" title="로그아웃" onClick={handleLogout}>
              로그아웃
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderProfile;
