import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import { openInfoPage , getFormatCurrency }from "../common/stringUtil";

interface Project {
    id: number;
    title: string;
    count: number;
}


const ProjectManage13:FC<any> = ({  infoDataMap }) => {
    const projects: Project[] = [
        { id: 1, title: "등록된 프로젝트 수"  , count: (infoDataMap?.PIC_PRJ_CNT ? infoDataMap?.PIC_PRJ_CNT : 0 ) },
        { id: 2, title: "촬영요청"           , count: (infoDataMap?.PIC_FILE_CNT ? infoDataMap?.PIC_FILE_CNT : 0 )  },
        { id: 3, title: "완료 된 프로젝트 수" , count: (infoDataMap?.COM_PIC_PRJ_CNT ? infoDataMap?.COM_PIC_PRJ_CNT : 0 )  },
    ];
    return (
        <ProjectSections title="현장조사사진 프로젝트" className="user-project" onButtonClick={() => openInfoPage("P003")} buttonClassName="icon--more" >
            <div className="user-project-list ">
                {projects.map(project => (
                    <div className="project-item" key={project.id}>
                        <div className="project-item-title">{project.title}</div>
                        <div className="project-item-count">{getFormatCurrency(project.count)}</div>
                    </div>
                ))}
            </div>
        </ProjectSections>
    )
}
export default ProjectManage13;
