import { FC, useState } from 'react';
import "../../styles/layout/mobile-navigation.scss";

const MobileGnbControl:FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        if (isMenuOpen) {
        document.body.classList.remove('menu-open');
        } else {
        document.body.classList.add('menu-open');
        }
        setIsMenuOpen(!isMenuOpen);
    };
    
    return (
        <button type="button" className="mobile-menu-control" onClick={toggleMenu}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
        </button>
    )
}

export default MobileGnbControl;