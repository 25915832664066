import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import '../../styles/components/table.scss';
import '../../styles/components/input.scss';
import Banners  from "../../containers/banner";
import { useNavigate } from 'react-router-dom';
import { CreateContactUsDto } from '../../types/support/CreateContactUsDto';
import { getContactUsAsync , postContactUsAsync , putContactUsAsync , deleteContactUsAsync } from '../../modules/support';
import SelectBox from '../../components/SelectBox';
import { getFileDownLoad }  from '../../api/common';
import { deleteFileAsync } from '../../modules/common';
import { getCurrentDate , getAddDays } from "../../pages/common/dateUtil";

interface WriteProps {
    title : string;
}

const ContactUsDetail:FC<WriteProps> = ({ title }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 결과 리턴값 설정
    const contactUsDetail  = useSelector((state:RootState) => state.support.data);
    const deleteResult     = useSelector((state:RootState) => state.support.contactUsDelete);

    // 변수 선언
    const [inqSn, setInqSn] = useState<number>(0);
    const [action, setAction] = useState<string>('');

    const [contactUs, setContactUs] = useState<any>({
        inqTypeCd: ""
      , inqTtl: ""
      , inqCn: ""
      , rlsStngCd:"ALL_OK"
      , inqTypeNm:""
      , regUserNm : ""
      , regDt :  getCurrentDate("YYYY.MM.DD")
    }); 
    
    // 화면 접근시 처리 로직 ( 수정 으로 접근시 조회)
    useEffect(() => {
        const fetchSearchData = async () => {
            const currentPath = window.location.pathname.split('/'); // 경로
            const inqSn = currentPath[currentPath.length-1];
            
            setInqSn(Number(inqSn));

            await dispatch(getContactUsAsync.request(Number(inqSn)));
        };

        fetchSearchData();

      },[]);

      // 화면 접근시 처리 로직 ( 수정 으로 접근시 조회)
    useEffect(() => {
        console.log('contactUsDetail 전송 결과:', JSON.stringify(contactUsDetail, null, 2));

        if (contactUsDetail && contactUsDetail.data && contactUsDetail.data.data) {
            setContactUs((pre:any) =>contactUsDetail.data.data);
        }

      },[contactUsDetail]);


    // 취소 버튼 클릭시
    const oncancel = async () => {
        navigate(-1);
    }

    const onUpdate = async () => {
        navigate("/contactUsWrite/"+inqSn);
    }

    // 파일 다운로드 버튼 클릭시
    const handleFileDownClick = (inqSn : number , atflSn : number , event: React.MouseEvent<HTMLButtonElement> ) => {
        // e.target.attributes();
 
        getFileDownLoad('contactUs' , inqSn , atflSn );
    }

    // 삭제 버튼 클릭시
    const onDel = async () => {
        const confirmDelete = window.confirm("정말로 삭제하시겠습니까?");
        if (confirmDelete) {
             await dispatch(deleteContactUsAsync.request(inqSn));
             await setAction("DELETE");
        } 
    };

    // 삭제 후처리
    useEffect(() => {
        if (action == "DELETE") {
            if (deleteResult && deleteResult.data) {
                const status = deleteResult.data.status;
        
                if (status == "error") {
                    alert(deleteResult.data.error.message);
                } else if (status == "success") {
                    alert("삭제 되었습니다.");
                    navigate("/contact");
                }
            }
        }
        
    },[deleteResult]);

    return (
        <div className='board'>
            <Banners title={title} />
        <div className="board-write">
            <div className="row">
                <div className="container">
                    <div className="table table-write">
                        <table>
                            <colgroup>
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '35%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '35%' }} />
                            </colgroup>
                            <tbody>
                                <tr style={{backgroundColor:"#dedbde"}}> 
                                    <td colSpan={4}><b>{ contactUs.inqTtl }</b></td>
                                </tr>
                                <tr>
                                    <th scope='row' style={{width:"15%"}}>작성자</th>
                                    <td style={{width:"35%"}}>{contactUs.regUserNm}</td>
                                    <th scope='row' style={{width:"15%"}}>작성일</th>
                                    <td style={{width:"35%"}}>{contactUs.regDt}</td>
                                </tr>
                                <tr>
                                    <th scope='row'>문의 항목</th>
                                    <td colSpan={3}>
                                        { contactUs.inqTypeNm }
                                    </td>
                                </tr>
                                
                                <tr>
                                    <th scope='row'>내용</th>
                                    <td colSpan={3}>
                                        <pre style={{whiteSpace: "pre-wrap"}}>{contactUs.inqCn}</pre>
                                    </td>
                                </tr>
                                <tr>
                                    <th >첨부파일</th>
                                    
                                        {contactUs.atflOrgnlNm ? (
                                            <td colSpan={3}>
                                            {contactUs.atflOrgnlNm}
                                            <button type='button' title='파일 다운로드' style={{marginLeft:"10px"}} onClick={(e)=>{handleFileDownClick( inqSn , 0 , e )}}><i className='icon--download' aria-hidden='true' ></i>다운로드</button>    
                                            </td>
                                        ) : (
                                            <td colSpan={3}></td>
                                        )}
                                </tr>
                                
                                <tr>
                                    <th scope='row'>공개 설정</th>
                                    <td colSpan={3}>
                                        { contactUs.rlsStngNm }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table table-write" style={{marginTop:"30px"}}>
                        <table>
                            <tbody>
                                <tr style={{backgroundColor:"#737bd9"}}> 
                                    <td colSpan={2} style={{color:"#ffffff"}}><b>공공 SAAS 답변</b></td>
                                </tr>
                                <tr>
                                    <th scope='row' style={{width:"15%"}}>작성자</th>
                                    <td style={{width:"85%"}}>{contactUs.rspnsCmptnUserNm}</td>
                                </tr>
                                <tr>
                                    <th scope='row'>답변</th>
                                    <td >
                                        { contactUs.rspnsCn }
                                    </td>
                                </tr>
                                <tr>
                                    <th scope='row'>답변일자</th>
                                    <td >
                                        { contactUs.rspnsCmptnDt }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='board-foot-buttons' style={{justifyContent: 'flex-end',gap:"10px"}}>
                        <button type='button' className='button button--sm button--outline-black' onClick={oncancel}>목록</button>
                        
                        {contactUs.regUserId == sessionStorage.getItem('userId') ? (
                            <button type='button' className='button button--sm button--primary' onClick={onUpdate}>수정하기</button>
                        ) : (
                            null
                        )}

                        {contactUs.regUserId == sessionStorage.getItem('userId') ? (
                            <button type='button' className='button button--sm button--primary'         onClick={onDel}>삭제하기</button>
                        ) : (
                            null
                        )}
                    </div>

                </div>
            </div>
            
        </div>
        </div>
    )
}

export default ContactUsDetail;