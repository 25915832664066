import { FC, useEffect,useState,ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import { useNavigate } from 'react-router-dom';
import Dialog from "../../components/Dialog";
import { UserDto } from '../../types/UserDto';
import Feedback from "../../components/Feedbacks";
import { putUserInfoAsync , postUserPwdCheckAsync } from '../../modules/myPage';
import { isPassWdFormatChk } from "../../pages/common/stringUtil";

type ButtonsProps = {
    openYn: boolean;
    userDto:UserDto
    closeDialog: () => void;
};

const MypageUserPassWordModal: FC<ButtonsProps> = ({ userDto , openYn , closeDialog }) => {  
    const dispatch = useDispatch();   
    const navigate = useNavigate();
    
    const [passwordFeedback, setPasswordFeedback] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const [passwordMatchFeedback, setPasswordMatchFeedback] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const [nowPassword,setNowPassword]= useState("");
    const [password,setPassword]= useState("");
    const [rePassword,setRePassword]= useState("");
    const [process,setProcess]= useState("");

    const userUpdResult  = useSelector((state:RootState) => state.myPage.result);
    const chkResult  = useSelector((state:RootState) => state.myPage.chkResult);

    const validatePassword = (password: string) => {
        // 비밀번호 형식 체크 (대소문사/숫자/특수문자 중 2가지 이상 조화, 10자~16자로 구성된 문자열로 구성되며, 공백 입력 불가능, 연속된 문자, 숫자 사용 불가능)
        if (isPassWdFormatChk(password)) {
            setPasswordFeedback({ type: 'success', message: '비밀번호 형식이 올바릅니다.' });
        } else {
            setPasswordFeedback({ type: 'error', message: '비밀번호 형식에 맞지 않습니다.' });
        }
    };

    const checkPasswordMatch = (password: string, rePassword: string) => {
        if (password === rePassword) {
            setPasswordMatchFeedback({ type: 'success', message: '비밀번호가 일치합니다.' });
        } else {
            setPasswordMatchFeedback({ type: 'error', message: '비밀번호가 일치하지 않습니다.' });
        }
    };

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        
        const { name, value } = e.target;

        if (name === 'userPassword') {
            setPassword(value);
            validatePassword(value);
            checkPasswordMatch(value, rePassword!);
        }

        if (name === 'userRePassword') {
            setRePassword(value);
            checkPasswordMatch(password, value);
        }
    };

    const setPasswd = () => {
        if (nowPassword == "") {
            alert("기존 비밀번호를 입력해 주십시오.");
            return;
        }

        if (password == "" || rePassword == "") {
            alert("변경 비밀번호를 입력해 주십시오.");
            return;
        }

        if (passwordFeedback && passwordFeedback.type == "success") {
            if (passwordMatchFeedback && passwordMatchFeedback.type == "success") {
                userDto.password = nowPassword;
                dispatch(postUserPwdCheckAsync.request(userDto));
                setProcess("CHK_PW");
            }
        }
    }

    useEffect(() => {
        console.log('chkResult 리턴 결과:', JSON.stringify(chkResult, null, 2));
        
        if (process == "CHK_PW" && chkResult && chkResult.data && chkResult.data.status == "success") {
            userDto.password = rePassword;
                
            dispatch(putUserInfoAsync.request(userDto));
            setProcess("UPD_PW");
        } else if (process == "CHK_PW" && chkResult && chkResult.data && chkResult.data.status == "error"){
            alert("기존 비밀번호가 동일하지 않습니다.");
            setProcess("");
        }

    },[chkResult]);

    useEffect(() => {
        console.log('userUpdResult 리턴 결과:', JSON.stringify(userUpdResult, null, 2));
       
        if (process == "UPD_PW" && userUpdResult && userUpdResult.data) {
            
            if (userUpdResult.data.status == "error") {
                alert(userUpdResult.data.error.message.message);
                setProcess("");
            } else if (userUpdResult.data.status == "success") {
                alert("수정되었습니다.");
                closePasswd();
            }
        }

    },[userUpdResult]);

    const closePasswd = () => {
        setProcess("");
        setNowPassword("");
        setPassword("");
        setRePassword("");

        return closeDialog();
    }

	
    return (
        <Dialog id="NewModal"  isOpen={openYn} title="비밀번호 변경" onClose={ closeDialog }>
            <div className="form-group">
                <div className="">
                    <label className="label" htmlFor="userName">기존 비밀번호</label>
                    <div className="">
                        <div className="form-email">
                            <input type="password"  id="password" name="password" value={nowPassword} className="form-control block" title="비밀번호 입력" placeholder="비밀번호를 입력하세요" autoComplete="new-password" onChange={(e) => setNowPassword(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <label className='label' htmlFor="userPassword">변경 비밀번호</label>
                <div className="label-desc">대소문자 구분하며 ‘.’과 ‘,’는 특수문자로 인식하지 않습니다</div>
                <div className='regi-input'>
                    <input
                        type="password"
                        className="form-control block"
                        id="userPassword"
                        placeholder="영문, 숫자, 특수문자 조합 10~16자리"
                        name="userPassword"
                        value={password}
                        onChange={handleFieldChange}
                        required
                    />
                    {passwordFeedback && (
                        <Feedback type={passwordFeedback.type} message={passwordFeedback.message} />
                    )}
                </div>
            </div>
            <div className="form-group">
                <label className='label' htmlFor="userRePassword">변경 비밀번호 확인</label>
                <div className='regi-input'>
                    <input
                        type="password"
                        className="form-control block"
                        id="userRePassword"
                        placeholder="영문, 숫자, 특수문자 조합 10~16자리"
                        name="userRePassword"
                        value={rePassword}
                        onChange={handleFieldChange}
                        required
                    />
                    {passwordMatchFeedback && rePassword && (
                        <Feedback type={passwordMatchFeedback.type} message={passwordMatchFeedback.message} />
                    )}
                </div>
            </div>

            <div className="button-group right" style={{paddingTop:"50px"}}>
                <button onClick={closePasswd} type="button" className="button button--sm button--outline-black">취소하기</button>
                <button onClick={setPasswd} type="button" className="button button--sm button--outline-black">확인</button>
            </div>
            
            
        </Dialog>
    )
}

export default MypageUserPassWordModal;
