import { getMyPageUserListAsync , getMyPageUserInviteAsync , postMyPageUserInviteAsync, deleteMyPageUserAsync, getUserInfoAsync, putUserInfoAsync, postUserPwdCheckAsync , getMyPageContactUsListAsync
       , getNeospectraProjectCntAsync , getNeospectraProjectStatsAsync , getNeospectraJobsStatsAsync , getNeospectraUsagesCurrentAsync , getNeospectraUsagesServiesLeftAsync
       , putUserRoleAsync , putUserGroupRoleAsync , getInfoseedMypageAsync , resetMyPageDataAsync,
       RESET_MYPAGE
} from './actions';
import { GET_MYPAGE_USER_LIST , GET_MYPAGE_USER_INVITE , POST_MYPAGE_USER_INVITE , DELETE_MYPAGE_USER , GET_MYPAGE_USER_INFO,PUT_MYPAGE_USER_INFO,POST_MYPAGE_PWD_CHK , GET_MYPAGE_CONTACT_US_INFO
       , GET_MYPAGE_NEO_PROJECT_CNT , GET_MYPAGE_NEO_PROJECT_STATS , GET_MYPAGE_NEO_JOBS_STATS , GET_MYPAGE_NEO_USAGES_CURRENT , GET_MYPAGE_NEO_USAGES_SERVIES_LEFT
       , PUT_USER_ROLE , PUT_USER_GROUP_ROLE , GET_INFO_DASH
} from './actions';
import { getMyPageUserList , getMyPageUserInvite , postMyPageUserInvite, deleteMyPageUser,getUserInfo,putUserInfo,postUserPwdCheck , getMyPageContactUsList
       , getNeospectraProjectCnt , getNeospectraProjectStats , getNeospectraJobsStats , getNeospectraUsagesCurrent , getNeospectraUsagesServiesLeft , getInfoseedMypage
       , updateUserRole , updateGroupUserRole
} from '../../api/myPage';

import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';

const getMyPageUserListSaga      = createAsyncSaga(getMyPageUserListAsync, getMyPageUserList);
const postMyPageUserInviteSaga   = createAsyncSaga(postMyPageUserInviteAsync, postMyPageUserInvite);
const getMyPageUserInviteSaga    = createAsyncSaga(getMyPageUserInviteAsync, getMyPageUserInvite);
const deleteMyPageUserSaga       = createAsyncSaga(deleteMyPageUserAsync, deleteMyPageUser);

const getUserInfoSaga            = createAsyncSaga(getUserInfoAsync, getUserInfo);
const putUserInfoSaga            = createAsyncSaga(putUserInfoAsync, putUserInfo);
const postUserPwdCheckSaga       = createAsyncSaga(postUserPwdCheckAsync, postUserPwdCheck);

const getMyPageContactUsListSaga = createAsyncSaga(getMyPageContactUsListAsync, getMyPageContactUsList);

const getNeospectraProjectCntSaga    = createAsyncSaga(getNeospectraProjectCntAsync, getNeospectraProjectCnt);
const getNeospectraProjectStatsSaga  = createAsyncSaga(getNeospectraProjectStatsAsync, getNeospectraProjectStats);
const getNeospectraJobsStatsSaga     = createAsyncSaga(getNeospectraJobsStatsAsync, getNeospectraJobsStats);
const getNeospectraUsagesCurrentSaga = createAsyncSaga(getNeospectraUsagesCurrentAsync, getNeospectraUsagesCurrent);
const getNeospectraUsagesServiesLeftSaga = createAsyncSaga(getNeospectraUsagesServiesLeftAsync, getNeospectraUsagesServiesLeft);

const putUserRoleSaga = createAsyncSaga(putUserRoleAsync, updateUserRole);
const putUserGroupRoleSaga = createAsyncSaga(putUserGroupRoleAsync, updateGroupUserRole);

const getInfoseedMypageSaga = createAsyncSaga(getInfoseedMypageAsync, getInfoseedMypage);


function* resetMyPageSaga(action: ReturnType<typeof resetMyPageDataAsync.request>) {
       resetMyPageDataAsync.success(null);
}

export function* myPageSaga() {
  yield takeEvery(RESET_MYPAGE  , resetMyPageSaga);

  yield takeEvery(GET_MYPAGE_USER_LIST, getMyPageUserListSaga);

  yield takeEvery(GET_MYPAGE_USER_INVITE  , getMyPageUserInviteSaga);
  yield takeEvery(POST_MYPAGE_USER_INVITE , postMyPageUserInviteSaga);
  yield takeEvery(DELETE_MYPAGE_USER      , deleteMyPageUserSaga);

  yield takeEvery(GET_MYPAGE_USER_INFO , getUserInfoSaga);
  yield takeEvery(PUT_MYPAGE_USER_INFO , putUserInfoSaga);
  yield takeEvery(POST_MYPAGE_PWD_CHK  , postUserPwdCheckSaga);

  yield takeEvery(GET_MYPAGE_CONTACT_US_INFO, getMyPageContactUsListSaga);

  yield takeEvery(GET_MYPAGE_NEO_PROJECT_CNT         , getNeospectraProjectCntSaga);
  yield takeEvery(GET_MYPAGE_NEO_PROJECT_STATS       , getNeospectraProjectStatsSaga);
  yield takeEvery(GET_MYPAGE_NEO_JOBS_STATS          , getNeospectraJobsStatsSaga);
  yield takeEvery(GET_MYPAGE_NEO_USAGES_CURRENT      , getNeospectraUsagesCurrentSaga);
  yield takeEvery(GET_MYPAGE_NEO_USAGES_SERVIES_LEFT , getNeospectraUsagesServiesLeftSaga);

  yield takeEvery(PUT_USER_ROLE       , putUserRoleSaga);
  yield takeEvery(PUT_USER_GROUP_ROLE , putUserGroupRoleSaga);

  yield takeEvery(GET_INFO_DASH , getInfoseedMypageSaga);
  

   

}
