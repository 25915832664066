import CryptoJS from 'crypto-js';
import config from '../config';

export interface EncryptedData {
    userPermissions: string;
    ognzGrpSn: string;
    ognzId: string,
    ognzNm: string;
    inviter: string;
}

// 암호화 및 복호화를 담당하는 클래스
export class EncryptionService {
    private secretKey: string;

    constructor() {
        this.secretKey = config.secreatKey || 'default-secret-key'; // 기본 비밀 키 설정
    }


    // Encrypts the data
    encrypt(data: EncryptedData): string {
        // Convert the object to JSON string and encrypt
        return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
    }

    // Decrypts the data
    decrypt(encryptedData: string): EncryptedData | null {
        try {
            const decodedData = decodeURIComponent(encryptedData);  // Decode URL-encoded string
            const bytes = CryptoJS.AES.decrypt(decodedData, this.secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

            return decryptedData ? JSON.parse(decryptedData) : null;
        } catch (error) {
            console.error("Decryption failed:", error);
            return null;
        }
    }    
}

/*
// 사용 예시
const encryptionService = new EncryptionService();

const dataToEncrypt = {
    userPermissions: 'user-role',
    agencyCode: 'agency-123'
};

// 암호화
const encryptedData = encryptionService.encrypt(dataToEncrypt);
console.log('암호화된 데이터:', encryptedData);

// 복호화
const decryptedData = encryptionService.decrypt(encryptedData);
console.log('복호화된 데이터:', decryptedData);
*/