import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import '../../styles/components/table.scss';
import '../../styles/components/input.scss';
import Banners  from "../../containers/banner";
import MypageLayout from "./MypageLayout";
import { UserDto } from '../../types/UserDto';
import Feedback from "../../components/Feedbacks";
import { getUserInfoAsync , putUserInfoAsync } from '../../modules/myPage';
import MypageUserPassWordModal from "./MypageUserPassWordModal";

interface WriteProps {
    title : string;
    pageTitle:string
}

const MypageUserUpdate:FC<WriteProps> = ({ title , pageTitle }) => {
    const dispatch = useDispatch();
    const [userDto, setUserDto] = useState<UserDto>({
         userId: ""
       , userName: ""
       , email: ""
       , password: ""
       , userRoleCd: ""
       , ognzId: ""
       , ognzName : ""
    });
    const [passwordFeedback, setPasswordFeedback] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const [passwordMatchFeedback, setPasswordMatchFeedback] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const userInfo  = useSelector((state:RootState) => state.myPage.data);
    const userUpdResult  = useSelector((state:RootState) => state.myPage.result);
    const [pwdDialog,  setPwdDialogOpen] = useState(false);
    const [process,setProcess]= useState("");

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setUserDto((prev) => ({ ...prev,[name] : value}));
    };

    const setUserRoleCd = (value:string) => {
        setUserDto((prev) => ({ ...prev, userRoleCd : value}));
    }

    useEffect(() => {
		const userId = sessionStorage.getItem('userId');

        if (userId) {
            dispatch(getUserInfoAsync.request(null));
        }
    },[]);

    useEffect(() => {

        console.log('리턴 결과:', JSON.stringify(userInfo, null, 2));
        if (userInfo && userInfo.data) {
            setUserDto(userInfo.data.user);
        }
    },[userInfo]);

    const putUser = () => {
        const fetchData = async () => {
            
            userDto.password = "";
            await dispatch(putUserInfoAsync.request(userDto));
            setProcess("UPD");
        }
        
        fetchData();
    }

    useEffect(() => {

        console.log('userUpdResult 리턴 결과:', JSON.stringify(userUpdResult, null, 2));
        if (process == "UPD" && userUpdResult && userUpdResult.data) {
            if (userUpdResult.data.status == "error") {
                alert(userUpdResult.data.error.message.message);
                setProcess("");
            } else if (userUpdResult.data.status == "success") {
                alert("수정되었습니다.");
                setProcess("");
            }
        }
    },[userUpdResult]);

    const openDialog = () => {
		setPwdDialogOpen(true);
    };  

	const closeDialog = () => {
		setPwdDialogOpen(false);
    };
    

    return (
        <MypageLayout bannerTitle={title} pageTitle={pageTitle}>
        <div className="board-write">
            {userDto ? (
            <div className="row">
                <div className="container">
                <section className="register-form-section">
                            <header>
                                <h3>기본 정보 입력</h3>
                                <p className="required"><i className='icon--check'></i>전체 항목 필수 입력 사항입니다.</p>
                            </header>
                            <div className="form-group">
                                <div className='regi-input'>
                                    <label className='label' htmlFor="userName">이름</label>
                                    <input type="text" className="form-control block" id="userName" placeholder="이름을 입력해 주세요." name="userName" value={userDto.userName} disabled style={{backgroundColor:"#e7e4e4"}} />
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label className='label' htmlFor="userId">아이디</label>
                                <div className='regi-input'>
                                    <input type="text" className="form-control block" id="userId" placeholder="아이디을 입력해 주세요." name="userId" value={userDto.userId} disabled style={{backgroundColor:"#e7e4e4"}} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className='label' htmlFor="userPassword">비밀번호</label>
                                <div className="label-desc">대소문자 구분하며 ‘.’과 ‘,’는 특수문자로 인식하지 않습니다</div>
                                <div className='regi-input'>
                                    <input
                                        type="password"
                                        className="form-control block"
                                        id="userPassword"
                                        placeholder="영문, 숫자, 특수문자 조합 10~16자리"
                                        name="password"
                                        value={userDto.password}
                                        required
                                        disabled style={{backgroundColor:"#e7e4e4",width:"95%"}} 
                                    />
                                    {passwordFeedback && (
                                        <Feedback type={passwordFeedback.type} message={passwordFeedback.message} />
                                    )}
                                    <button type='button' className='button button--sm button--outline-black' onClick={openDialog}>비밀번호 변경</button>
                                </div>
                                
                            </div>
                            
                            <div className="form-group">
                                <label className='label' htmlFor="userEmail">이메일</label>
                                <div className='regi-input'>
                                    <div className='form-email'>
                                        <input type="text" className="form-control block" id="userEmail" name="email" value={userDto.email} onChange={handleFieldChange} required />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className='label' htmlFor="userOrgan">기관</label>
                                <div className='regi-input'>
                                    <input type="text" className="form-control block" id="userOrgan" value={userDto.ognzName} disabled style={{backgroundColor:"#e7e4e4"}} />
                                </div>
                            </div>
                        </section>

                    <div className='board-foot-buttons' style={{justifyContent:"center"}}>
                        <button type='button' className='button button--sm button--primary' onClick={putUser}>수정하기</button>
                    </div>
                    
                </div>
            </div>) : (null) }
            <MypageUserPassWordModal openYn={pwdDialog} closeDialog={closeDialog} userDto={userDto} />
        </div>
        </MypageLayout>
    )
}

export default MypageUserUpdate;