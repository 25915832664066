import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../modules";
import { getBuyScrtnListAsync } from "../modules/product";
import PagingButton from "../components/pagingButton";
import { getFormatCurrency } from "../pages/common/stringUtil";

interface BuyListProps {
    sltnId: string;
}
// MAPPRIME (디지털틔원 ) , SIMMETA (AI 데이터생성관리 ) , GEO_NIC (영상촬영) 

const BuyList:FC<BuyListProps> = ({ sltnId }) => {
    const dispatch = useDispatch();

    // 결과 리턴값 설정
    const buyList = useSelector((state:RootState) => state.product.data);
    
    // 변수 설정


    // 상품 그룹번호 변경시 상품정보 조회
    useEffect(() => {
        onSearch(1);

    },[sltnId]);

    const onSearch = (currentPageNo:number) => {
        async function fetchBuyList() {
            const param = {
                sltnId : sltnId
              , currentPageNo : currentPageNo
              , pageUnit : 3
            };
    
            await dispatch(getBuyScrtnListAsync.request(param)); 
        };

        fetchBuyList();

    }

    // 상품 그룹번호 변경시 상품정보 조회
    var buyListHtml = [];

    console.log('buyList 리턴 결과:', JSON.stringify(buyList, null, 2));

    if (buyList && buyList.data ) {
        if (buyList.data.status != "error") {
            for (var i=0;i<buyList.data.data.length;i++) {
                var buyInfo = buyList.data.data[i];
                buyListHtml.push(
                    <tr>
                        <td >{buyInfo.stlmNm}</td>
                        <td style={{textAlign:"right"}}>{getFormatCurrency(buyInfo.stlmAmt)}원</td>
                        <td style={{textAlign:"center"}}>{buyInfo.stlmCmptnDt}</td>
                        <td style={{textAlign:"center"}}>{buyInfo.startDate} ~ {buyInfo.endDate}</td>
                        <td >{buyInfo.stlmMthdNm}</td>
                    </tr>
                );                
            }
        }
    }

    return (
        buyList && buyList.data && buyList.data.data && buyList.data.data.length > 0 ? ( 
        <div>
            <div className="product-ti">
                <h3>결제이력</h3>
                <button type="button" className="button button--outline-black button--sm" style={{display:"none"}}>결제 수단 변경</button>
            </div>
            <div className="table mb30" style={{display: "block",maxHeight: "300px" ,overflowY : "auto" }}>
                <table >
                    <thead >
                        <tr>
                            <th >상품명</th>
                            <th >결제 금액</th>
                            <th >결제일</th>
                            <th >이용기간</th>
                            <th >결제 수단</th>
                        </tr>
                    </thead>
                    <tbody>
                        { buyListHtml }
                    </tbody>
                </table>
            </div>
            
            { buyList.data.meta  ? ( 
                    <PagingButton pageingDto={buyList.data.meta} onClick={onSearch} />        
            ) : null }
        </div>
        ) : null
    );
};

export default BuyList;
