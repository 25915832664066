import { createReducer } from 'typesafe-actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { MyPageState } from './types';
import { getMyPageUserListAsync , getMyPageUserInviteAsync , postMyPageUserInviteAsync , deleteMyPageUserAsync , getUserInfoAsync , putUserInfoAsync 
       , postUserPwdCheckAsync , getMyPageContactUsListAsync
       , getNeospectraProjectCntAsync
       , getNeospectraProjectStatsAsync
       , getNeospectraJobsStatsAsync
       , getNeospectraUsagesCurrentAsync
       , getNeospectraUsagesServiesLeftAsync
       , putUserRoleAsync
       , putUserGroupRoleAsync
       , getInfoseedMypageAsync
       , resetMyPageDataAsync
} from './actions';

import { AnyAction } from 'redux';

const initialState: MyPageState = {
  data: asyncState.initial(),
  inviteList: asyncState.initial(),
  result: asyncState.initial(),
  delResult: asyncState.initial(),
  chkResult: asyncState.initial(),

  neoProjCnt: asyncState.initial(),
  neoProjStat: asyncState.initial(),
  neoJobsStat: asyncState.initial(),
  neoUsagesCurrent: asyncState.initial(),
  neoUsagesServies: asyncState.initial(),

  updateUserRole : asyncState.initial(),
  updateUserGroupRole : asyncState.initial(),

  infoseedMypage : asyncState.initial(),
};

const myPageReducer = createReducer<MyPageState, AnyAction>(initialState)
.handleAction(resetMyPageDataAsync.request, () => initialState)
.handleAction(resetMyPageDataAsync.success, () => initialState)

.handleAction(
  transformToArray(getMyPageUserListAsync),
  createAsyncReducer(getMyPageUserListAsync, 'data')
)
.handleAction(
  transformToArray(getMyPageUserInviteAsync),
  createAsyncReducer(getMyPageUserInviteAsync, 'inviteList')
)
.handleAction(
  transformToArray(postMyPageUserInviteAsync),
  createAsyncReducer(postMyPageUserInviteAsync, 'result')
)
.handleAction(
  transformToArray(deleteMyPageUserAsync),
  createAsyncReducer(deleteMyPageUserAsync, 'delResult')
)
.handleAction(
  transformToArray(getUserInfoAsync),
  createAsyncReducer(getUserInfoAsync, 'data')
)
.handleAction(
  transformToArray(putUserInfoAsync),
  createAsyncReducer(putUserInfoAsync, 'result')
)
.handleAction(
  transformToArray(postUserPwdCheckAsync),
  createAsyncReducer(postUserPwdCheckAsync, 'chkResult')
)
.handleAction(
  transformToArray(getMyPageContactUsListAsync),
  createAsyncReducer(getMyPageContactUsListAsync, 'data')
)
.handleAction(
  transformToArray(getNeospectraProjectCntAsync),
  createAsyncReducer(getNeospectraProjectCntAsync, 'neoProjCnt')
)
.handleAction(
  transformToArray(getNeospectraProjectStatsAsync),
  createAsyncReducer(getNeospectraProjectStatsAsync, 'neoProjStat')
)
.handleAction(
  transformToArray(getNeospectraJobsStatsAsync),
  createAsyncReducer(getNeospectraJobsStatsAsync, 'neoJobsStat')
)
.handleAction(
  transformToArray(getNeospectraUsagesCurrentAsync),
  createAsyncReducer(getNeospectraUsagesCurrentAsync, 'neoUsagesCurrent')
)
.handleAction(
  transformToArray(getNeospectraUsagesServiesLeftAsync),
  createAsyncReducer(getNeospectraUsagesServiesLeftAsync, 'neoUsagesServies')
)
.handleAction(
  transformToArray(putUserRoleAsync),
  createAsyncReducer(putUserRoleAsync, 'updateUserRole')
)
.handleAction(
  transformToArray(putUserGroupRoleAsync),
  createAsyncReducer(putUserGroupRoleAsync, 'updateUserGroupRole')
)
.handleAction(
  transformToArray(getInfoseedMypageAsync),
  createAsyncReducer(getInfoseedMypageAsync, 'infoseedMypage')
)



;

export default myPageReducer;