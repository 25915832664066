import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";

type props = {
    neoProjCnt: any[];
};
                     
const ProjectManage8:FC<props> = ({ neoProjCnt }) => {
// buttonComponent={<Link to="/details" className="icon--more"></Link>}


    return (
        <ProjectSections title="참여중인 프로젝트" className="col-9 col-lg-8 col-md-12 user-project" >
            <div className="user-project-list type-03">
                {neoProjCnt && neoProjCnt?.length > 0 ? (neoProjCnt.map((project:any) => (
                    <div className="project-item">
                        <div className="project-item-title">{project.category_name}</div>
                        <div className="project-item-count">{project.count}</div>
                    </div>
                ))) : (
                    <div style={{display : "contents"}}>
                        <div className="project-item">
                            <div className="project-item-title">객체 탐지</div>
                            <div className="project-item-count">0</div>
                        </div>
                        <div className="project-item">
                            <div className="project-item-title">객체 삭제</div>
                            <div className="project-item-count">0</div>
                        </div>
                    </div>
                ) }
            </div>
        </ProjectSections>
   
    )
}
export default ProjectManage8;
