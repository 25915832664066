import axios from 'axios';

export const getFormatDate = (date: Date, format: string) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return format
        .replace('YYYY', String(year))
        .replace('MM', month)
        .replace('DD', day)
        .replace('HH', hours)
        .replace('mm', minutes)
        .replace('SS', seconds);
}

export const getAddDays = (date: Date, days: number,format : string) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);

    const returnStr = getFormatDate(result,format);

    return returnStr;
}

export const getCurrentDate = (format:string): string => {
    const date = new Date();
    const returnStr = getFormatDate(date,format);

    return returnStr;
};

export const getDaysDifference = (startDate: Date, endDate: Date) => {
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24)); // 밀리초를 일로 변환
}