import {
    ChangeEvent,
    Dispatch,
    FC,
    SetStateAction,
    useState,
    useEffect,
    FormEvent,
} from 'react';
import { Link } from 'react-router-dom';
import Banners from '../../containers/banner';
import Title from '../../components/Titles';
import Buttons from '../../components/Buttons';
import '../../styles/components/input.scss';
import '../../styles/components/icon.scss';
import './login.scss';
import { useNavigate } from 'react-router-dom';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { loginAsync } from '../../modules/authuser';

interface PageProps {
    title: string;
    setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
const Login: FC<PageProps> = ({ title, setIsLoggedIn }) => {
    const [userId, setUserId] = useState('');
    const [userPw, setUserPw] = useState('');
    const [rememberUserId, setRememberUserId] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authuser = useSelector((state: RootState) => state.auth);



    // 사용자가 저장한 아이디를 로드
    useEffect(() => {
        const savedUserId = localStorage.getItem('rememberedUserId');
        if (savedUserId) {
            setUserId(savedUserId);
            setRememberUserId(true);
        }
    }, []);

    // 로그인 성공 시 후속 작업 처리
    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (token) {
            setIsLoggedIn(true); // 로그인 상태 업데이트
            navigate('/'); // 로그인 성공 시 홈 페이지로 리다이렉트
        }
    }, [sessionStorage.getItem('access_token'), setIsLoggedIn, navigate]);

    // 에러가 발생했을 때 처리
    useEffect(() => {
        if (authuser.data.error) {
            alert('로그인에 실패했습니다. 다시 시도해 주세요.');
        }
    }, [authuser.data.error]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'userId') {
            setUserId(event.target.value);
        } else if (event.target.name === 'userPw') {
            setUserPw(event.target.value);
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!userId || !userPw) {
            alert('아이디와 비밀번호를 입력하세요.');
            return;
        }

        console.log('Form submitted, calling login');
        const response = dispatch(loginAsync.request({ username: userId, password: userPw }));

        if (sessionStorage.getItem('access_token')) {
            setIsLoggedIn(true);
            navigate('/');
        }

        if (rememberUserId) {
            localStorage.setItem('rememberedUserId', userId);
        } else {
            localStorage.removeItem('rememberedUserId');
        }
    };


    return (
        <>
            <Banners title={title} />
            <div className="login">
                <div className="row">
                    <div className="container">
                        <Title
                            title="영상정보 관리 및 분석 서비스 로그인"
                            subtitle="영상정보 관리 및 분석 서비스에 오신 것을 환영합니다"
                        />
                        <div className="small-wraps">
                            <form className="login-form" onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend className="sr-only">로그인</legend>
                                    <div className="form-group">
                                        <label htmlFor="userName" className="label">
                                            아이디
                                        </label>
                                        <input
                                            type="text"
                                            id="userName"
                                            name="userId"
                                            className="form-control block"
                                            value={userId}
                                            onChange={handleChange}
                                            placeholder="아이디를 입력해 주세요."
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userPassword" className="label">
                                            비밀번호
                                        </label>
                                        <input
                                            type="password"
                                            id="userPassword"
                                            name="userPw"
                                            className="form-control block"
                                            value={userPw}
                                            onChange={handleChange}
                                            placeholder="영문, 숫자, 특수문자 조합 10~16자리"
                                            required
                                        />
                                    </div>
                                    <div className="authid-save">
                                        <input
                                            type="checkbox"
                                            id="save"
                                            checked={rememberUserId}
                                            onChange={(e) => setRememberUserId(e.target.checked)}
                                        />
                                        <label htmlFor="save">아이디 저장</label>
                                    </div>
                                    <Buttons
                                        text="로그인"
                                        variant="primary"
                                        size="lg"
                                        block
                                        type="submit"
                                        disabled={authuser.data.loading}
                                    />
                                    <div className="login-util">
                                        <Link to={'/forgotid'} className="forgot-link">
                                            아이디 찾기
                                            <i className="icon--arwright-black"></i>
                                        </Link>
                                        <Link to={'/forgotpassword'} className="forgot-link">
                                            비밀번호 찾기
                                            <i className="icon--arwright-black"></i>
                                        </Link>
                                        <Link to={'/register'} className="forgot-link">
                                            회원가입 <i className="icon--arwright-black"></i>
                                        </Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Login;
