import { FC } from 'react';
import '../styles/components/titles.scss';

interface TitleProps {
    title : string,
    subtitle : string
}

const Title:FC<TitleProps> = ({title,subtitle}) => {
    return (
        <div className="page-titles">
            <h3 className="page-title" dangerouslySetInnerHTML={{__html : title }} />
            <p className="page-desc">{subtitle}</p>
        </div>
    )
}

export default Title;