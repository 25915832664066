import { FC } from 'react';
import { Link } from "react-router-dom";
import Banners  from "../../containers/banner";
import './policy.scss';

interface PageProps {
    title: string;
}

const Privacy:FC<PageProps> = ({title}) => {
    return (
        <div className="">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <div className="cont-agg-all">
                        <section className="cont-agg-all_boxtext">
                            공공SaaS가 취급하는 모든 개인정보는 개인정보 보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 수집·보유·처리하고 있습니다. 공공SaaS는 개인정보 보호법 제30조에 따라 이용자의 개인
                            정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 두고 있습니다.
                        </section>

                        <section className="cont-agg-all_list">
                            <div className="cont-agg-all_list_header">
                                <h3>목차</h3>
                                <div>( ※ 각 항목을 클릭하면 해당항목으로 이동합니다. )</div>
                            </div>
                            <div className="cont-agg-all_list_nav">
                                <ul>
                                    <li><a href="#제1조"><strong>제1조</strong> 개인정보의 처리 목적</a></li>
                                    <li><a href="#제2조"><strong>제2조</strong> 개인정보의 처리 및 보유기간</a></li>
                                    <li><a href="#제3조"><strong>제3조</strong> 개인정보의 수집 이용저장</a></li>
                                    <li><a href="#제4조"><strong>제4조</strong> 개인정보의 제3자 제공</a></li>
                                    <li><a href="#제5조"><strong>제5조</strong> 개인정보처리의 위탁</a></li>
                                    <li><a href="#제6조"><strong>제6조</strong> 정보주체와 법정대리인이 권리·의무 및 행사방법</a></li>
                                    <li><a href="#제7조"><strong>제7조</strong> 처리하는 개인정보 항목</a></li>
                                </ul>
                                <ul>
                                    <li><a href="#제8조"><strong>제8조</strong> 개인정보의 파기</a> </li>
                                    <li><a href="#제9조"><strong>제9조</strong> 개인정보의 안전성 확보조치</a></li>
                                    <li><a href="#제10조"><strong>제10조</strong> 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 상황</a></li>
                                    <li><a href="#제11조"><strong>제11조</strong> 개인정보 보호책임자</a></li>
                                    <li><a href="#제12조"><strong>제12조</strong> 개인정보 열람청구</a></li>
                                    <li><a href="#제13조"><strong>제13조</strong> 권익침해 구제방법</a></li>
                                    <li><a href="#제14조"><strong>제14조</strong> 개인정보 처리방침 변경</a></li>
                                </ul>
                            </div>
                        </section>

                        <section id="제1조">
                            <h3>제1조 개인정보의 처리 목적</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                    공공SaaS는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고있는 개인정보는 다음의 목적 이외 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조<br />
                                    (개인정보의 목적 외 이용·제공제한)에 따라 별도의 동의를 받는 등 필요한 조치를 이행하고 있습니다.
                                    <ul className="agg-item_list2">
                                        <li data-no="①">
                                            <strong>홈페이지 회원 가입 및 관리</strong><br />
                                            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별‧인증, 회원자격 유지‧관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보처리 시 법정 대리인의 동의 여부 확인, 사외보 발송 등을 목적으로 개인정보를 처리합니다.
                                        </li>
                                        <li data-no="②">
                                            <strong>민원사무 처리</strong><br />
                                            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락‧통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.
                                        </li>
                                    </ul>
                                </li>
                                <li data-no="2">공공SaaS가 개인정보 보호법 제32조(개인정보파일의 등록 및 공개)에 따라 등록·공개하는 개인정보 파일의 처리 목적은 다음과 같습니다.</li>
                            </ul>

                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">순번</th>
                                            <th scope="col">개인정보 파일 명칭</th>
                                            <th scope="col">운영근거/처리목적</th>
                                            <th scope="col">파일에 기록되는 항목</th>
                                            <th scope="col">보유기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tac">1</td>
                                            <td className="tac">지적측량 바로처리 온라인 회원</td>
                                            <td>
                                                <ul className="cs-list">
                                                    <li>공간정보의 구축 및 관리 등에 관한 법률 24조 및시행령 104조의 2 </li>
                                                    <li>회원가입자 정보관리</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="cs-list">
                                                    <li><strong>필수 :</strong> 아이디, 비밀번호, 이름</li>
                                                    <li><strong>선택 :</strong> 주민등록번호, 주소, 휴대전화번호, 토지소재지, 이메일</li>
                                                </ul>
                                            </td>
                                            <td className="tac">회원탈퇴 시, 2년</td>
                                        </tr>
                                        <tr>
                                            <td className="tac">2</td>
                                            <td className="tac">지적측량 의뢰고객</td>
                                            <td>
                                                <ul className="cs-list">
                                                    <li>공간정보의 구축 및 관리 등에 관한 법률 24조 및 시행령 104조의 2</li>
                                                    <li>지적측량 의뢰고객 관리 및 변경</li>
                                                    <li>부가가치세법 제32조</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="cs-list">
                                                    <li>
                                                        <strong>필수 :</strong> 성명, 주소, 토지 소재지, 휴대전화번호,<br />
                                                        주민(외국인)등록번호, 이메일
                                                    </li>
                                                    <li>
                                                        <strong>선택 :</strong> 주민등록번호, 주소, 휴대전화번호<br />
                                                        토지소재지, 이메일
                                                    </li>
                                                </ul>
                                            </td>
                                            <td className="tac">5년</td>
                                        </tr>
                                        <tr>
                                            <td className="tac">3</td>
                                            <td className="tac">지적측량 민원신청</td>
                                            <td>
                                                <ul className="cs-list">
                                                    <li>민원처리에 관한 법률 제2조2항 및 시행령 제7조 2항</li>
                                                    <li>민원신청 고객 정보관리</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="cs-list">
                                                    <li><strong>필수 :</strong> 성명, 주소</li>
                                                    <li><strong>선택 :</strong> 토지소재지, 전화번호, 휴대전화번호, 이메일</li>
                                                </ul>
                                            </td>
                                            <td className="tac">10년</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="fz14 mt5">* 상세 내역은 개인정보보호 포털(<a href="http://www.privacy.go.kr/" target="manager-window" title="새창 열림" className="fc-blue">http://www.privacy.go.kr/</a>) &gt; 개인서비스 &gt; 개인정보 열람 등 요구 &gt; 개인정보파일 검색 &gt; 기관명에 "공공SaaS" 입력 후 검색 메뉴를 이용하여 주시기 바랍니다.</div>

                            <ul className="agg-item_list1">
                                <li data-no="3">인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.(IP 주소, 서비스 이용기록, 방문기록, 불량 이용기록 등)</li>
                            </ul>

                        </section>

                        <section id="제2조">
                            <h3>제2조 개인정보의 처리 및 보유기간</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">공공SaaS는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</li>
                                <li data-no="2">
                                    각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                                    <ul className="agg-item_list2">
                                        <li data-no="①">
                                            <strong>지적측량 바로처리 온라인 회원 </strong><br />
                                            회원 탈퇴 시까지(2년 경과 시 재동의)
                                        </li>
                                        <li data-no="②">
                                            <strong>지적측량 의뢰 고객</strong><br />
                                            지적측량업무 종료 후 5년간 보유
                                        </li>
                                        <li data-no="③">
                                            <strong>민원사무 처리 </strong><br />
                                            민원사무 처리 후 10년간 보유
                                        </li>
                                        <li data-no="④">
                                            <strong>사외보 신청</strong><br />
                                            구독 해지 시까지(2년 경과 시 재동의)
                                        </li>
                                        <li data-no="⑤">
                                            <strong>공간정보아카데미 회원</strong><br />
                                            회원 탈퇴 시까지(2년 경과 시 재동의)
                                        </li>
                                    </ul>
                                </li>
                                <li data-no="3">인터넷 서비스 이용 과정에서 아래 항목이 자동으로 생성되어 최소 6개월 이상 보유·관리 됩니다.(IP 주소, 서비스 이용 기록, 방문 기록, 불량 이용 기록 등)</li>
                            </ul>
                        </section>

                        <section id="제3조">
                            <h3>제3조 개인정보의 수집·이용·저장</h3>
                            <div className="agg-item_text">공공SaaS는 개인정보의 수집 시 개인정보 보호법 제15조 제2항(법령상의무를 준수하기 위하여 불가피한 경우)를 제외 하고는 정보 주체의 동의에 의하여 수집하고 저장은 정부에서 권장하는 암호화를 준수하여 보관하며 개인정보의 동의 목적 내에서 만 이용합니다.</div>
                        </section>

                        <section id="제4조">
                            <h3>제4조 개인정보의 제3자 제공</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">공공SaaS는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</li>
                                <li data-no="2">
                                    공공SaaS는 아래의 경우를 제외하고는 정보주체의 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.
                                    <ul className="agg-item_list2">
                                        <li data-no="①">정보주체로부터 별도의 동의를 받는 경우</li>
                                        <li data-no="②">법률에 특별한 규정이 있는 경우</li>
                                        <li data-no="③">정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</li>
                                        <li data-no="④">개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 개인정보보호 위원회의 심의·의결을 거친 경우</li>
                                        <li data-no="⑤">조약, 그 밖의 국제협정의 이행을 위하여 외국정보 또는 국제기구에 제공하기 위하여 필요한 경우</li>
                                        <li data-no="⑥">범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</li>
                                        <li data-no="⑦">법원의 재판업무 수행을 위하여 필요한 경우</li>
                                        <li data-no="⑧">형 및 감호, 보호처분의 집행을 위하여 필요한 경우</li>
                                    </ul>
                                </li>
                                <li data-no="3">
                                    공공SaaS는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                                </li>
                            </ul>
                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col className="" />
                                        <col className="" />
                                        <col className="" />
                                        <col className="" />
                                        <col />
                                        <col />
                                        <col className="" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">운영시스템</th>
                                            <th scope="col">제공받는 기관</th>
                                            <th scope="col">제공 대상</th>
                                            <th scope="col">이용 목적</th>
                                            <th scope="col">관계 법령</th>
                                            <th scope="col">제공하는 항목</th>
                                            <th scope="col">제공받는자의 이용기간</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="tac">지적측량 업무지원</td>
                                            <td className="tac">기획재정부</td>
                                            <td className="tac">지적측량 의뢰고객</td>
                                            <td className="tac">고객만족도 조사</td>
                                            <td className="tac">공공기관의 운영에 관한 법률 제13조 2항</td>
                                            <td className="tac">성명, 주소, 휴대전화번호</td>
                                            <td className="tac">목적달성 후 파기</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>

                        <section id="제5조">
                            <h3>제5조 개인정보처리의 위탁</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">공공SaaS는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적‧관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리‧감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</li>
                                <li data-no="2">공공SaaS는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</li>
                            </ul>

                            <div className="table tac">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">시스템</th>
                                            <th scope="col">제공받는 기관</th>
                                            <th scope="col">제공 대상</th>
                                            <th scope="col">이용 목적</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>지적측량 바로처리</td>
                                            <td>시스템 전반 유지관리 및 헬프데스크 운영</td>
                                            <td>(주)스패셜티</td>
                                            <td>063-228-9650</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2}>지적측량 업무지원</td>
                                            <td>고객만족도 조사</td>
                                            <td>㈜엠브레인리서치</td>
                                            <td>02-2015-2400</td>
                                        </tr>
                                        <tr>
                                            <td className="bdrl">시스템 전반 유지 관리</td>
                                            <td>(주)스패셜티</td>
                                            <td>063-228-9650</td>
                                        </tr>
                                        <tr>
                                            <td>고객관계관리</td>
                                            <td>시스템 전반 유지 관리</td>
                                            <td>(주)스패셜티</td>
                                            <td>063-228-9650</td>
                                        </tr>
                                        <tr>
                                            <td>사외보</td>
                                            <td>사외보 발송</td>
                                            <td>㈜이팝</td>
                                            <td>02-514-7567</td>
                                        </tr>
                                        <tr>
                                            <td>공간정보 아카데미</td>
                                            <td>시스템 전반 유지 관리</td>
                                            <td>㈜큐리어드</td>
                                            <td>02-6937-2095</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <ul className="agg-item_list1">
                                <li data-no="3">위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</li>
                            </ul>
                        </section>

                        <section id="제6조">
                            <h3>제6조 정보주체와 법정대리인의 권리‧의무 및 행사방법</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">정보주체는 공공SaaS에 대해 언제든지 개인정보 열람‧정정‧삭제‧처리정지 요구 등의 권리를 행사할 수 있습니다.</li>
                                <li data-no="2">제1항에 대한 권리 행사는 개인정보 처리 방법에 관한 고시 별지 제8호 서식에 따라 작성 후 서면, 전자우편, FAX 등을 통하여 하실 수 있으며 공공SaaS는 이에 대해 지체 없이 조치하겠습니다.</li>
                                <li data-no="3">제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리방법에 관한 고시 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
                                <li data-no="4">개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>
                                <li data-no="5">개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
                                <li data-no="6">공공SaaS는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
                            </ul>
                        </section>

                        <section id="제7조">
                            <h3>제7조 개인정보의 처리 및 보유기간</h3>
                            <div className="agg-item_text">공공SaaS는 다음의 개인정보 항목을 처리하고 있습니다.</div>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                    지적측량 바로처리 온라인 회원가입 및 관리
                                    <ul className="cs-list">
                                        <li><strong>필수 항목 :</strong> 성명, 아이디, 비밀번호</li>
                                        <li><strong>선택 항목 :</strong> 주민등록번호, 주소, 휴대전화번호, 토지소재지, 이메일</li>
                                    </ul>
                                </li>
                                <li data-no="2">
                                    지적측량 의뢰
                                    <ul className="cs-list">
                                        <li><strong>필수 항목 :</strong> 성명, 주민(외국인)등록번호, 주소, 휴대전화번호, 토지소재지, 이메일</li>
                                        <li><strong>선택 항목 :</strong> 전화번호, 이메일</li>
                                    </ul>
                                </li>
                                <li data-no="3">
                                    민원 신청
                                    <ul className="cs-list">
                                        <li><strong>필수 항목 :</strong> 성명, 주소</li>
                                        <li><strong>선택 항목 :</strong> 토지소재지, 전화번호, 토지소재지, 이메일</li>
                                    </ul>
                                </li>
                                <li data-no="4">
                                    사외보 구독 신청
                                    <ul className="cs-list">
                                        <li><strong>필수 항목 :</strong> 성명, 주소, 휴대전화번호, 이메일</li>
                                        <li><strong>선택 항목 :</strong> 없음</li>
                                    </ul>
                                </li>
                                <li data-no="5">
                                    공간정보아카데미 회원가입 및 관리
                                    <ul className="cs-list">
                                        <li><strong>필수 항목 :</strong> 성명, 아이디, 비밀번호, 휴대전화번호, 이메일</li>
                                        <li><strong>선택 항목 :</strong> 회사명, 사업자등록번호, 대표자명, 협약기업유무, 협약기업명</li>
                                    </ul>
                                </li>
                            </ul>
                        </section>

                        <section id="제8조">
                            <h3>제8조 개인정보의 파기</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">공공SaaS는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</li>
                                <li data-no="2">
                                    개인정보 파기의 절차 및 방법은 다음과 같습니다.
                                    <ul className="agg-item_list2">
                                        <li data-no="①">
                                            <strong>파기 절차</strong><br />
                                            공공SaaS는 파기하여야 하는 개인정보에 대해 개인정보 파기계획을 수립하여 파기합니다. 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                                        </li>
                                        <li data-no="②">
                                            <strong>파기 방법</strong><br />
                                            공공SaaS는 전자적 파일 형태로 기록‧저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 이용하여 파기하며, 종이 문서에 기록‧저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </section>

                        <section id="제9조">
                            <h3>제9조 개인정보의 안전성 확보조치</h3>
                            <div className="agg-item_text">공공SaaS는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</div>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                    <strong>관리적 조치</strong><br />
                                    내부관리계획 수립·시행, 정기적 직원 교육 등
                                </li>
                                <li data-no="2">
                                    <strong>기술적 조치</strong><br />
                                    개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
                                </li>
                                <li data-no="3">
                                    <strong>물리적 조치</strong><br />
                                    전산실, 자료보관실 등의 접근통제
                                </li>
                            </ul>
                        </section>

                        <section id="제10조">
                            <h3>제10조 개인정보 자동 수집 장치의 설치‧운영 및 거부에 관한 사항</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">공공SaaS는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</li>
                                <li data-no="2">
                                    쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.

                                    <ul className="agg-item_list2">
                                        <li data-no="①">
                                            <strong>쿠키의 사용목적</strong><br />
                                            이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                                        </li>
                                        <li data-no="②">
                                            <strong>쿠키의 설치·운영 및 거부</strong><br />
                                            웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
                                        </li>
                                        <li data-no="③">쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
                                    </ul>
                                </li>
                            </ul>
                        </section>

                        <section id="제11조">
                            <h3>제11조 개인정보 보호책임자</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">공사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</li>
                            </ul>

                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col className="width140" />
                                        <col />
                                        <col className="width140" />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={2}>개인정보 보호 책임자</th>
                                            <th scope="col" colSpan={2}>개인정보 보호 담당자</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">담당부서</th>
                                            <td>정보전략실</td>
                                            <th scope="row">담당부서</th>
                                            <td>정보보안부</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">직책</th>
                                            <td>실장</td>
                                            <th scope="row">직책</th>
                                            <td>개인정보보호 담당</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">성명</th>
                                            <td>이장희</td>
                                            <th scope="row">성명</th>
                                            <td>강경성</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">전화</th>
                                            <td colSpan={3} className="tac">063-710-0400, 0444</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">이메일</th>
                                            <td colSpan={3} className="tac">privacy@lx.or.kr</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">FAX</th>
                                            <td colSpan={3} className="tac">063-710-0470</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">주소</th>
                                            <td colSpan={3} className="tac">54870 전라북도 전주시 덕진구 기지로 120(중동) 공공SaaS</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <ul className="agg-item_list1">
                                <li data-no="2">공사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 공사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</li>
                            </ul>
                        </section>

                        <section id="제12조">
                            <h3>제12조 개인정보 열람청구</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 공공SaaS는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</li>
                            </ul>

                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col className="width140" />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={2}>개인정보 열람청구 접수·처리부서</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">담당부서</th>
                                            <td>정보보안부</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">직책</th>
                                            <td>개인정보보호 담당 강경성</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">성명</th>
                                            <td>
                                                Tel)063-710-0444<br />
                                                Fax)063-710-0470<br />
                                                privacy@lx.or.kr
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <ul className="agg-item_list1">
                                <li data-no="2">정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 공공SaaS는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</li>
                            </ul>
                        </section>

                        <section id="제13조">
                            <h3>제13조 권익침해 구제방법</h3>
                            <div className="agg-item_text">정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</div>
                            <div className="fz14">! 아래의 기관은 공공SaaS와는 별개의 기관으로서, 공공SaaS의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.</div>
                            <div className="table mt5">
                                <table>
                                    <colgroup>
                                        <col className="width140" />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={2}>개인정보 침해신고센터(한국인터넷진흥원 운영)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">소관업무</th>
                                            <td>개인정보 침해사실 신고, 상담 신청</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">홈페이지</th>
                                            <td>privacy.kisa.or.kr</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">전화</th>
                                            <td>(국번없이) 118</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">주소</th>
                                            <td>우)58324 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="table cs-table-line">
                                <table>
                                    <colgroup>
                                        <col className="width140" />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan={2}>개인정보 분쟁조정위원회</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">소관업무</th>
                                            <td>개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">홈페이지</th>
                                            <td>www.kopico.go.kr</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">전화</th>
                                            <td>(국번없이) 1833-6972</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">주소</th>
                                            <td>우)03171 서울특별시 종로구 세종대로 209 정부서울청사 12층</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ul className="cs-list">
                                <li><strong>대검찰청 사이버수사과 :</strong> (국번없이) 1301 (<a href="http://www.spo.go.kr" target="_blank" title="새창 열림" className="fc-blue">www.spo.go.kr</a>)</li>
                                <li><strong>경찰청 사이버범죄 신고시스템(ECRM) :</strong> (국번없이)182 (<a href="http://ecrm.police.go.kr" target="_blank" title="새창 열림" className="fc-blue">http://ecrm.police.go.kr</a>)</li>
                            </ul>
                        </section>

                        <section id="제14조">
                            <h2>제14조 개인정보 처리방침 변경</h2>
                            <ul className="agg-item_list1">
                                <li data-no="1">정보주체는 아래의 기관에 대해 개인정보이 개인정보 처리방침은 2023년 5월 29일부터 적용됩니다.</li>
                                <li data-no="2">이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;