import { FC } from 'react';
import '../../styles/components/icon.scss';
import './main-section1.scss';


const MainSection1:FC = () => {
    return (
        <section className='main-visual'>
            <div className="row">
                <div className="container">
                    <div className="group">
                        <h3><b>3D</b> Data</h3>
                        <h4>Information <br />Management & Analytics</h4>
                        <p>
                            3D Reconstruction 및 실감정사영상 자동 제작을 통해 <br className='md-block' />체계적인 업무 프로세스를 지원하며,<br />
                            클라우드 기반 데이터 관리를 통해 다양한 3D 활용 업무를 <br className='md-block' />지원합니다.
                        </p>
                    </div>
                    <i className='icon--arrowdown-white' aria-hidden='true'></i>
                </div>
            </div>
        </section>
    )
}
export default MainSection1;