import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { FIND_OGNZ_LIST, findOgnzListAsync } from './actions';
import { findOgnzList } from '../../api/ognz';


const findOgnzListSaga = createAsyncSaga(findOgnzListAsync, findOgnzList);

export function* ognzSaga() {
  yield takeEvery(FIND_OGNZ_LIST, findOgnzListSaga);
}
