import { FC } from 'react';
import '../styles/components/paging.scss';
import { PotalCommDto } from '../types/PotalCommDto';

interface SearchFormProps {
    pageingDto : PotalCommDto;
    onClick: (value: number) => void; // onChange prop 추가
}

const Pagination:FC<SearchFormProps> =({ pageingDto , onClick }) => {
    
    const handleClick = (pageNo: number) => {
        onClick(pageNo); // 선택된 값 전달
    };

    
   // console.log('페이징 결과:', JSON.stringify(pageingDto, null, 2));

    const pageingList = [];

    for ( let pageNo =  Number(pageingDto?.firstPageNo); pageNo <=  Number(pageingDto?.lastPageNo); pageNo++) {
      if (pageingDto.currentPageNo ==  pageNo) {
         pageingList.push (<button key={pageNo} type='button' className='paging' aria-current='true' title='현재 목록'>{pageNo}</button>);
      } else {
         pageingList.push (<button key={pageNo} type='button' className='paging' aria-current='false' value="{pageNo}" onClick={() => handleClick(pageNo)}>{pageNo}</button>);
      }
    }

    if (pageingList.length == 0) {
      pageingList.push (<button key={1} type='button' className='paging' aria-current='true' title='현재 목록'>1</button>);
    }

    return (
        <nav className='pagination'>
            {Number(pageingDto?.prevPageNo ) > 0 ? ( 
               <button type='button' key={pageingDto.prevPageNo} title='이전 목록' className='prev'  onClick={() => handleClick(Number(pageingDto.prevPageNo))}><i className='icon--arwleft-black' aria-hidden='true'></i><span>이전</span></button>
            ):( 
               <span></span>
            )}

           <span>{pageingList}</span>

           {Number(pageingDto?.nextPageNo ) > 0 ? ( 
              <button type='button' key={pageingDto.nextPageNo} title='다음 목록 이동' className='next'  onClick={() => handleClick(Number(pageingDto.nextPageNo))}><span>다음</span><i className='icon--arwright-black' aria-hidden='true' ></i></button>
           ) : (
              <span></span>
           ) }
        </nav>
    )
}

export default Pagination;