import { FC } from "react";
import { Link } from 'react-router-dom';
import '../styles/pages/404.scss';


const NotFound: FC = () => {
    return (
        <div className='notfound'>
            <div className='img'><img src="../images/404/404.png" alt="" /></div>
            <div className="error-title">요청하신 페이지를 찾을 수 없습니다.</div>
            <div className="error-text">
                현재 접속하신 페이지의 주소가 잘못 입력되었거나,<br />
                변경 또는 삭제되어 페이지를 찾을 수 없습니다.<br />
                홈페이지의 메인으로 이동 후 원하시는 메뉴를 선택해 주세요.
            </div>
            <div className="retry-button">
                <Link to={'/'} className="button button--outline button--lg button--round">메인 화면으로 이동</Link>
            </div>
        </div>
    )
}

export default NotFound;