import { FC } from 'react';
import './stepProgress.scss';

interface StepProps {
    currentStep: number;
    totalSteps: number;
}

const StepProgress:FC<StepProps> = ({ currentStep, totalSteps  }) => {
    return (
        <div>
            <div className="step-progress">
                <strong className="step-current">STEP {currentStep}</strong>
                <span>/</span>
                <strong className="step-total">STEP {totalSteps}</strong>
            </div>

            <div className="step-info">
                {currentStep === 1 && (
                    <>
                    <h3>약관 동의</h3>
                </>
                )}
                {currentStep === 2 && (
                    <>
                    <h3>정보 입력</h3>
                </>
                )}
                {currentStep === 3 && (
                    <>
                    <h3>회원 가입 완료</h3>
                </>
                )}
            </div>
        </div>
    )
}

export default StepProgress;