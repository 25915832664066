import { FC } from 'react';
interface MainHeaderProps {
    children: React.ReactNode;

}
const MainHeader: FC<MainHeaderProps> = ({ children }) => {
    return (
        <header className="main-hgroup">
            {children}
        </header>
    )
}

export default MainHeader;