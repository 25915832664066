import config from "../../config";

export const openMapprimePage = () => {
    if(sessionStorage.getItem("isMapprimeSubscribe")) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `${config.mapprimeApiUrl}/${config.mapprimeApiVer}/gate-m`; // action URL 설정
        form.target = "manager-window";
    
        // 필요한 hidden input 생성 (예: 토큰)
        const accessField = document.createElement('input');
        accessField.type = 'hidden';
        accessField.name = 't';
        accessField.value = sessionStorage.getItem('access_token') || ''; // 적절한 토큰 값을 사용하세요
        form.appendChild(accessField);
    
        // 필요한 hidden input 생성 (예: 토큰)
        const refreshField = document.createElement('input');
        refreshField.type = 'hidden';
        refreshField.name = 'r';
        refreshField.value = sessionStorage.getItem('refresh_token') || ''; // 적절한 토큰 값을 사용하세요
        form.appendChild(refreshField);
    
        // 폼을 body에 추가하고 제출
        document.body.appendChild(form);
        form.submit();
    
        // 폼 제거 (더 이상 필요하지 않음)
        document.body.removeChild(form);
    } else {
        alert("구독하지 않은 서비스입니다.");
    }
}

export const openNeospectraPage = () => {
    if(sessionStorage.getItem("isNeospectraSubscribe")) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `${config.neospectraApiUrl}/redirect/${config.neospectraApiVer}/`; // action URL 설정
        form.target = "manager-window";
    
        // access_token
        const access_token = document.createElement('input');
        access_token.type = 'hidden';
        access_token.name = 'access_token';
        access_token.value = sessionStorage.getItem('access_token') || ''; // 적절한 토큰 값을 사용하세요
        form.appendChild(access_token);
    
        // refresh_token
        const refresh_token = document.createElement('input');
        refresh_token.type = 'hidden';
        refresh_token.name = 'refresh_token';
        refresh_token.value = sessionStorage.getItem('refresh_token') || ''; // 적절한 토큰 값을 사용하세요
        form.appendChild(refresh_token);
    
        // target
        const target = document.createElement('input');
        target.type = 'hidden';
        target.name = 'target';
        target.value = 'home'; // 적절한 토큰 값을 사용하세요
        form.appendChild(target);
    
        // 폼을 body에 추가하고 제출
        document.body.appendChild(form);
        form.submit();
    
        // 폼 제거 (더 이상 필요하지 않음)
        document.body.removeChild(form);
    } else {
        alert("구독하지 않은 서비스입니다.");
    }
}