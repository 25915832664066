import { createReducer } from 'typesafe-actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { getInfoseedGalleryAsync, getMapprimeGalleryAsync, getNeospectraGalleryAsync } from './actions';
import { ProductAction, ProductState } from './types';
import { AnyAction } from 'redux';

const initialState: ProductState = {
  mapprime: asyncState.initial(),
  neospectra: asyncState.initial(),
  infoseed: asyncState.initial(),
};

const projReducer = createReducer<ProductState, ProductAction>(initialState)
.handleAction(
  getMapprimeGalleryAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getMapprimeGalleryAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    mapprime: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getMapprimeGalleryAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    mapprime: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
).handleAction(
  getNeospectraGalleryAsync.request, // 요청 액션
  (state) => ({
    ...state,
    loading: true,
    error: null,// 로딩 상태로 변경
  })
)
.handleAction(
  getNeospectraGalleryAsync.success, // 성공 액션
  (state, action) => ({
    ...state,
    neospectra: asyncState.success(action.payload),
    loading: false,
    error: null,// 성공 상태로 업데이트
  })
)
.handleAction(
  getNeospectraGalleryAsync.failure, // 실패 액션
  (state, action) => ({
    ...state,
    neospectra: asyncState.error(action.payload), // 에러 상태로 업데이트
    loading: false,
    error: action.payload,
  })
)
.handleAction(
  transformToArray(getInfoseedGalleryAsync),
  createAsyncReducer(getInfoseedGalleryAsync, 'infoseed')
);

export default projReducer;