import { FC } from 'react';
import '../styles/components/dialog.scss';

interface DialogProps {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
    footChildren?: React.ReactNode;
}

const Dialog:FC<DialogProps> = ( { id, isOpen, onClose, title, children , footChildren } ) => {
    
    if (!isOpen) return null;

    return (
    <>
        <div className="dialog-backdrop" onClick={onClose}></div>
        <div className="dialog" id={id}>
            <div className="dialog-container">
                <div className="dialog-body">
                    <div className='dialog-inner'>
                        <header className="dialog-header">
                            {title && <h2 className="dialog-title">{title}</h2>}
                            <button className='dialog-close' onClick={onClose}>닫기</button>
                        </header>
                        <div className='dialog-content'>
                            {children}
                        </div>
                    </div>
                </div>     
            </div>
            {/* dialog-container */}
        </div>
    </>
    )
}

export default Dialog;