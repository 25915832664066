import { FC } from 'react';
import { Link } from "react-router-dom";
import Banners  from "../../containers/banner";

interface PageProps {
    title: string;
}

const ServiceOverview:FC<PageProps> = ({title}) => {
    return (
        <div className="features">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <p className="service-txt-top"> 
                        SaaS 기반의 영상업무 관리 서비스를 통해 공공 영상 데이터의 체계적 관리 기반을 만들고,<br className="br-responsive"/>
                        데이터 기반의 적극적인 행정을 추진하기 위해 3차원 분석, AI 등 최신 기술을 영상 활용 업무에 적용하여,<br/>
                        영상 데이터 관리 및 활용 효율성을 향상시켜 공공 서비스의 질을 높이고,<br className="br-responsive"/>
                        클라우드 기반 시스템 도입으로 디지털 혁신을 촉진하며 국내 기술 산업의 성장을 지원합니다.
                    </p>
                    <div className='service-target'>
                        <img src="../../../images/service/service_target.png" alt="" />
                    </div>
                    <dl className='service-dl'>
                        <dt>서비스 목적</dt>
                        <dd></dd>
                    </dl>
                    <dl className="service-ul">
                        <dt><span>01</span>공공 영상 데이터의 틀을 마련하기 위한 영상 업무 관리 및 분석 프로그램 공공 SaaS 개발</dt>
                        <dd>
                            <ul>
                                <li>영상 데이터 수집을 위한 계획 수립과 촬영 단계의 업무 관리툴 개발</li>
                                <li>취득된 영상 데이터(항공, 드론, 카메라, 스마트폰)를 체계적으로 관리하는 클라우드 기반의 DB 통합 관리체계 구축</li>
                                <li>3D 복원, 객체 탐지 AI, 2D,3D 서비스 및 업무 적용과 같은 고도화된 2D, 3D 영상 업무 처리 기술을 적용하는 기반을 마련하여 영상 데이터가 사용되는 업무의 효율성 극대화</li>
                            </ul>
                        </dd>
                    </dl>
                    <dl className="service-ul mb15">
                        <dt><span>02</span>통합 관리되는 영상 데이터를 통해 경쟁력 있는 영상처리 기술 개발 및 업무 적용</dt>
                        <dd>
                            <ul>
                                <li>스마트폰과 드론 시장의 성장으로 얻은 다양한 영상 데이터를 활용하여 국내의 여러 산업 분야에 적용 가능한 서비스 개발</li>
                                <li>외산 소프트웨어에 의존한 영상처리 작업에서 벗어나, 국내 기술 경쟁력을 강화하고 영상처리 기술 연구의 기반을 마련</li>
                            </ul>
                        </dd>
                    </dl>
                    <div className="service-card mb80">
                        <h3 className='service-card-ti'>&lt; 영상 처리/관리 업무 프로세스 &gt;</h3>
                        <div className='service-card-cont'>
                            <img src="../../../images/service/service_process.png" alt="" />
                        </div>
                    </div>
                    <dl className='service-dl'>
                        <dt>서비스 효과</dt>
                        <dd>영상 데이터 관리 및 활용 효율성을 향상시켜 공공 서비스의 질을 높이고, 클라우드 기반 시스템 도입으로 디지털 혁신을 촉진하며 국내 기술 산업의 성장을 지원합니다.</dd>
                    </dl>
                    <dl className="service-ul">
                        <dt><span>01</span>공공부문의 효율적인 디지털화 촉진</dt>
                        <dd>
                            <ul>
                                <li>공공 영상 데이터를 통합 관리하여 데이터의 활용도 증가(10% -&gt; 60% 이상)및 보안 강화</li>
                                <li>클라우드 기반 SaaS 프로그램을 통해 데이터 접근 시간이 평균 50% 단축되며, 업무 만족도 또한 90% 상승 효과</li>
                                <li>중복 데이터 처리의 비효율성을 해소함으로써 데이터 관리 비용 30% 이상 절감 효과</li>
                                <li>향상된 영상업무 처리 기술을 통한 공공 업무 처리 시간 단축 예상</li>
                            </ul>
                        </dd>
                    </dl>
                    <dl className="service-ul mb30">
                        <dt><span>02</span>클라우드 기반 영상처리 기술의 혁신적 통합 및 활용</dt>
                        <dd>
                            <ul>
                                <li>중앙화된 통합 데이터 플랫폼 구축을 통해, 데이터 저장, 검색 및 분석 프로세스 혁신화 및 데이터 관리의 효율성과 신뢰성 증가</li>
                                <li>영상업무 처리 과정에서 외산 소프트웨어에 대한 의존도를 줄이고, 국내 기술의 기술적 독립성 확보 가능</li>
                                <li>자체 보유 중인 고도화된 공간정보 및 영상처리 기술을 공공 업무에 적용하여 공공기관의 기술적 유연성을 증가시키고, 기술 업데이트와 유지보수를 용이하게 함</li>
                            </ul>
                        </dd>
                    </dl>
                    <div className="service-card mb30">
                        <h3 className='service-card-ti'>&lt; 클라우드 기반 자료 관리 &gt;</h3>
                        <div className='service-card-cont'>
                            <img src="../../../images/service/sevice_cloude.png" alt="" />
                        </div>
                    </div>
                    <dl className="service-ul mb30">
                        <dt><span>03</span>데이터 기반 사회의 실현과 지속 가능한 사회 발전</dt>
                        <dd>
                            <ul>
                                <li>공공부문에서의 데이터 통합 관리로 효율적인 과제 시행이 가능해지며, 이는 공공 영상 데이터의 품질 개선 및 고품질의 공공 서비스 제공으로 이루어질 수 있음</li>
                                <li>공간정보 및 영상처리 기술의 도입으로 외산 소프트웨어에 대한 연간 지출 비용을 절감하여 장기적인 기술 경제적 자립성 확보</li>
                                <li>고도화된 영상 처리 기술의 적용으로 산업별 생산성을 향상시켜, 국가 통계 분야(지적 조사), 안전 · 재난 · 치안 분야(인명구조, 침입 감시, 교통 순찰), 하천 해양 · 산림 분야(산림 및 환경 감시)에서 작업 효율성과 정확성 개선</li>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default ServiceOverview;