import axios from 'axios';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import { PotalCommDto } from '../types/PotalCommDto';
import config from '../config';
import { MapprimeGalleryDto, SelectMapprimeGalleryDto, MapprimeGalleryApiResponse, NeospectraGalleryApiResponse, SelectNeospectraGalleryDto, SelectInfoseedGalleryDto, InfoseedGalleryApiResponse, ErrorReturn } from '../types/ProjectsDto';
import accessTokenPortalApi, { accessTokenInfoseedApi, accessTokenMapprimeApi, accessTokenNeospectraApi } from './api';


export async function getMapprimeGallery({ dto }: { dto: SelectMapprimeGalleryDto }): Promise<MapprimeGalleryApiResponse | null> {
    try {
        const response = await accessTokenMapprimeApi.get<Promise<MapprimeGalleryApiResponse> | null>(
            `${config.mapprimeApiVer}/data/portal/gallery`,
            {
                params: {
                    keyword: dto.keyword,   // 키워드
                    align: dto.align,   // 정렬 컬럼
                    page: dto.page,     // 페이지
                    limit: dto.limit,   // 개수
                }
            }
        );
        // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

        return response.data;
    } catch (error) {
        // 토큰 인증 실패 처리
        console.error('Failed to select:', error);

        return {
            result: 'error',
            message: '인증 실패 또는 다른 오류 발생',
        };
    }
};

export async function getNeospectraGallery({ dto }: { dto: SelectNeospectraGalleryDto }): Promise<NeospectraGalleryApiResponse | null> {
    try {
        const params: { [key: string]: string | number | undefined } = {
            page: dto.page,
            size: dto.size,
            search: dto.search,
        };

        if (dto.category && dto.category !== "") {
            params.category = dto.category;
        }

        const response = await accessTokenNeospectraApi.get<Promise<NeospectraGalleryApiResponse> | null>(
            `/project/${config.neospectraApiVer}/e/projects`,
            {
                params,
            }
        );
        // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

        return response.data;
    } catch (error) {
        // 토큰 인증 실패 처리
        console.error('Failed to select:', error);

        return {
            result: 'error',
            message: '인증 실패 또는 다른 오류 발생',
        };
    }
};

export async function getInfoseedGallery({ dto }: { dto: SelectInfoseedGalleryDto }): Promise<InfoseedGalleryApiResponse | ErrorReturn | null> {
    try {
        const params: { [key: string]: string | number | undefined } = {
            category: dto.category, 
            page: dto.page,
            size: dto.size,
            keyword: dto.keyword,
        };

        if (dto.category && dto.category !== "") {
            params.category = dto.category;
        }

        const response = await accessTokenInfoseedApi.get<Promise<InfoseedGalleryApiResponse> | null>(
            `/v1/project/gallery`,
            {
                params,
            }
        );
        // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));

        return response.data;
    } catch (error) {
        // 토큰 인증 실패 처리
        console.error('Failed to select:', error);
        return {
            result: 'error',
            message: '인증 실패 또는 다른 오류 발생',
        };
    }
};