import { createReducer } from 'typesafe-actions';
import { SupportState, SupportAction } from './types';
import { getNoticesAsync , getNoticeAsync , getFaqsAsync , getContactUsListAsync , getContactUsAsync , postContactUsAsync , putContactUsAsync , deleteContactUsAsync  } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { AnyAction } from 'redux';

const initialState: SupportState = {
  data: asyncState.initial(),

  contactUsList: asyncState.initial(),
  contactUs: asyncState.initial(),
  contactUsInsert: asyncState.initial(),
  contactUsUpdate: asyncState.initial(),
  contactUsDelete: asyncState.initial(),
};

const ntcs = createReducer<SupportState, AnyAction>(initialState)
.handleAction(
  transformToArray(getNoticesAsync),
  createAsyncReducer(getNoticesAsync, 'data')
)
.handleAction(
  transformToArray(getNoticeAsync),
  createAsyncReducer(getNoticeAsync, 'data')
)
.handleAction(
  transformToArray(getFaqsAsync),
  createAsyncReducer(getFaqsAsync, 'data')
)
.handleAction(
  transformToArray(getContactUsListAsync),
  createAsyncReducer(getContactUsListAsync, 'data')
)
.handleAction(
  transformToArray(getContactUsAsync),
  createAsyncReducer(getContactUsAsync, 'data')
)
.handleAction(
  transformToArray(postContactUsAsync),
  createAsyncReducer(postContactUsAsync, 'contactUsInsert')
)
.handleAction(
  transformToArray(putContactUsAsync),
  createAsyncReducer(putContactUsAsync, 'contactUsUpdate')
)
.handleAction(
  transformToArray(deleteContactUsAsync),
  createAsyncReducer(deleteContactUsAsync, 'contactUsDelete')
)
;

export default ntcs;