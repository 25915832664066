import { FC } from 'react';
import './progress.scss';

interface ProgressProps {
    item: {
        id: string;
        title: string;
        count: number;
        text : string;
        width? :string
    };
    theme: string; 
}
const Progress:FC<ProgressProps> = ({item, theme = 'blue'}) => {
    const titleWidth = (item.width ? item.width : "10%")
    return (
        <div className={`progress-ui theme-${theme}`}>
            <label htmlFor={item.id} style={{width:titleWidth , minWidth:"100px"}}>{item.title}</label>
            <span style={{width:"10%"}}>{item.count} % </span>
            <div className="progress-group">
                <progress id={item.id} max="100" value={item.count}></progress>
                <span className="count">{item.text}</span>
            </div>
        </div>
    )
}

export default Progress;