import { FC, useState } from 'react';
import { Link } from "react-router-dom";
import Banners  from "../../containers/banner";
import TabComponent from '../../components/Tab';
import './service.scss';

interface PageProps {
    title: string;
}

const tabs = [
    { id: 1, title: '영상촬영 서비스' , control : 'content-1'},
    { id: 2, title: 'AI 데이터 생성관리 서비스' , control : 'content-2'},
    { id: 3, title: '디지털트윈 지도 서비스' , control : 'content-3'},
];

const MainFeatures:FC<PageProps> = ({title}) => {

    const [currentTab, setCurrentTab] = useState<number>(tabs[0].id);
    const handleTabClick = (id: number) => {
        setCurrentTab(id);
    };
    
    return (
        <div className="features">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <TabComponent 
                    tabs={tabs} 
                    role='tab' 
                    current={ currentTab } 
                    onClick={(index, tab) => handleTabClick(tab.id)}
                    />
                    <div className="tabpanel features-video" id='content-1' role='tabpanel' hidden={currentTab  !== 1}>
                        <div className="flex mb80">
                            <div className='col-4'>
                                <dl>
                                    <dt className="mb30">geo.nick</dt>
                                    <dd>
                                    ‘geo.nick’은 전 지구를 약 1m 크기의 격자로 분할하고<br /> 격자마다 고유한 주소를 부여하여<br /> 정밀한 위치를 소통할 수 있는 정밀격자주소 플랫폼입니다.
                                    </dd>
                                </dl>
                            </div>
                            <div className='col-7'>
                                <div className="service-card">
                                    <h3 className='service-card-ti'>&lt; 정밀격자주소서비스(geo.nick) 서비스 화면 &gt;</h3>
                                    <div className='service-card-cont'>
                                        <img src="../../../images/service/geonick.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex mb80 nth-2">
                            <div className='col-7'>
                                <div className="service-card">
                                    <h3 className='service-card-ti'>&lt; 현장조사사진서비스(geo.pic) 서비스 화면 &gt;</h3>
                                    <div className='service-card-cont'>
                                        <img src="../../../images/service/geopic.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <dl>
                                    <dt className="mb30">geo.pic</dt>
                                    <dd>
                                    ‘geo.pic’은 새롭고 혁신적인 사진 위치표시, 공유서비스 입니다.<br />글이나 말로 주소를 설명하기 힘든 장소에서 사진을 찍어<br />사진의 정밀주소를 쉽게 기록하고 및 전송할 수 있습니다.
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div className="flex mb80">
                            <div className='col-4'>
                                <dl>
                                    <dt className="mb30">photo map</dt>
                                    <dd>
                                    ‘photo map’은 geo.pic으로 취득한 현장조사사진을<br />프로젝트 단위로 지도에 표시하고 조회하는 서비스 입니다.
                                    </dd>
                                </dl>
                            </div>
                            <div className='col-7'>
                                <div className="service-card">
                                    <h3 className='service-card-ti'>&lt; 정밀격자주소서비스(geo.nick) 서비스 화면 &gt;</h3>
                                    <div className='service-card-cont'>
                                        <img src="../../../images/service/photomap.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='link-view'>
                            <Link to={'/product'} className='button button--primary button--lg button--round'>서비스 신청하기</Link>
                        </div>
                    </div>
                    <div className="tabpanel features-digital" id='content-2' role='tabpanel' hidden={currentTab  !== 2}>
                        {/* <dl>
                            <dt className="mb30">3D Reconstruction</dt>
                            <dd>
                                요약 : 연속된 사진을 활용하여 고품질 3D 모델을 만들 수 있습니다<br/><br/>
                                마트폰으로 촬영한 연속된 이미지를 본 서비스에 업로드하면, 자동으로 3D 모델을 구축할 수 있습니다.
                                ​3D 재구성의 결과는 이미지의 Texture가 포함된 3D<br className="br-responsive"/>모델과
                                이미지의 Texture가 불포함된 3D Mesh 형태로 출력 합니다
                            </dd>
                        </dl>
                        <div className="center mb50">
                            <img src="../../../images/service/ai_98.png" alt="" />
                        </div>
                        <div className="service-card mb80">
                            <h3 className='service-card-ti'>&lt; MapPrime 3D Manager 서비스 화면 &gt;</h3>
                            <div className='service-card-cont'>
                                <div>
                                    <img src="../../../images/service/ai_01.png" alt="" />
                                    <p>[3D 모델]</p>
                                </div>
                                <div>
                                    <img src="../../../images/service/ai_02.png" alt="" />
                                    <p>[3D Mesh]</p>
                                </div>
                            </div>
                        </div>
                        <dl>
                            <dt className="mb30">실감정사영상</dt>
                            <dd>
                            요약 : 항공(드론) 이지지로 생성한 3D 모델을 CV방식을 활용하여 실감정사영상으로 변환합니다.<br/><br/>
                            설명 : 정사영상이란 사진촬영 당시의 카메라 자세 및 지형 기복에 ​의해 발생된 물체의 변위를 제거한 영상을 말하며,<br className="br-responsive"/>
                            ​정사보정 작업을 통하여 제작된 항공사진은 지형지물 등 ​위치 관계가 지도와 동일해집니다.<br className="br-responsive"/> ​옆으로 기울어진 것처럼 보이는 형상이 정사 보정을 거친 영상에서는 수직으로 내려다 본 영상으로 표현됩니다.
                            </dd>
                        </dl>
                        <div className="center mb50">
                            <img src="../../../images/service/ai_99.png" alt="" />
                        </div>
                        <div className="service-card mb80">
                            <div className='service-card-cont'>
                                <div>
                                    <img src="../../../images/service/ai_03.png" alt="" />
                                    <p>(실감정사영상 이미지)</p>
                                </div>
                            </div>
                        </div>
                        <dl>
                            <dt className="mb30">해상도 향상</dt>
                            <dd>
                            요약 : AI를 활용하여 저해상도 이미지를 고해상도 이미지로 변환합니다.<br/><br/>
                            설명 : Super Resolution(SR) 기술은 저해상도의 영상을 고해상도의 ​영상으로 변환하는 기술입니다.<br className="br-responsive"/> ​고해상도 이미지는 더 많은 세부 정보와 더 적은 노이즈를 ​가지고 있기 때문에 시각적인 선명도와 품질이 향상됩니다.​

                            </dd>
                        </dl>
                        <div className="service-card mb80">
                            <div className='service-card-cont'>
                                <div>
                                    <img src="../../../images/service/ai_04.png" alt="" />
                                    <p>(해상도 향상 이미지)</p>
                                </div>
                            </div>
                        </div>
                        <dl>
                            <dt className="mb30">변화 탐지</dt>
                            <dd>
                            요약 : AI를 활용하여 전, 후 이미지의 건물 변화 탐지를 수행합니다.<br/><br/>
                            설명 : AI기반 도시 변화탐지 기술 실용화 연구를 통하여, ​도시변화(건물, 녹지, 태양광 등) 자동탐지 체계 구축 및 ​현업화 기반을 마련하고,<br className="br-responsive"/>
                             도시변화 관리 업무의 ​행정 효율화를 도모합니다.​ 현재는 실감정사영상 간 변화탐지만 지원합니다.​

                            </dd>
                        </dl>
                        <div className="service-card mb80">
                            <div className='service-card-cont'>
                                <div>
                                    <img src="../../../images/service/ai_05.png" alt="" />
                                    <p>(변화 탐지 이미지)</p>
                                </div>
                            </div>
                        </div> */}
                        <dl>
                            <dt className="mb30">객체 탐지</dt>
                            <dd>
                            요약 : 사용자가 선택한 객체(자동차, 사람, 양식장, 해양쓰레기)를 AI가 이미지에서 선택하여 보여줍니다.​<br/><br/>
                            설명 : 이미지 객체 탐지는 국가 안보, 재해재난 감시, 기상 관측, ​지도 제작 등 다양한 분야에 활용하기 위해 지속적으로 ​연구되고 있는 분야입니다.​
                            </dd>
                        </dl>
                        <div className="service-card mb80">
                            <div className='service-card-cont'>
                                <div>
                                    <img src="../../../images/service/ai_06.png" alt="" />
                                    <p>(객체 탐지 이미지)</p>
                                </div>
                            </div>
                        </div>
                        <dl>
                            <dt className="mb30">객체 삭제</dt>
                            <dd>
                            요약 : 사용자가 선택한 객체(자동차, 사람, 양식장, 해양쓰레기)를 AI가 삭제하여 이미지에서 보여줍니다.​​<br/><br/>
                            설명 : 이미지에서 불필요한 부분을 제거하여 자연스럽게 ​해소하는 인페인팅 기술은 다방면에서 연구되고 있으며,<br className="br-responsive"/>
                            ​이 기술은 이미지에서 특정 객체 제거와 손상된 ​이미지 복구와 같은 기본적인 이미지 편집 작업에 ​영향을 미치고 있습니다.​
                            </dd>
                        </dl>
                        <div className="service-card mb80">
                            <div className='service-card-cont'>
                                <div>
                                    <img src="../../../images/service/ai_07.png" alt="" />
                                    <p>(객체 탐지 이미지)</p>
                                </div>
                            </div>
                        </div>

                        <div className='link-view'>
                            <Link to={'/product'} className='button button--primary button--lg button--round'>서비스 신청하기</Link>
                        </div>
                    </div>
                    <div className="tabpanel features-digital" id='content-3' role='tabpanel' hidden={currentTab  !== 3}>
                        <dl>
                            <dt className="mb30">MapPrime 3D Manager</dt>
                            <dd>
                                MapPrime 3D Manager는 MapPrime 3D Studio에서 제공하는 각종 기능을 생성 및 관리하는 지도 기반 데이터 관리 서비스입니다.<br className="br-responsive"/>
                                MapPrime 3D Manager는 디지털트윈 지도 서비스 개발을 위한 3D타일, 지형 정보, 3D 모델, 이미지, 배경 타일 등 다양한 지도 기반 데이터를 제공하며,<br className="br-responsive"/>
                                사용자가 쉽고 편리하게 지도 서비스를 제작할 수 있도록 지원합니다.
                            </dd>
                        </dl>
                        
                        <div className="manager-serivce-flow">
                        <h3 className="">MapPrime 3D Manager 서비스 구조</h3>
                            <img src="../../../images/service/mapprimetbl.png" alt="" />
                        </div>
                        
                        <div className="service-card mb80">
                            <h3 className='service-card-ti'>&lt; MapPrime 3D Manager 서비스 화면 &gt;</h3>
                            <div className='service-card-cont'>
                                <img src="../../../images/service/manager01.png" alt="" />
                                <img src="../../../images/service/manager02.png" alt="" />
                            </div>
                        </div>

                        <dl>
                            <dt className="mb30">MapPrime 3D Studio</dt>
                            <dd>
                            MapPrime 3D Studio는 MapPrime 3D Manager에서 관리되는 데이터를 불러와<br className="br-responsive"/>
                            3D 엔진 혹은 웹페이지 개발에 관한 코드 작성 없이도 사용자가 직접 건물이나 조경 같은 객체를 자유롭게 배치하고<br className="br-responsive"/>
                            크기나 색상들을 변경하며 Cesium 기반 3D 프로젝트를 손쉽게 저작하고 배포할 수 있는 서비스입니다.
                            </dd>
                        </dl>

                        <div className="service-card mb15">
                            <h3 className='service-card-ti'>&lt; MapPrime 3D Studio 서비스 화면 &gt;</h3>
                            <div className='service-card-cont'>
                                <img src="../../../images/service/studio01.png" alt="" />
                                <img src="../../../images/service/studio02.png" alt="" />
                            </div>
                        </div>
                        <div className="service-card mb80">
                            <h3 className='service-card-ti'>&lt; 서비스 예시 화면 &gt;</h3>
                            <div className='service-card-cont'>
                                <img src="../../../images/service/exservice01.png" alt="" />
                                <img src="../../../images/service/exservice02.png" alt="" />
                            </div>
                        </div>

                        <div className='link-view'>
                            <Link to={'/product'} className='button button--primary button--lg button--round'>서비스 신청하기</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainFeatures;