import { getUsersAsync, setUserAsync, getUserAsync, GET_USER, SET_USER, GET_USER_BY_ID, getPasswordAsync, GET_PASSWORD } from './actions';
import { createUser, getUsers, getUser, getPassword} from '../../api/users';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';

const getUserSaga = createAsyncSaga(getUsersAsync, getUsers);
const setUserSaga = createAsyncSaga(setUserAsync, createUser);
const getUserByIdSaga = createAsyncSaga(getUserAsync, getUser);
const getPasswordSaga = createAsyncSaga(getPasswordAsync, getPassword);

export function* userSaga() {
  yield takeEvery(GET_USER, getUserSaga);
  yield takeEvery(GET_USER_BY_ID, getUserByIdSaga);
  yield takeEvery(GET_PASSWORD, getPasswordSaga);
}
export function* createSaga() {
  yield takeEvery(SET_USER, setUserSaga);
}