import { FC } from 'react';
import '../../styles/components/icon.scss';
import './ProjectManage.scss';

// Props 정의
interface ProjectSectionProps {
    title: string;
    light?: string;
    onButtonClick?: () => void;
    children?: React.ReactNode;
    className?: string;
    buttonComponent?: React.ReactNode;
    buttonClassName?: string;
}

const ProjectSections:FC<ProjectSectionProps> = ({ title, light, onButtonClick, children, className , buttonComponent , buttonClassName}) => {
    return (
        <div className={`col ${className ? className : ''}`}>
            <header className="col-header">
                <h4>
                {title}
                {light && (
                    <em>&nbsp;{light}</em>
                )}
                </h4>
                {buttonComponent ? (
                    <div className={buttonClassName}>{buttonComponent}</div>
                ) : onButtonClick && (
                    <button type="button" onClick={onButtonClick}>
                        <i className={buttonClassName}></i>
                    </button>
                )}
            </header>
            <div className="col-content">
                {children}
            </div>
        </div>
    );
};

export default ProjectSections;