import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Banners from "../../containers/banner";
import { CreateBuyDto }  from "../../types/CreateBuyDto";
import { putBuyAsync }  from "../../modules/product";
import { getProfile } from '../../api/authuser';
import './Buy.scss';
interface PageProps {
  title: string;
}

  const BuySuccess: FC<PageProps> = ({ title }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [responseData, setResponseData] = useState(null);
    const [createBuyDto, setCreateBuyDto] = useState<CreateBuyDto>();

    // 결과 리턴값 설정
    const buyResult = useSelector((state:RootState) => state.product.data);
 const product       = useSelector((state:RootState) => state.product.product);
    useEffect(() => {
      const fetchSearchData = async () => {
        const orderId: string | undefined = searchParams.get("orderId") as string;
        const paymentType: string | undefined = searchParams.get("paymentType") as string;
        const amount: string | undefined = searchParams.get("amount") as string;
        const paymentKey: string | undefined = searchParams.get("paymentKey") as string;

        setCreateBuyDto({
          stlmId:orderId,
          stlmMthd: paymentType,
          stlmAmt:Number(amount),
          stlmKey : paymentKey,   
        });

    };

    fetchSearchData();
        
    }, [searchParams]);

    useEffect(() => {
     

      const fetchSearchData = async () => {
        
        if (createBuyDto) {
          await dispatch(putBuyAsync.request(createBuyDto)); 
        }
        
    };

    fetchSearchData();
        
    }, [createBuyDto]);

    useEffect(() => {
      console.log('buyResult 리턴 결과:', JSON.stringify(buyResult, null, 2));

      if (buyResult.data) {
        if (buyResult.data.status == "error") {
          if (buyResult.data.error) {
              navigate('/buyFail?code='+buyResult.data.error.message+'&message='+buyResult.data.error.message);
              return;
          }
        } else {
          const buyOkData = async () => {
            await getProfile();
            await setResponseData((prev) => ( buyResult.data));
          }

          buyOkData();
          
        }
      }
      

    }, [buyResult]);

  return (
    <div className='board buySuccess'>
      <Banners title={title} />
      <div className="board-list">
        <div className="row">
          <div className="container">
            <div className="box_section" style={{ textAlign: "center" }}>
              <img width="100px" src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png" />
              <h2>결제를 완료했어요</h2>
              <div className="p-grid typography--p" style={{ marginTop: "50px" }}>
                <div className="p-grid-col text--left">
                <b>결제금액</b>
              </div>
              <div className="p-grid-col text--right" id="amount">
                {`${Number(searchParams.get("amount")).toLocaleString()}원`}
              </div>
            </div>
            <div className="p-grid typography--p" style={{ marginTop: "10px" }}>
              <div className="p-grid-col text--left">
                <b>주문번호</b>
              </div>
              <div className="p-grid-col text--right" id="orderId">
                {`${searchParams.get("orderId")}`}
             </div>
            </div>
            <div className="p-grid typography--p" style={{ marginTop: "10px" }}>
              <div className="p-grid-col text--left">
                <b>paymentKey</b>
              </div>
              <div className="p-grid-col text--right" id="paymentKey" style={{ whiteSpace: "initial" }}>
                {`${searchParams.get("paymentKey")}`}
              </div>
            </div>
            
            <div className="p-grid-col" style={{display:"none"}}>
              <Link to="https://docs.tosspayments.com/guides/v2/payment-widget/integration">
                <button className="button p-grid-col5">연동 문서</button>
              </Link>
            </div>
        </div>
        <div className="box_section" style={{ textAlign: "center" , display:"none" }}>
          <b>Response Data :</b>
          <div id="response" style={{ whiteSpace: "initial" }}>
            {responseData && <pre>{JSON.stringify(responseData, null, 4)}</pre>}
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
      
  );
}
export default BuySuccess;