import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";


const ProjectManage2:FC<any> = (  projectDataMap  ) => {

    // console.log('projectDataMap 리턴 :', JSON.stringify(projectDataMap.projectCntDataMap.project , null, 2));

     const projectCnt = (projectDataMap  && projectDataMap.projectCntDataMap && projectDataMap.projectCntDataMap.project.current ? projectDataMap.projectCntDataMap.project.current : 0);
     const assetCnt   = (projectDataMap  && projectDataMap.projectCntDataMap && projectDataMap.projectCntDataMap.asset ? Number(projectDataMap.projectCntDataMap.asset.progress) + Number(projectDataMap.projectCntDataMap.asset.done) : 0);

    // const projectCnt = 0;
    // const assetCnt   = 0;
    return (
        <ProjectSections title="참여중인 프로젝트" className="col-9 col-lg-8 col-md-12 user-project" >
            <div className="user-project-list">
                <div className="project-item">
                    <div className="project-item-title">프로젝트 개수</div>
                    <div className="project-item-count">{projectCnt}</div>
                </div>
                <div className="project-item">
                    <div className="project-item-title">에셋 개수</div>
                    <div className="project-item-count">{assetCnt ? assetCnt : 0}</div>
                </div>
            </div>
        </ProjectSections>
    )
}
export default ProjectManage2;
