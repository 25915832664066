import { FC } from "react";
import Menu from "./Menu";
import RelatedSites from "./RelatedSites";
import "../../styles/layout/footer.scss";

interface FooterContainer {
    isLoggedIn: boolean;
}

const FooterContainer: FC<FooterContainer> = ({ isLoggedIn }) => {
    return (
        <footer id="footer" className="footer">
            <div className="footer--menu">
                <div className="row">
                    <div className="container">
                        <Menu />
                        {isLoggedIn ? <RelatedSites /> : null}
                    </div>
                </div>
            </div>
            <div className="footer--end">
                <div className="row">
                    <div className="container">
                        <div className="footer--info">
                            <div className="footer--address">
                                <p>[서울] 서울특별시 금천구 가산디지털1로 145</p>
                                <div>
                                    <span>TEL. 02-855-5724</span>
                                    <span>FAX. 02-857-574</span>
                                </div>
                            </div>
                            <div className="footer--logos">
                                <a href="https://all4land.com/" target="manager-window" title="새창 열림" rel="noopener noreferrer"><img src="/images/logos/all4land@154x52.png" alt="Allforland" /></a>
                                <a href="https://waapi.info-seed.com/" target="manager-window" title="새창 열림" rel="noopener noreferrer"><img src="/images/logos/infoseed@129x52.png" alt="Allforland" /></a>
                                <a href="https://neospectra.co.kr/" target="manager-window" title="새창 열림" rel="noopener noreferrer"><img src="/images/logos/neo@186x52.png" alt="Allforland" /></a>
                            </div>
                        </div>
                        <p className="copyright">© ALLFORLAND, All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterContainer;