import { FC } from 'react';
import '../styles/components/paging.scss';
import { PotalCommDto } from '../types/PotalCommDto';

interface SearchFormProps {
    pageingDto : PotalCommDto;
    onClick: (value: number) => void; // onChange prop 추가
}

const PagingButton:FC<SearchFormProps> =({ pageingDto , onClick }) => {
    
    const handleClick = (pageNo: number) => {
        onClick(pageNo); // 선택된 값 전달
    };

    return (
      
      <div className="mb30 flex justify-center">
         {Number(pageingDto.lastPageNo ) > Number(pageingDto.currentPageNo ) ? ( 
            <button type="button" value="1" className="button button--outline-black button--sm" onClick={() => handleClick(Number(pageingDto.currentPageNo)+1)}>결제이력 더 보기</button>
         ):<span></span>}
      </div>
      
   )};

export default PagingButton;