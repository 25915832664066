import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Buttons from '../../components/Buttons';
import TabComponent from '../../components/Tab';
import Pagination from '../../components/paging';
import config from '../../config';
import Banners from "../../containers/banner";
import { RootState } from '../../modules';
import { getInfoseedGalleryAsync, getMapprimeGalleryAsync, getNeospectraGalleryAsync } from '../../modules/proj';
import { PotalCommDto } from '../../types/PotalCommDto';
import { InfoseedGalleryApiResponse, SelectInfoseedGalleryDto, SelectMapprimeGalleryDto, SelectNeospectraGalleryDto } from '../../types/ProjectsDto';
import './boardlist.scss';
import { openMapprimePage, openNeospectraPage } from '../common/openSiteUtil';
import { openInfoPage } from '../common/stringUtil';

interface TabProbs {
    title: string;
    count?: number;
    controls?: string;
    id: number;
}

interface PageProps {
    title: string;
}

enum categoryEnum {
    "전체", "프로젝트", "배포 맵"
}

const ProjectList: FC<PageProps> = ({ title }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    const infoseedList = useSelector((state: RootState) => state.project.infoseed);
    const neospectraList = useSelector((state: RootState) => state.project.neospectra);
    const mapprimeList = useSelector((state: RootState) => state.project.mapprime);
    const [tabs, setTabs] = useState<TabProbs[]>([
        // { id: 1, title: '전체', count: 0 },
        { id: 2, title: '영상촬영', count: 0 },
        { id: 3, title: 'AI 데이터 생성관리', count: 0 },
        { id: 4, title: '디지털트윈 지도', count: 0 },
    ]);

    const [infoseedPageingDto, setInfoseedPageing] = useState<PotalCommDto>({});
    const [neospectraPageingDto, setNeospectraPageing] = useState<PotalCommDto>({});
    const [mapprimePageingDto, setMapprimePageing] = useState<PotalCommDto>({});

    const [selectInfoseedGalleryDto, setSelectInfoseedGalleryDto] = useState<SelectInfoseedGalleryDto>({
        category: "",
        keyword: "",
        page: 1,
        size: 12,
    });

    const [selectNeospectraGalleryDto, setSelectNeospectraGalleryDto] = useState<SelectNeospectraGalleryDto>({
        page: 1,
        size: 12,
        search: "",
    });

    const [selectMapprimeGalleryDto, setSelectMapprimeGalleryDto] = useState<SelectMapprimeGalleryDto>({
        keyword: "",
        align: "modifyDate",
        page: 1,
        limit: 12,
    });

    // 탭 초기 상태를 설정합니다.
    const [currentTab, setCurrentTab] = useState<number>(() => {
        // 쿼리 파라미터로 전달된 tab 값이 있으면 해당 값으로 설정하고, 없으면 기본값 사용
        const initialTab = tabParam ? parseInt(tabParam, 10) : tabs[0].id;
        return initialTab;
    });

    const onSearch = async (pageNo: number) => {
        setSelectInfoseedGalleryDto((prev) => ({
            ...prev,
            page: pageNo,
        }));
        setSelectNeospectraGalleryDto((prev) => ({
            ...prev,
            page: pageNo,
        }));
        setSelectMapprimeGalleryDto((prev) => ({
            ...prev,
            page: pageNo,
        }));
        dispatch(getInfoseedGalleryAsync.request({
            dto: {
                ...selectInfoseedGalleryDto,
                page: pageNo, // 페이지 번호 반영
            }
        }));
        dispatch(getNeospectraGalleryAsync.request({
            dto: {
                ...selectNeospectraGalleryDto,
                page: pageNo, // 페이지 번호 반영
            }
        }));
        dispatch(getMapprimeGalleryAsync.request({
            dto: {
                ...selectMapprimeGalleryDto,
                page: pageNo, // 페이지 번호 반영
            }
        }));
    }

    const handleKeyword = (keyword: string) => {
        setSelectInfoseedGalleryDto((prev) => ({
            ...prev,
            keyword: keyword,
        }));
        setSelectNeospectraGalleryDto((prev) => ({
            ...prev,
            search: keyword,
        }));
        setSelectMapprimeGalleryDto((prev) => ({
            ...prev,
            keyword: keyword,
        }));
    };

    // 검색 폼 제출 시 처리
    const onSubmitSearch = (e: FormEvent) => {
        e.preventDefault();
        dispatch(getInfoseedGalleryAsync.request({
            dto: selectInfoseedGalleryDto,
        }));
        dispatch(getNeospectraGalleryAsync.request({
            dto: selectNeospectraGalleryDto,
        }));
        dispatch(getMapprimeGalleryAsync.request({
            dto: selectMapprimeGalleryDto,
        }));
    };

    const handleTabClick = (index: number, tab: TabProbs) => {
        setCurrentTab(tab.id); // 클릭한 탭의 id로 상태를 업데이트
    };

    const handleLimitChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newLimit = parseInt(e.target.value, 10); // 선택된 값을 정수로 변환

        // 상태 업데이트

        setSelectInfoseedGalleryDto((prev) => ({
            ...prev,
            size: newLimit, // limit 값을 업데이트
            page: 1, // 페이지 번호를 1로 초기화
        }));

        setSelectNeospectraGalleryDto((prev) => ({
            ...prev,
            size: newLimit, // limit 값을 업데이트
            page: 1, // 페이지 번호를 1로 초기화
        }));

        setSelectMapprimeGalleryDto((prev) => ({
            ...prev,
            limit: newLimit, // limit 값을 업데이트
            page: 1, // 페이지 번호를 1로 초기화
        }));

        // 상태 업데이트 후 바로 API 요청을 보냄
        dispatch(getInfoseedGalleryAsync.request({
            dto: {
                ...selectInfoseedGalleryDto,
                size: newLimit,
                page: 1, // 페이지를 1로 리셋
            }
        }));

        dispatch(getNeospectraGalleryAsync.request({
            dto: {
                ...selectNeospectraGalleryDto,
                size: newLimit,
                page: 1, // 페이지를 1로 리셋
            }
        }));

        dispatch(getMapprimeGalleryAsync.request({
            dto: {
                ...selectMapprimeGalleryDto,
                limit: newLimit,
                page: 1, // 페이지를 1로 리셋
            }
        }));
    };

    useEffect(() => {
        if (tabParam) {
            setCurrentTab(parseInt(tabParam, 10));
        }
    }, [tabParam]);

    useEffect(() => {
        if(sessionStorage.getItem("isInfoseedSubscribe")){
            dispatch(getInfoseedGalleryAsync.request({
                dto: selectInfoseedGalleryDto
            }));
        }
        if(sessionStorage.getItem("isNeospectraSubscribe")){
            dispatch(getNeospectraGalleryAsync.request({
                dto: selectNeospectraGalleryDto
            }));
        }
        if(sessionStorage.getItem("isMapprimeSubscribe")){
            dispatch(getMapprimeGalleryAsync.request({
                dto: selectMapprimeGalleryDto
            }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (infoseedList?.data?.data) {
            setTabs((prevTabs) =>
                prevTabs.map((tab) =>
                    tab.id === 2
                        ? { ...tab, count: infoseedList?.data?.total }
                        : tab
                )
            );

            // 인포시드 페이징
            const totalCount = infoseedList.data.total;       // 총 프로젝트 개수
            const totalIndexCount = infoseedList.data.total / infoseedList.data.size;   // 총 페이지 수?
            const pageUnit = selectInfoseedGalleryDto.size!;  // 페이지에 보일 프로젝트 수
            const pageSize = Math.ceil(totalCount / pageUnit);  // 
            const firstPageNo = (Math.floor((infoseedList.data.page - 1) / pageSize) * pageSize) + 1;
            const currentPageNo = selectInfoseedGalleryDto.page!;
            let lastPageNo = 1;
            let prevPageNo = firstPageNo - 1;

            if (Math.floor(totalCount / pageSize) == Math.floor(currentPageNo / pageSize)) {
                lastPageNo = totalCount;
            } else {
                lastPageNo = firstPageNo + pageSize - 1;
            }

            let nextPageNo = lastPageNo + 1;

            if (nextPageNo > totalIndexCount) {
                nextPageNo = 0;
            }
            setInfoseedPageing({
                currentPageNo: currentPageNo,
                firstPageNo: firstPageNo,
                lastPageNo: lastPageNo,
                prevPageNo: prevPageNo,
                nextPageNo: nextPageNo,
                totalIndexCount: totalIndexCount
            })
        }
    }, [infoseedList]);

    useEffect(() => {
        if (neospectraList?.data?.data) {
            setTabs((prevTabs) =>
                prevTabs.map((tab) =>
                    tab.id === 3
                        ? { ...tab, count: neospectraList?.data?.total }
                        : tab
                )
            );

            // 네오스펙트라 페이징
            const totalCount = neospectraList.data.total;       // 총 프로젝트 개수
            const totalIndexCount = neospectraList.data.total / neospectraList.data.size;   // 총 페이지 수?
            const pageUnit = selectNeospectraGalleryDto.size!;  // 페이지에 보일 프로젝트 수
            const pageSize = Math.ceil(totalCount / pageUnit);  // 
            const firstPageNo = (Math.floor((neospectraList.data.page - 1) / pageSize) * pageSize) + 1;
            const currentPageNo = selectNeospectraGalleryDto.page!;
            let lastPageNo = 1;
            let prevPageNo = firstPageNo - 1;

            if (Math.floor(totalCount / pageSize) == Math.floor(currentPageNo / pageSize)) {
                lastPageNo = totalCount;
            } else {
                lastPageNo = firstPageNo + pageSize - 1;
            }

            let nextPageNo = lastPageNo + 1;

            if (nextPageNo > totalIndexCount) {
                nextPageNo = 0;
            }
            setNeospectraPageing({
                currentPageNo: currentPageNo,
                firstPageNo: firstPageNo,
                lastPageNo: lastPageNo,
                prevPageNo: prevPageNo,
                nextPageNo: nextPageNo,
                totalIndexCount: totalIndexCount
            })
        }
    }, [neospectraList]);

    useEffect(() => {
        if (mapprimeList?.data?.data) {
            setTabs((prevTabs) =>
                prevTabs.map((tab) =>
                    tab.id === 4
                        ? { ...tab, count: mapprimeList?.data?.count?.[categoryEnum.전체]?.count }
                        : tab
                )
            );

            // 맵프라임 페이징
            const totalCount = mapprimeList.data.count[categoryEnum.전체].count;        // 총 프로젝트 수
            const totalIndexCount = mapprimeList.data.page[categoryEnum.전체].page;     // 총 페이지 수
            const pageUnit = mapprimeList.data.query.limit;                             // 한 페이지에 보기는 수
            const pageSize = Math.ceil(totalCount / pageUnit);                          // 총 페이지 수
            const firstPageNo = (Math.floor((mapprimeList.data.page[categoryEnum.전체].page - 1) / pageSize) * pageSize) + 1;
            const currentPageNo = mapprimeList.data.query.page;                         // 현재 페이지 번호
            let lastPageNo = 1;
            let prevPageNo = firstPageNo - 1;


            if (Math.floor(totalCount / pageSize) == Math.floor(currentPageNo / pageSize)) {
                lastPageNo = totalCount;
            } else {
                lastPageNo = firstPageNo + pageSize - 1;
            }

            let nextPageNo = lastPageNo + 1;

            if (nextPageNo > totalIndexCount) {
                nextPageNo = 0;
            }
            setMapprimePageing({
                currentPageNo: currentPageNo,
                firstPageNo: firstPageNo,
                lastPageNo: lastPageNo,
                prevPageNo: prevPageNo,
                nextPageNo: nextPageNo,
                totalIndexCount: totalIndexCount
            })
        }
    }, [mapprimeList]);

    return (
        <div className='board'>
            <Banners title={title} />
            <div className="row">
                <div className="container">
                    <TabComponent tabs={tabs} current={currentTab} onClick={handleTabClick} />
                    {/* tabmenu */}
                    <div className='form-search'>
                        <form className='form-fieldset' onSubmit={onSubmitSearch}>
                            <fieldset>
                                <legend className='sr-only'>프로젝트 검색</legend>
                                <div className="search-input">
                                    {/* <select name="{objectId}" className='form-select' title='분류 선택'>
                                                <option value="">전체</option>
                                                <option value="RECONSTRUCTION">복원</option>
                                                <option value="INFERENCE">추론</option>
                                            </select> */}
                                    <input
                                        type="text"
                                        id="searchKeyword"
                                        name="searchKeyword"
                                        className='form-control'
                                        title='프로젝트 명 입력'
                                        placeholder='프로젝트 명을 입력하세요'
                                        value={selectMapprimeGalleryDto.keyword}
                                        onChange={(e) => handleKeyword(e.target.value)}
                                    />
                                    <Buttons text='검색'
                                        variant='primary'
                                        size='sm'
                                        type='submit'
                                    />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    {currentTab === 2 && (
                        <>
                            <div className={`board-controls`}>
                                <span className='board-display'>
                                    <span>총 게시물</span>
                                    <em>({infoseedList?.data?.data?.ITEMS.length || 0}건</em>/{infoseedList?.data?.data?.total || 0}건)</span>
                                <div className='board-sort'>
                                    <select
                                        id="pageUnit"
                                        name="pageUnit"
                                        className='form-select'
                                        title='게시판 목록 수 선택'
                                        value={selectMapprimeGalleryDto.limit}
                                        onChange={handleLimitChange}
                                    >
                                        <option value="12">12개씩 보기</option>
                                        <option value="24">24개씩 보기</option>
                                        <option value="36">36개씩 보기</option>
                                        <option value="48">48개씩 보기</option>
                                        <option value="60">60개씩 보기</option>
                                    </select>
                                </div>
                            </div>
                            {infoseedList?.data?.data?.ITEMS && infoseedList?.data?.data?.ITEMS.length > 0 ? (
                                <div className='board-list'>
                                    <ul className="gall">
                                        {infoseedList?.data?.data?.ITEMS.map((project: InfoseedGalleryApiResponse["data"]["ITEMS"][0], index: number) => (
                                            <li className="gall-item" key={project.PRJ_NID || index}>
                                                <figure>
                                                    <div className="thumbnail no-img">
                                                        <a
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                openInfoPage("P001");
                                                            }}
                                                        >
                                                            <img src={`data:image/png;base64,${project.IMG_URI}`} alt="" />
                                                        </a>
                                                        <span className="cate">
                                                            {
                                                                project.PRJ_CLS_CD === "L001M001S001"
                                                                    ? "현장사진"
                                                                    : project.PRJ_CLS_CD === "L001M001S002"
                                                                        ? "비행촬영"
                                                                        : ""
                                                            }
                                                        </span>
                                                    </div>

                                                    <figcaption className='caption'>
                                                        <div className='desc'>
                                                            <p>
                                                            </p>
                                                            <p>
                                                                {
                                                                    project.PRG_ST_CD === "L001M002S002"
                                                                        ? "계획 수립"
                                                                        : project.PRG_ST_CD === "L001M002S003"
                                                                            ? "촬영중"
                                                                            : project.PRG_ST_CD === "L001M002S008"
                                                                                ? "완료"
                                                                                : project.PRG_ST_CD === null
                                                                                ? "null"
                                                                                : "일치하는 값 없음"
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className='desc'>
                                                            <p>기간 : {`${new Date(project.PRJ_SDT).toLocaleDateString()}~${new Date(project.PRJ_EDT).toLocaleDateString()}` || 'N/A'}</p>
                                                        </div>
                                                        <h3>{project.PRJ_NM}</h3>
                                                        <div className='desc2'>
                                                            <p>생성일 : {new Date(project.CRE_DD).toLocaleDateString()}</p>
                                                        </div>
                                                    </figcaption>

                                                </figure>
                                            </li>
                                        ))}
                                    </ul>
                                    <Pagination pageingDto={infoseedPageingDto} onClick={onSearch} />
                                </div>
                            ) : (
                                <>
                                    <div className="cm-no-data">
                                        <div className="cm-no-data-wrap">
                                            <i className="cs-icon-warning_gray"></i>
                                            <div>등록된 프로젝트가 없습니다</div>
                                        </div>
                                    </div>
                                    {sessionStorage.getItem("isInfoseedSubscribe") ? 
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '2rem'
                                        }}
                                    >
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openInfoPage("P001");
                                            }}
                                            style={{
                                                backgroundColor: '#242e42',
                                                padding: '2rem 3.5rem 2rem 5rem',
                                                color: '#fff',
                                                fontSize: '1.8rem',
                                                borderRadius: '1.2rem',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '0 1rem'
                                            }}
                                        >
                                            서비스로 이동
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#e8eaed"><path d="m256-240-56-56 384-384H240v-80h480v480h-80v-344L256-240Z" /></svg>
                                        </a>
                                    </div>
                                    : <div></div>
                                    }
                                </>
                            )}
                        </>
                    )}
                    {currentTab === 3 && (
                        <>
                            <div className={`board-controls`}>
                                <span className='board-display'>
                                    <span>총 게시물</span>
                                    <em>({neospectraList?.data?.data?.length || 0}건</em>/{neospectraList?.data?.total || 0}건)</span>
                                <div className='board-sort'>
                                    <select
                                        id="pageUnit"
                                        name="pageUnit"
                                        className='form-select'
                                        title='게시판 목록 수 선택'
                                        value={selectMapprimeGalleryDto.limit}
                                        onChange={handleLimitChange}
                                    >
                                        <option value="12">12개씩 보기</option>
                                        <option value="24">24개씩 보기</option>
                                        <option value="36">36개씩 보기</option>
                                        <option value="48">48개씩 보기</option>
                                        <option value="60">60개씩 보기</option>
                                    </select>
                                </div>
                            </div>

                            {neospectraList?.data?.data?.length > 0 ? (
                                <div className='board-list'>
                                    <ul className="gall">
                                        {neospectraList?.data?.data?.map((project: any, index: number) => (
                                            <li className="gall-item" key={project.id || index}>
                                                <figure>
                                                    <div className="thumbnail no-img">
                                                        <a
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                const form = document.createElement('form');
                                                                form.method = 'POST';
                                                                form.action = `${config.neospectraApiUrl}/redirect/${config.neospectraApiVer}/`; // action URL 설정
                                                                form.target = "manager-window";

                                                                // access_token
                                                                const access_token = document.createElement('input');
                                                                access_token.type = 'hidden';
                                                                access_token.name = 'access_token';
                                                                access_token.value = sessionStorage.getItem('access_token') || ''; // 적절한 토큰 값을 사용하세요
                                                                form.appendChild(access_token);

                                                                // refresh_token
                                                                const refresh_token = document.createElement('input');
                                                                refresh_token.type = 'hidden';
                                                                refresh_token.name = 'refresh_token';
                                                                refresh_token.value = sessionStorage.getItem('refresh_token') || ''; // 적절한 토큰 값을 사용하세요
                                                                form.appendChild(refresh_token);

                                                                // target
                                                                const target = document.createElement('input');
                                                                target.type = 'hidden';
                                                                target.name = 'target';
                                                                target.value = 'project'; // 적절한 토큰 값을 사용하세요
                                                                form.appendChild(target);

                                                                // project_id
                                                                const project_id = document.createElement('input');
                                                                project_id.type = 'hidden';
                                                                project_id.name = 'project_id';
                                                                project_id.value = project.id; // 적절한 토큰 값을 사용하세요
                                                                form.appendChild(project_id);

                                                                // 폼을 body에 추가하고 제출
                                                                document.body.appendChild(form);
                                                                form.submit();

                                                                // 폼 제거 (더 이상 필요하지 않음)
                                                                document.body.removeChild(form);
                                                            }}
                                                        >
                                                            <img src={project.thumbnail} alt="" />
                                                        </a>
                                                        <span className="cate">{project.medium_category_name}</span>
                                                    </div>

                                                    <figcaption className='caption'>
                                                        <div className='desc'>
                                                            <p>작업 갯수 : {project.job_count || 'N/A'}</p>
                                                        </div>
                                                        <h3>{project.name}</h3>
                                                        <div className='desc2'>
                                                            <p>생성일 : {new Date(project.created_at).toLocaleDateString()}</p>
                                                        </div>
                                                    </figcaption>

                                                </figure>
                                            </li>
                                        ))}
                                    </ul>
                                    <Pagination pageingDto={neospectraPageingDto} onClick={onSearch} />
                                </div>
                            ) : (
                                <>
                                    <div className="cm-no-data">
                                        <div className="cm-no-data-wrap">
                                            <i className="cs-icon-warning_gray"></i>
                                            <div>등록된 프로젝트가 없습니다</div>
                                        </div>
                                    </div>
                                    {sessionStorage.getItem("isNeospectraSubscribe") ?
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '2rem'
                                        }}
                                    >
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openNeospectraPage();
                                            }}
                                            style={{
                                                backgroundColor: '#242e42',
                                                padding: '2rem 3.5rem 2rem 5rem',
                                                color: '#fff',
                                                fontSize: '1.8rem',
                                                borderRadius: '1.2rem',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '0 1rem'
                                            }}
                                        >
                                            서비스로 이동
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#e8eaed"><path d="m256-240-56-56 384-384H240v-80h480v480h-80v-344L256-240Z" /></svg>
                                        </a>
                                    </div>
                                    : <div></div>
                                    }
                                </>
                            )}
                        </>
                    )}
                    {currentTab === 4 && (
                        <>
                            <div className={`board-controls`}>
                                <span className='board-display'>
                                    <span>총 게시물</span>
                                    <em>({mapprimeList?.data?.data?.length || 0}건</em>/{mapprimeList?.data?.count?.[categoryEnum.전체]?.count || 0}건)</span>
                                <div className='board-sort'>
                                    <select
                                        id="pageUnit"
                                        name="pageUnit"
                                        className='form-select'
                                        title='게시판 목록 수 선택'
                                        value={selectMapprimeGalleryDto.limit}
                                        onChange={handleLimitChange}
                                    >
                                        <option value="12">12개씩 보기</option>
                                        <option value="24">24개씩 보기</option>
                                        <option value="36">36개씩 보기</option>
                                        <option value="48">48개씩 보기</option>
                                        <option value="60">60개씩 보기</option>
                                    </select>
                                </div>
                            </div>

                            {mapprimeList.data?.data?.length > 0 ? (

                                <div className='board-list'>
                                    <ul className="gall">
                                        {mapprimeList?.data?.data?.map((project: any, index: number) => (
                                            <li className="gall-item" key={project.id || index}>
                                                <figure>
                                                    <div className="thumbnail">
                                                        <a
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                const form = document.createElement('form');
                                                                form.method = 'POST';
                                                                form.action = `${config.mapprimeApiUrl}/${config.mapprimeApiVer}/gate-m`; // action URL 설정
                                                                form.target = "manager-window";

                                                                // id 변수 추가
                                                                const idField = document.createElement('input');
                                                                idField.type = 'hidden';
                                                                idField.name = 'project_id';
                                                                idField.value = project.id; // 전달할 id 값을 적절하게 설정하세요
                                                                form.appendChild(idField);

                                                                // 필요한 hidden input 생성 (예: 토큰)
                                                                const accessField = document.createElement('input');
                                                                accessField.type = 'hidden';
                                                                accessField.name = 't';
                                                                accessField.value = sessionStorage.getItem('access_token') || ''; // 적절한 토큰 값을 사용하세요
                                                                form.appendChild(accessField);

                                                                // 필요한 hidden input 생성 (예: 토큰)
                                                                const refreshField = document.createElement('input');
                                                                refreshField.type = 'hidden';
                                                                refreshField.name = 'r';
                                                                refreshField.value = sessionStorage.getItem('refresh_token') || ''; // 적절한 토큰 값을 사용하세요
                                                                form.appendChild(refreshField);

                                                                // 폼을 body에 추가하고 제출
                                                                document.body.appendChild(form);
                                                                form.submit();

                                                                // 폼 제거 (더 이상 필요하지 않음)
                                                                document.body.removeChild(form);
                                                            }}
                                                        >  {/*프로젝트 URL로 변경해야함*/}
                                                            <img src={`https://kftwihqemy.apigw.gov-ntruss.com/mpapi/srvgw/v1/data/thumb/${project.id}`} alt="" />
                                                        </a>
                                                        <span className="cate">{project.category}</span>
                                                    </div>

                                                    <figcaption className='caption'>
                                                        <div className='desc'>
                                                            <p>지역 : {project.tags?.[0] || 'N/A'}</p>
                                                        </div>
                                                        {/* <i className="new-ico"></i> */}
                                                        <h3>{project.name}</h3>
                                                        <div className='desc2'>
                                                            <p>생성일 : {new Date(project.registerDate).toLocaleDateString()}</p>
                                                        </div>

                                                        {/* <button
                                                        type='button'
                                                        className='button-favorites'
                                                        aria-pressed='true'
                                                        title='즐겨찾기 등록'
                                                        >
                                                        <i className='icon--star' aria-hidden='true'></i>
                                                        </button> */}
                                                    </figcaption>
                                                </figure>
                                            </li>
                                        ))}
                                    </ul>
                                    {/* gall */}
                                    <Pagination pageingDto={mapprimePageingDto} onClick={onSearch} />
                                </div>
                            ) : (
                                <>
                                    <div className="cm-no-data">
                                        <div className="cm-no-data-wrap">
                                            <i className="cs-icon-warning_gray"></i>
                                            <div>등록된 프로젝트가 없습니다</div>
                                        </div>
                                    </div>
                                    {sessionStorage.getItem("isMapprimeSubscribe") ?
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '2rem'
                                        }}
                                    >
                                        <a
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openMapprimePage();
                                            }}
                                            style={{
                                                backgroundColor: '#242e42',
                                                padding: '2rem 3.5rem 2rem 5rem',
                                                color: '#fff',
                                                fontSize: '1.8rem',
                                                borderRadius: '1.2rem',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '0 1rem'
                                            }}
                                        >
                                            서비스로 이동
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#e8eaed"><path d="m256-240-56-56 384-384H240v-80h480v480h-80v-344L256-240Z" /></svg>
                                        </a>
                                    </div>
                                    : <div></div>
                                    }
                                </>
                            )}
                        </>
                    )}
                </div>
                {/* container */}
            </div>
        </div>
    )
}

export default ProjectList;