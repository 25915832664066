import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import { NeospectraGalleryApiResponse, SelectInfoseedGalleryDto, SelectMapprimeGalleryDto, SelectNeospectraGalleryDto } from '../../types/ProjectsDto';

export const GET_MAPPRIME_GALLERY = 'api/GET_MAPPRIME_GALLERY'
export const GET_MAPPRIME_GALLERY_SUCCESS = 'api/GET_MAPPRIME_GALLERY_SUCCESS'
export const GET_MAPPRIME_GALLERY_ERROR = 'api/GET_MAPPRIME_GALLERY_ERROR'
export const GET_NEOSPECTRA_GALLERY = 'api/GET_NEOSPECTRA_GALLERY'
export const GET_NEOSPECTRA_GALLERY_SUCCESS = 'api/GET_NEOSPECTRA_GALLERY_SUCCESS'
export const GET_NEOSPECTRA_GALLERY_ERROR = 'api/GET_NEOSPECTRA_GALLERY_ERROR'
export const GET_INFOSEED_GALLERY = 'api/GET_INFOSEED_GALLERY'
export const GET_INFOSEED_GALLERY_SUCCESS = 'api/GET_INFOSEED_GALLERY_SUCCESS'
export const GET_INFOSEED_GALLERY_ERROR = 'api/GET_INFOSEED_GALLERY_ERROR'

export const getMapprimeGalleryAsync = createAsyncAction(
  GET_MAPPRIME_GALLERY,
  GET_MAPPRIME_GALLERY_SUCCESS,
  GET_MAPPRIME_GALLERY_ERROR
)<{dto: SelectMapprimeGalleryDto}, any, AxiosError>();

export const getNeospectraGalleryAsync = createAsyncAction(
  GET_NEOSPECTRA_GALLERY,
  GET_NEOSPECTRA_GALLERY_SUCCESS,
  GET_NEOSPECTRA_GALLERY_ERROR
)<{dto: SelectNeospectraGalleryDto}, any, AxiosError>();

export const getInfoseedGalleryAsync = createAsyncAction(
  GET_INFOSEED_GALLERY,
  GET_INFOSEED_GALLERY_SUCCESS,
  GET_INFOSEED_GALLERY_ERROR
)<{dto: SelectInfoseedGalleryDto}, any, AxiosError>();