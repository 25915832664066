import { FC, useState} from "react";
import "../../styles/layout/header-utils.scss";

interface Alert {
    id: number;
    label: string;
    count: number;
}
const dummyAlerts: Alert[] = [
    { id: 1, label: '초대 승인', count: 3 },
    { id: 2, label: '파일 변환', count: 5 },
];

const HeaderAlert:FC = () => {
    const [alerts] = useState<Alert[]>(dummyAlerts);
    const totalCount = alerts.reduce((total, alert) => total + alert.count, 0);

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleLinks = () => {
        setIsExpanded(!isExpanded);
    };
    return(
        <div className="header--alert">
            {/* <button type="button"
            onClick={ toggleLinks }
            title={isExpanded ? '목록 닫힘' : '목록 열림'}
            aria-expanded={isExpanded}>
            <span className="sr-only">사용자 알림</span>
            <i aria-hidden="true"></i>
            <em className="alert--count">{totalCount}</em>
            </button>
            <div className="header--alert--detail -toolbox">
                {alerts.map((alert) => (
                    <div key={alert.id} className="header--alert--item">
                        <div>
                            <div className="alert-label">{alert.label}</div>
                            <div className="alert-count"><strong>{alert.count}</strong>건</div>
                        </div>
                    </div>
                ))}
            </div> */}
        </div>
    )
}

export default HeaderAlert;