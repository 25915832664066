import { FC } from 'react';
import './MypageTitle.scss';

interface MypageTitleProps{
    pageTitle : string;
}

const MypageTitle:FC<MypageTitleProps> = ({pageTitle}) =>{
    return (
        <header className="mypage-title">
            <h3>{pageTitle}</h3>
        </header>
    )
}

export default MypageTitle;