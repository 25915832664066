import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import BarChart from "../../components/BarChart";
import { getBytesToSizeNumber } from "../../pages/common/stringUtil";

type props = {
    projectDataMap: any[];
};

const ProjectManage4:FC<props> = ({  projectDataMap }) => {
    const width = "100%";
    const height = 600;

    const projectDat : {name:string , value:number}[] = [];

    projectDataMap.forEach((item,index) => {
        const project = {name : item.name , value : getBytesToSizeNumber(item.size) }    
        projectDat.push(project);
    });
       


    return (
        <ProjectSections title="프로젝트 별 데이터 현황" className="col-6 col-lg-6 col-md-12 user-porject-data" >
            <div className="">
                <BarChart width={width} height={projectDat.length == 0 ? 0 : height} data={projectDat} />
            </div>
        </ProjectSections>
    )
}
export default ProjectManage4;
