import { FC } from 'react';
import './index.scss';
import MainSection1 from './MainSection1';
import MainSection2 from './MainSection2';
import MainSection3 from './MainSection3';
import MainSection4 from './MainSection4';
import MainSection5 from './MainSection5';

interface Mainpage2Props {
    isLoggedIn: boolean;
}

const Mainpage:FC<Mainpage2Props> = ({isLoggedIn}) => {
    return (
        <main id="main" className='main'>
            <MainSection1 />
            <MainSection2 isLoggedIn={isLoggedIn} />
            <MainSection3 />
            <MainSection4 />
            <MainSection5 />
        </main>
    )
}

export default Mainpage;
