import { createReducer } from 'typesafe-actions';
import { CommonState, CommonAction } from './types';
import { getCommCdListAsync , deleteFileAsync  } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';
import { transform } from 'typescript';
import { AnyAction } from 'redux';

const initialState: CommonState = {
  data: asyncState.initial(),
  delFile:asyncState.initial()
};
const common = createReducer<CommonState, AnyAction>(initialState)
.handleAction(
  transformToArray(getCommCdListAsync),
  createAsyncReducer(getCommCdListAsync, 'data')
)
.handleAction(
  transformToArray(deleteFileAsync),
  createAsyncReducer(deleteFileAsync, 'delFile')
)
;


export default common;