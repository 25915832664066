import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banners from "../../containers/banner";
import Title from '../../components/Titles';
import Buttons from '../../components/Buttons';
import '../../styles/components/input.scss';
import '../../styles/components/icon.scss';
import './forgot.scss';
import { useDispatch, useSelector } from "react-redux";
import { postFindUserIdAsync } from "../../modules/authuser";
import { RootState } from "../../modules";
import { UserDto } from "../../types/UserDto";

interface PageProps {
    title: string;
}
const ForgotId: FC<PageProps> = ({ title }) => {
    const [username, setUsername] = useState(''); // 선택된 이메일 도메인 상태
    const [email, setEmail] = useState(''); // 선택된 이메일 도메인 상태
    const [emailDomain, setEmailDomain] = useState(''); // 선택된 이메일 도메인 상태
    const [customDomain, setCustomDomain] = useState(''); // 직접 입력한 이메일 도메인 상태
    const dispatch = useDispatch();

    const userId = useSelector((state: RootState) => state.auth.data.data);

    const handleEmailDomainChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setEmailDomain(e.target.value);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        dispatch(postFindUserIdAsync.request({ name: username, email: email }));
    };

    return (
        <>
            <Banners title={title} />
            <div className="forgot">
                <div className="row">
                    <div className="container">
                        <Title title="영상정보 관리 및 분석 서비스 아이디 찾기" subtitle="가입시 입력하신 이메일로 가입여부를 확인합니다" />
                        <div className="small-wraps">
                            {userId ? (
                                <div className="forgot-result">
                                    <p>회원님의 아이디는 <br></br><b>{userId}</b> 입니다.</p>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            <form className="forgot-form" onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend className="sr-only">회원 아이디 찾기</legend>
                                    <div className="form-group">
                                        <label htmlFor="userName" className="label">이름</label>
                                        <input
                                            type="text"
                                            id="userName"
                                            className="form-control block"
                                            placeholder="이름을 입력해 주세요."
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userEmail" className='label'>이메일</label>
                                        <div className="form-email">
                                            <input type="text" id="userEmail" className="form-control block" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email@example.com" required />
                                        </div>
                                    </div>
                                    <Buttons type='submit' text="확인" variant="primary" size="lg" block />
                                    <div className="login-util end">
                                        <Link to={'/register'} className='forgot-link'>회원가입 <i className="icon--arwright-black"></i></Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotId;