import React from 'react';
import '../styles/components/LoadingSpinner.scss';

const LoadingSpinner: React.FC = () => {
  return (
    <div className="loading-spinner-overlay">
      <div className="loading-spinner" />
      <p>잠시만 기다려 주세요...</p>
    </div>
  );
};

export default LoadingSpinner;
