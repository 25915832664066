import { FC, ReactNode} from 'react';

interface HeaderUtilContainerProps{
    children: ReactNode;
}

const HeaderUtilContainer: FC<HeaderUtilContainerProps> = ({ children }) => {
    return(
        <div className="header__util">
            {children}
        </div>
    )
}
export default HeaderUtilContainer;