import { DELETE_SLTN, GET_SLTN_BY_ID, GET_SLTN_LIST, POST_CHECK_SLTN_ID, POST_CREATE_SLTN, PUT_UPDATE_SLTN, deleteSltnAsync, getSltnByIdAsync, getSltnListAsync, postCheckSltnIdAsync, postCreateSltnAsync, putUpdateSltnAsync } from './actions';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { createSltn, deleteSltn, getSltnById, getSltnList, postCheckSltnId, updateSltn } from '../../api/sltn';

const checkSltnIdSaga = createAsyncSaga(postCheckSltnIdAsync, postCheckSltnId);
const getSltnListSaga = createAsyncSaga(getSltnListAsync, getSltnList);
const getSltnByIdSaga = createAsyncSaga(getSltnByIdAsync, getSltnById);
const createSltnSaga = createAsyncSaga(postCreateSltnAsync, createSltn);
const updateSltnSaga = createAsyncSaga(putUpdateSltnAsync, updateSltn);
const deleteSltnSaga = createAsyncSaga(deleteSltnAsync, deleteSltn);

export function* sltnSaga() {
  yield takeEvery(POST_CHECK_SLTN_ID, checkSltnIdSaga);
  yield takeEvery(GET_SLTN_LIST, getSltnListSaga);
  yield takeEvery(GET_SLTN_BY_ID, getSltnByIdSaga);
  yield takeEvery(POST_CREATE_SLTN, createSltnSaga);
  yield takeEvery(PUT_UPDATE_SLTN, updateSltnSaga);
  yield takeEvery(DELETE_SLTN, deleteSltnSaga);
}
