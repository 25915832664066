import { FC, useEffect,useState } from "react";
import '../styles/components/button.scss';
import SmallDialog from "./SmallDialog";

type ButtonsProps = {
    title: string;
    openDialog : boolean;
    closeDialog: (value : string) => void;
};

const Confirm: FC<ButtonsProps> = ({ title, openDialog , closeDialog }) => { 

    const returnDialog = (value : string) => {
        closeDialog(value);
    }
    return (
        <SmallDialog id="DelModal"  isOpen={openDialog} onClose={ () => returnDialog('N') }>
            <p>{title}</p>
            <div className="button-group center">
                <button onClick={() => returnDialog('N')} type="button" value="N" className="button button--sm button--outline-black">아니오</button>
                <button onClick={() => returnDialog('Y')} type="button" value="Y" className="button button--sm button--primary">네</button>
            </div>
        </SmallDialog>
    );
}

export default Confirm;
