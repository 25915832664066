import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";

const ProjectManage3:FC<any> = ({  totalDataLogMap }) => {
    
    return (
        <ProjectSections title="작업 이력" className="col-3-1 col-lg-6 col-md-12 user-worked" >
            <ul className="user-worked-list">
                {totalDataLogMap && totalDataLogMap.map((work:any) => (
                    <li>
                        <div className="worked-item-title">{work.name}</div>
                        <div className="worked-item-date">{work.registerDate}</div>
                    </li>
                ))}
            </ul>
        </ProjectSections>
    )
}
export default ProjectManage3;
