import { createReducer } from 'typesafe-actions';
import { UserState, UserAction } from './types';
import { getUsersAsync, setUserAsync } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: UserState = {
  data: asyncState.initial(),
};

const users = createReducer<UserState, UserAction>(initialState).handleAction(
  transformToArray(getUsersAsync),
  createAsyncReducer(getUsersAsync, 'data')
);

export default users;