import { FC } from 'react';
import { Link } from "react-router-dom";
import "../../styles/layout/mobile-navigation.scss";

const MobileGnb:FC = () => {

    const toggleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
        const button = e.currentTarget;
        const expanded = button.getAttribute('aria-expanded') === 'true';
        button.setAttribute('aria-expanded', expanded ? 'false' : 'true');
    };
    
    return (
        <aside className="mobile__gnb">
            <div className="mobile-wrap">
                <div className="mobile-menu">
                    <ul className="mobile-menu-list">
                        <li><button type="button" aria-expanded="false" className="trigger" onClick={toggleExpand}><span>서비스 소개</span><i aria-hidden="true" className="icon--arw-black"></i></button>
                            <div className="depth">
                                <ul>
                                    <li><Link to={'/overview'}>서비스 개요</Link></li>
                                    <li><Link to={'/features'}>주요 기능</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li><button type="button" aria-expanded="false" className="trigger" onClick={toggleExpand}><span>제품 안내</span><i aria-hidden="true" className="icon--arw-black"></i></button>
                            <div className="depth">
                                <ul>
                                    <li><Link to={'/product'}>제품 안내</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li><button type="button" aria-expanded="false" className="trigger" onClick={toggleExpand}><span>프로젝트</span><i aria-hidden="true" className="icon--arw-black"></i></button>
                            <div className="depth">
                                <ul>
                                    <li><Link to={'/project'}>영상촬영 서비스</Link></li>
                                    <li><Link to={'/project'}>AI 데이터 생성관리 서비스</Link></li>
                                    <li><Link to={'/project'}>디지털트윈 지도 서비스</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li><button type="button" aria-expanded="false" className="trigger" onClick={toggleExpand}><span>고객지원</span><i aria-hidden="true" className="icon--arw-black"></i></button>
                            <div className="depth">
                                <ul>
                                    <li><Link to={'/notice'}>공지사항</Link></li>
                                    <li><Link to={'/faq'}>FAQ</Link></li>
                                    <li><Link to={'/contact'}>문의하기</Link></li>
                                    <li><Link to={'/help'}>도움말</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default MobileGnb;