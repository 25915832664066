import axios from 'axios';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import {PotalCommDto} from '../types/PotalCommDto';
import {CreateBuyDto} from '../types/CreateBuyDto';
import { accessTokenMapprimeApi, accessTokenNeospectraApi } from './api';
import accessTokenPortalApi from './api';
import config from '../config';

interface ApiResponseList {
  status: string;
  data?: any[];
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}

interface ApiResponse {
  status: string;
  data?: any;
  meta?: PotalCommDto
  error?: {
    code: string;
    message: string;
  };
}


interface MapprimeDashboardApiResponse {
  count?: {project :number, asset: {progress:number,done:number}}, original: number;
  datasize?: { id : number, name: string, size: number }[];
  query?: any;
  result: string;
  message: string;
  page?: number;
}


export async function getProduct(sltnId: string): Promise<any | null> {
  
  try {
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/prdct/`+sltnId,
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function getProductItem(sltnId: string): Promise<any | null> {
  const access_token = sessionStorage.getItem('access_token');
  try {
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/prdct/item/`+sltnId,
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

export async function getBuyKey(): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<Promise<ApiResponse> | null>(
      `${config.portalApiUrl}/buy/key`,
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to Buy Key:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '구매 정보 조회시 오류가 발생하였습니다.',
      },
    };
  }
};

// 구매 id 를 설정하기 위해서 구매정보를 저장한다.
export async function setBuyInfo(createBuyDto : CreateBuyDto): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.post<Promise<ApiResponse> | null>(
      `${config.portalApiUrl}/buy`,
      createBuyDto,
    );

    // console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to Buy:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '구매 정보 저장시 오류가 발생하였습니다.',
      },
    };
  }
};

// 구매완료 처리
export async function putBuy(createBuyDto : CreateBuyDto): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.put<Promise<ApiResponse> | null>(
      `${config.portalApiUrl}/buy/`+createBuyDto.stlmId,
      createBuyDto,
    );

     console.log('api 리턴 결과:', JSON.stringify(response.data, null, 2));
    
    return response.data;
  } catch (error) {
    console.error('Failed to Buy:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '구매 완료 처리시 오류가 발생하였습니다.',
      },
    };
  }
};

// Mapprime 정보를 받아온다.
export async function getMapprimeDashboard({mapprimeToken}: {mapprimeToken: string | null}): Promise<any | null> {
  try {
      const response = await accessTokenMapprimeApi.get<Promise<MapprimeDashboardApiResponse> | null>(
          `${config.mapprimeApiUrl}/${config.mapprimeApiVer}/data/portal/dashboard`,
      );
      console.log('api 리턴 결과:', JSON.stringify(response, null, 2));

      return response.data;
  } catch (error) {
      console.error('Failed to select:', error);
      return {
          result: 'error',
          message: `${error}`,
      };
  }
};

// 구독 목록을 조회한다.
export async function getBuyScrtnList(param : any): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/buy/scrtn`,
        {
          params :  param,
        }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

// 현재 사용중인 구독 관련 목록을 조회한다.
export async function getBuyUseList(): Promise<any | null> {
  try {
    
    const response = await accessTokenPortalApi.get<Promise<ApiResponseList> | null>(
      `${config.portalApiUrl}/buy/use`,
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};

//  현재 사용중인 구독 관련 정보를 조회한다.(단건)
export async function getBuyUse(sltnId:string): Promise<any | null> {
  try {
    const response = await accessTokenPortalApi.get<Promise<ApiResponse> | null>(
      `${config.portalApiUrl}/buy/use/`+sltnId,
        {
          params : {"sltnId" : sltnId}
        }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to select:', error);
    return {
      status: 'error',
      error: {
        code: 'INTERNAL_SERVER_ERROR',
        message: '조회시 오류가 발생하였습니다.',
      },
    };
  }
};
