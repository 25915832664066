import { FC } from "react";
import { Link } from "react-router-dom";
import ProjectSections from "./ProjectSection";
import Progress from "./Progress";
import { openInfoPage , getFormatCurrency }from "../common/stringUtil";

interface ProjectState {
    id : string;
    title : string;
    count : number;
    text  : number;
    theme : string;
}




const ProjectManage12:FC<any> = ({  infoDataMap }) => { 

    const projects: ProjectState[] = [
        { id: "drone"   , title: "드론장비"    , count: (infoDataMap?.USE_DRN_CNT && infoDataMap?.TOTAL_DRN_CNT ? infoDataMap?.USE_DRN_CNT/infoDataMap?.TOTAL_DRN_CNT : 0 ) , text : (infoDataMap?.TOTAL_DRN_CNT ? infoDataMap?.TOTAL_DRN_CNT : 0 ) , theme : 'sky'},
        { id: "mobile"  , title: "모바일 장비" , count: (infoDataMap?.USE_EQM_CNT && infoDataMap?.TOTAL_EQM_CNT ? infoDataMap?.USE_EQM_CNT/infoDataMap?.TOTAL_EQM_CNT : 0 ) , text : (infoDataMap?.TOTAL_EQM_CNT ? infoDataMap?.TOTAL_EQM_CNT : 0 ) , theme : 'sky'},
        { id: "pilot"   , title: "조종사"      , count: (infoDataMap?.USE_PLT_CNT && infoDataMap?.TOTAL_PLT_CNT ? infoDataMap?.USE_PLT_CNT/infoDataMap?.TOTAL_PLT_CNT : 0 ) , text : (infoDataMap?.TOTAL_PLT_CNT ? infoDataMap?.TOTAL_PLT_CNT : 0 ) , theme : 'sky'},
        { id: "investi" , title: "조사자"      , count: (infoDataMap?.USE_INVS_CNT && infoDataMap?.TOTAL_INVS_CNT ? infoDataMap?.USE_INVS_CNT/infoDataMap?.TOTAL_INVS_CNT : 0 ) , text : (infoDataMap?.TOTAL_INVS_CNT ? infoDataMap?.TOTAL_INVS_CNT : 0 ) , theme : 'sky'},
    
    ]
    
    return (
        <ProjectSections title="장비/인력 현황" className="user-state" onButtonClick={() => openInfoPage("P005")} buttonClassName="icon--more">
            {projects.map(item => (
                <Progress 
                    item={{ id: item.id, title: item.title, count: item.count , text : getFormatCurrency(item.text) }} 
                    theme={item.theme}
                />
            ))}
        </ProjectSections>
    )
}
export default ProjectManage12;
