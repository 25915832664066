import { AxiosError } from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import { SltnDto } from '../../types/SltnDto';

export const POST_CHECK_SLTN_ID = 'api/POST_CHECK_SLTN_ID'
export const POST_CHECK_SLTN_ID_SUCCESS = 'api/POST_CHECK_SLTN_ID_SUCCESS'
export const POST_CHECK_SLTN_ID_ERROR = 'api/POST_CHECK_SLTN_ID_ERROR'
export const GET_SLTN_LIST = 'api/POST_CHECK_SLTN_ID'
export const GET_SLTN_LIST_SUCCESS = 'api/POST_CHECK_SLTN_ID_SUCCESS'
export const GET_SLTN_LIST_ERROR = 'api/POST_CHECK_SLTN_ID_ERROR'
export const GET_SLTN_BY_ID = 'api/POST_GET_SLTN_BY_ID'
export const GET_SLTN_BY_ID_SUCCESS = 'api/GET_SLTN_BY_ID_SUCCESS'
export const GET_SLTN_BY_ID_ERROR = 'api/GET_SLTN_BY_ID_ERROR'
export const POST_CREATE_SLTN = 'api/POST_CREATE_SLTN'
export const POST_CREATE_SLTN_SUCCESS = 'api/POST_CREATE_SLTN_SUCCESS'
export const POST_CREATE_SLTN_ERROR = 'api/POST_CREATE_SLTN_ERROR'
export const PUT_UPDATE_SLTN = 'api/PUT_UPDATE_SLTN'
export const PUT_UPDATE_SLTN_SUCCESS = 'api/PUT_UPDATE_SLTN_SUCCESS'
export const PUT_UPDATE_SLTN_ERROR = 'api/PUT_UPDATE_SLTN_ERROR'
export const DELETE_SLTN = 'api/DELETE_SLTN'
export const DELETE_SLTN_SUCCESS = 'api/DELETE_SLTN_SUCCESS'
export const DELETE_SLTN_ERROR = 'api/DELETE_SLTN_ERROR'


export const postCheckSltnIdAsync = createAsyncAction(
  POST_CHECK_SLTN_ID,
  POST_CHECK_SLTN_ID_SUCCESS,
  POST_CHECK_SLTN_ID_ERROR
)<string, any, AxiosError>();
// <TRequestPayload, TSuccessPayload, TFailurePayload>

export const getSltnListAsync = createAsyncAction(
  GET_SLTN_LIST,
  GET_SLTN_LIST_SUCCESS,
  GET_SLTN_LIST_ERROR
)<SltnDto, any, AxiosError>();

export const getSltnByIdAsync = createAsyncAction(
  GET_SLTN_BY_ID,
  GET_SLTN_BY_ID_SUCCESS,
  GET_SLTN_BY_ID_ERROR
)<string, any, AxiosError>();

export const postCreateSltnAsync = createAsyncAction(
  POST_CREATE_SLTN,
  POST_CREATE_SLTN_SUCCESS,
  POST_CREATE_SLTN_ERROR
)<SltnDto, any, AxiosError>();

export const putUpdateSltnAsync = createAsyncAction(
  PUT_UPDATE_SLTN,
  PUT_UPDATE_SLTN_SUCCESS,
  PUT_UPDATE_SLTN_ERROR
)<{sltnId: string ,updateSltnDto: SltnDto}, any, AxiosError>();

export const deleteSltnAsync = createAsyncAction(
  DELETE_SLTN,
  DELETE_SLTN_SUCCESS,
  DELETE_SLTN_ERROR
)<string, any, AxiosError>();