import { getCommCdListAsync , GET_CD_GRP , deleteFileAsync , DELETE_FILE} from './actions';
import { getComboList , deleteFile} from '../../api/common';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';

const getommCdListSaga = createAsyncSaga(getCommCdListAsync, getComboList);
const deleteFileSaga = createAsyncSaga(deleteFileAsync, deleteFile);

export function* commonSaga() {
  yield takeEvery(GET_CD_GRP, getommCdListSaga);
  yield takeEvery(DELETE_FILE, deleteFileSaga);
}
