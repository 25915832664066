import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../modules/";
import Buttons from './Buttons';
import '../styles/components/search.scss';
import { getCommCdListAsync } from '../modules/common';

interface SearchFormProps {
    objectId : string;
    cdGrpId  : string;
    defValue : string;
    params   : any;
    disabled? : boolean;
    onChange: (params : any , value: string) => void; // onChange prop 추가
}

const SelectBoxForm:FC<SearchFormProps> = ({ objectId, cdGrpId, defValue,params , disabled,onChange }) => {

    const dispatch = useDispatch();
    const comboInfo  = useSelector((state:RootState) => state.common.data);
    
    useEffect(() => {
        
        console.log('시작 : ' + cdGrpId);
        dispatch(getCommCdListAsync.request({cdGrpId : cdGrpId}));

        // console.log('리턴 결과:', JSON.stringify(comboInfo, null, 2));
      },[]);


    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(params , e.target.value); // 선택된 값 전달
    };

    let options = [];

    if (comboInfo.data && comboInfo.data.data) {
        for (let i=0;i<comboInfo.data.data.length;i++) {
            let combo = comboInfo.data.data[i];
            if (cdGrpId == combo.cdGrpId) {
                if (combo.cmCdId == defValue) {
                    options.push(<option key={combo.cdGrpId + '_' + combo.cmCdId} value={combo.cmCdId} selected>{combo.cdVl}</option>);
                } else {
                    options.push(<option key={combo.cdGrpId + '_' + combo.cmCdId} value={combo.cmCdId}>{combo.cdVl}</option>);
                }
            }
        }
    }

    
    return (
        <select key={objectId} name="{objectId}" id="{objectId}" className='form-select' title='분류 선택' disabled={disabled} onChange={handleChange}>
           <option value="">선택</option>
           {options}
        </select>
    )
}

export default SelectBoxForm;