import { FC } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/icon.scss';
import '../../styles/components/button.scss';
import './RegistrationComplete.scss';

interface RegisterCompleteProps {
    userName: string;
}

const RegistrationComplete: FC<RegisterCompleteProps> = ({ userName }) => {
    return (
        <div className="regi-complete">
            <div className="row">
                <div className="container">
                    <div className='welcome-message'>
                        <i className='icon--user-big' aria-hidden="true"></i>
                        <p><b>{userName}</b>님의 회원가입을 진심으로 환영합니다.<br />
                            로그인 후 영상정보 관리 및 분석 서비스를 이용하실 수 있습니다.</p>
                    </div>
                    <div className='button-group center'>
                        <Link to={'/login'} className='button button--outline-black button--md'>로그인 화면으로 이동</Link>
                        <Link to={'/products'} className='button button--primary button--md'>제품 선택 화면으로 이동</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationComplete;