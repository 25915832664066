import { useEffect, useState , FC } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Banners from "../../containers/banner";
// import './Buy.scss';

interface PageProps {
  title: string;
}

  const BuyFail: FC<PageProps> = ({ title }) => {

    const [searchParams] = useSearchParams();
    return (
      <div className='board'>
        <Banners title={title} />
        <div className="board-list" style={{ textAlign: "center" }}>
            <div className="row">
            <div id="info" className="box_section" >
          <img width="100px" src="https://static.toss.im/lotties/error-spot-no-loop-space-apng.png" alt="에러 이미지" />
          <h2>결제를 실패했어요</h2>

          <div className="p-grid typography--p" style={{ marginTop: "50px" }}>
            <div className="p-grid-col text--left">
              <b>에러메시지</b>
            </div>
            <div className="p-grid-col text--right" id="message">{`${searchParams.get("message")}`}</div>
          </div>
          <div className="p-grid typography--p" style={{ marginTop: "10px" }}>
            <div className="p-grid-col text--left">
              <b>에러코드</b>
            </div>
            <div className="p-grid-col text--right" id="code">{`${searchParams.get("code")}`}</div>
          </div>

          <div className="p-grid-col">
            <Link to="https://docs.tosspayments.com/guides/v2/payment-widget/integration">
              <button className="button p-grid-col5">연동 문서</button>
            </Link>
            
          </div>
        </div>
            </div>
        </div>

        
      </div>
    );
}
export default BuyFail;