import { FC } from 'react';
import '../styles/components/feedback.scss';

interface FeedbackProps {
    type: 'error' | 'success';
    message: string;
}

const Feedback:FC<FeedbackProps > = ({ type, message }) => {
    return(
        <div className={`feedback feedback-${type}`}>
            {message}
        </div>
    )
}

export default Feedback;