import { ChangeEvent, FC, FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import Banners from "../../containers/banner";
import Title from '../../components/Titles';
import Buttons from '../../components/Buttons';
import '../../styles/components/input.scss';
import '../../styles/components/icon.scss';
import './forgot.scss';
import { useDispatch, useSelector } from "react-redux";
import { getPassword } from "../../api/users";
import { getPasswordAsync } from "../../modules/user";
import { RootState } from "../../modules";

interface PageProps {
    title: string;
}
const ForgotId: FC<PageProps> = ({ title }) => {
    const [userId, setUserId] = useState(''); // 선택된 이메일 도메인 상태
    const [username, setUsername] = useState(''); // 선택된 이메일 도메인 상태
    const [userPassword, setUserPassword] = useState(false);
    const [email, setEmail] = useState(''); // 선택된 이메일 도메인 상태
    const [customDomain, setCustomDomain] = useState(''); // 직접 입력 도메인 상태
    const dispatch = useDispatch();
    const returnData = useSelector((state: RootState) => state.user.data);

    
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        dispatch(getPasswordAsync.request({ name: username, email: email, id: userId}));
    };

    return (
        <>
            <Banners title={title} />
            <div className="forgot">
                <div className="row">
                    <div className="container">
                        <Title title="영상정보 관리 및 분석 <br className='md-show' />서비스 비밀번호 찾기" subtitle="가입시 입력하신 아이디 및 이메일로 가입여부를 확인합니다" />
                        <div className="small-wraps">
                            {userPassword ? (
                                <div className="forgot-result">
                                    <p>회원님의 비밀번호는 <br></br><b>{returnData.data.password}</b> 입니다.</p>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <form className="forgot-form" onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend className="sr-only">비밀번호 찾기</legend>
                                    <div className="form-group">
                                        <label htmlFor="userName" className="label">이름</label>
                                        <input type="text" id="userName" className="form-control block" placeholder="이름를 입력해 주세요." value={username} onChange={(e) => setUsername(e.target.value)}required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userId" className="label">아이디</label>
                                        <input type="text" id="userId" className="form-control block" placeholder="아이디를 입력해 주세요." value={userId} onChange={(e) => setUserId(e.target.value)} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userEmail" className='label'>이메일</label>
                                        <div className="form-email">
                                            <input type="text" id="userEmail" className="form-control block" value={email} placeholder="email@example.com" onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                    </div>
                                    <Buttons type='submit' text="확인" variant="primary" size="lg" block />
                                    <div className="login-util end">
                                        <Link to={'/register'} className='forgot-link'>회원가입 <i className="icon--arwright-black"></i></Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotId;