import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules";
import { useNavigate } from 'react-router-dom';
import Dialog from "../../components/Dialog";
import { CreateUserJoinReqDto } from "../../types/myPage/CreateUserJoinReqDto";
import { getMyPageUserInviteAsync , postMyPageUserInviteAsync } from "../../modules/myPage";
import { isEmailFormatChk } from "../../pages/common/stringUtil";

type ButtonsProps = {
    openYn: boolean;
    closeDialog: () => void;
    onClick?: () => void;
};

const MypageUserInviteModal: FC<ButtonsProps> = ({ openYn , closeDialog }) => {  
    const dispatch = useDispatch();   
    const navigate = useNavigate();

    const [email , setEmail] = useState("");
	const [userRoleCd , setUserRoleCd] = useState("");
    const [inviteSendYn , setInviteSendYn] = useState("False");
    const [param , setParam] = useState<CreateUserJoinReqDto>({
        reqUserEml: "" , 
        userRoleCd: "", 
        returnUrl: ""
    });

    const inviteList    = useSelector((state:RootState) => state.myPage.inviteList);
    const inviteResult  = useSelector((state:RootState) => state.myPage.result);

    useEffect(() => {
        const fetchList = async () => {
            dispatch(getMyPageUserInviteAsync.request(null));
        };

        fetchList();

    },[]);

    const setSend = () => {
        // 이메일 형식 체크
        const fetchData = async () => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!isEmailFormatChk(email)) {
                alert('올바른 이메일 형식을 입력하세요.');
                return;
            }

            param.reqUserEml = email;
            param.userRoleCd = userRoleCd;

            setInviteSendYn("True");
            await dispatch(postMyPageUserInviteAsync.request(param));


        };

        fetchData();
    }

    useEffect(() => {

        if (inviteSendYn == "True") {
            if (inviteResult && inviteResult.data && inviteResult.data.status == "error") {
                alert(inviteResult.data.error.message.message);
    
            } else if (inviteResult && inviteResult.data && inviteResult.data.status == "success") {
                alert("신규 회원 초대가 완료되었습니다.");
                setInviteSendYn("False");
                closeDialog();
            }
        }
        

    },[inviteResult]);

    return (
        <Dialog id="NewModal"  isOpen={openYn} title="신규 회원 초대" onClose={ closeDialog }>
            <div className="form-group">
                <div className="">
                    <label className="label" htmlFor="userName">이메일</label>
                    <div className="">
                        <div className="form-email">
                            <input type="text"  id="userEmail" name="userEmail" className="form-control block" title="이메일 입력" placeholder="이메일을 입력하세요" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-textarea">
                    <label className="label" htmlFor="userName">권한</label>
                    <div className="">
                        <input
                            type="radio"
                            id="userRoleAdmin"
                            name="userRoleCd"
                            onClick={(e) => setUserRoleCd("ADMIN")}
                        />
                        <label htmlFor="userRoleAdmin" style={{paddingLeft:"10px",cursor:"pointer"}}>기관 관리자</label>
                        <input
                            type="radio"
                            id="userRoleUser"
                            name="userRoleCd"
                            style={{marginLeft:"20px"}}
                            onClick={(e) => setUserRoleCd("USER")}
                        />
                        <label htmlFor="userRoleUser" style={{paddingLeft:"10px",cursor:"pointer"}}>일반 사용자</label>
                    </div> 
                    
                </div>
            </div>
            <div className="button-group right">
                <button onClick={closeDialog} type="button" className="button button--sm button--outline-black">취소</button>
                <button onClick={setSend} type="button" className="button button--sm button--primary">회원 초대하기</button>
            </div>
            <div className="invitation-list">
                <h3>초대 이력</h3>
                <ul className="invitation-list-inner">
                    {inviteList && inviteList.loading ? (
                        <li className="empty"> loading...</li>
                    ) : inviteList && inviteList.data && inviteList.data.data && inviteList.data.data.length > 0 ? ( 
                        inviteList && inviteList.data.data && inviteList.data.data.map((item: any, idx: number) => 
                        <li className="invitation-list-item">
                            <span className="name">{item.userRoleCd == 'ADMIN' ? '기관 관리자' : '일반 사용자'}</span>
                            <span className="email">{item.reqUserEml}</span>
                            <span className="regDt">{item.regDt}</span>
                        </li>)
                    ) : (<li className="empty"> 초대 이력이 없습니다. </li>)
                    }
                </ul>
            </div>
            
        </Dialog>
    )
}

export default MypageUserInviteModal;
