import { FC } from 'react';
import { Link } from 'react-router-dom';
import MainHeaderGroup from './MainHeaderGroup'; 

import './main-section4.scss';

interface SettingListItem {
    id: number;
    kr : string;
    en: string;
    desc : string;
}
const settingList: SettingListItem[] = [
    {
        id: 1 ,
        kr : '매뉴얼',
        en : 'Manual',
        desc : '서비스를 쉽고 빠르게 활용할 수 있도록,  <br />상세하고 친절한 안내가 포함된 편리한 매뉴얼을 제공해 드립니다.' 
    },{
        id: 2 ,
        kr : '대시보드',
        en : 'Dashboard',
        desc : '개인 및 그룹에게 최적화된 데이터를 다양한 시각적 정보 형태로 <br />제공하여 정보를 쉽게 이해하고 활용할 수 있도록 지원합니다.' 
    },{
        id: 3 ,
        kr : '커뮤니티',
        en : 'Community',
        desc : '궁금한 점을 질문하고, 공유하며, 다양한 활용 방안에 대한 <br />창의적이고 실용적인 아이디어를 찾아보세요.' 
    },{
        id: 4 ,
        kr : '라이브러리',
        en : 'Library',
        desc : '전문가들이 개발한 오픈 소스 스크립트를 활용하여, <br />다양한 AI 환경을 제공하고 활용할 수 있도록 지원합니다.' 
    }
]
const MainSection4:FC = () => {
    return (
        <section className='main-section4'>
            <div className="row">
                <div className="container">
                    <MainHeaderGroup>
                    지금 영상정보 관리 및 분석 서비스로<br />
                    <b>다양한 3D 환경을 구축</b>하세요.
                    </MainHeaderGroup>
                    <div className='main-section4-links'>
                    {settingList.map((item, index) => (
                        <div className='item'  key={item.id}>
                            <div className='icon'></div>
                            <div className='figure'>
                                <div className='kr'>{item.kr} <em>{item.en}</em></div>
                                <div className='desc' dangerouslySetInnerHTML={{__html: item.desc}} /> 
                            </div>
                        </div>
                    ))}
                    </div>
                   <div className='link-area'>
                    <Link to={'/overview'}>서비스 자세히 알아보기</Link>
                   </div>
                </div>
            </div>
        </section>
    )
}

export default MainSection4;