import { createReducer } from 'typesafe-actions';
import { OgnzState, OgnzAction } from './types';
import { findOgnzListAsync } from './actions';
import { asyncState, createAsyncReducer, transformToArray } from '../lib/reducerUtils';

const initialState: OgnzState = {
  data: asyncState.initial(),
};

const ognz = createReducer<OgnzState, OgnzAction>(initialState)
.handleAction(
  transformToArray(findOgnzListAsync),
  createAsyncReducer(findOgnzListAsync, 'data')
);

export default ognz;