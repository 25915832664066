import { FC } from 'react';
import './stepNavigation.scss';

enum Steps {
    Agreement = 1,
    Information,
    Completion,
}

const StepLabels: Record<Steps, string> = {
    [Steps.Agreement]: '약관 동의',
    [Steps.Information]: '정보 입력',
    [Steps.Completion]: '회원 가입 완료',
};

interface StepNavigationProps {
    currentStep: number;
    totalSteps: number;
}

const StepNavigation:FC<StepNavigationProps> = ({ currentStep, totalSteps  }) => {
    //const steps = Array.from({ length: totalSteps }, (_, index) => index + 1);
    return (
        <div className="step-navigation">
            <div className="row">
                <div className="container step-wraps">
                {Array.from({ length: totalSteps }, (_, index) => {
                    const step = index + 1 as Steps;
                    const stepContent = StepLabels[step];

                    return (
                        <div
                            key={step}
                            className="step-navigation-item"
                        >
                            <div className={`deco ${step === currentStep ? 'active' : ''}`}></div>
                            <p className="step-show">STEP {step}</p>
                            <p>{stepContent}</p>
                        </div>
                    );
                })}
                </div>
            </div>
        </div>
    )
}

export default StepNavigation;