import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../modules";
import { getProductAsync , getProductItemAsync , getBuyKeyAsync } from '../modules/product';
import { loadTossPayments, ANONYMOUS , TossPaymentsWidgets } from "@tosspayments/tosspayments-sdk";
import { getFormatCurrency } from "../pages/common/stringUtil";

interface ProductProps {
    sltnId: string;
    compAmt:number;
    buyPrdctId: string;

    setBuyProduct: (prdctSn: number , prdctNm:string , month : number , payment : number , widgets : TossPaymentsWidgets | null,customerKey:string) => void; // onChange prop 추가
}
// MAPPRIME (디지털틔원 ) , SIMMETA (AI 데이터생성관리 ) , GEO_NIC (영상촬영) 

const Product:FC<ProductProps> = ({ sltnId, compAmt , buyPrdctId , setBuyProduct  }) => {
    const dispatch = useDispatch();

    // 결과 리턴값 설정
    const product       = useSelector((state:RootState) => state.product.product);
    const productDetail = useSelector((state:RootState) => state.product.productItem);
    const buyKey        = useSelector((state:RootState) => state.product.buyKey);

    // 변수 설정
    const [clientKey, setClientKey] = useState<string>("");
    const [customerKey, setCustomerKey] = useState<string>("");
    const [amount, setAmount] = useState({
          currency: "KRW",
          value: 0,
    });
    
    const [ready, setReady] = useState<boolean>(false);
    const [widgets, setWidgets] = useState<TossPaymentsWidgets | null>(null);
    const [prdctSn, setPrdctSn] = useState<number>(0);
    const [month, setMonth] = useState<number>(0);

  /* 결제 모듈 연동 start */
  useEffect(() => {
    if (buyKey && buyKey.data && buyKey.data.data ) {
        console.log('buyKey 리턴 결과:', JSON.stringify(buyKey, null, 2));
        
        setClientKey(buyKey.data.data.clientKey);
        setCustomerKey(buyKey.data.data.customerKey);
    }    
  }, [buyKey]);

  useEffect(() => {
    setPrdctSn(0);
    setMonth(0);
  }, [sltnId]);

  useEffect(() => {
    async function fetchPaymentWidgets() {
        if (clientKey == "") {
            return;
        }

      try {
        const tossPayments = await loadTossPayments(clientKey);
        // const widgets = tossPayments.widgets({ customerKey });
        const widgets = tossPayments.widgets({ customerKey: customerKey });

        setWidgets(widgets); // 위젯이 로드된 후 상태 업데이트
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    }

    fetchPaymentWidgets();
  }, [clientKey]);

  useEffect(() => {
    async function renderPaymentWidgets() {
       
      if (widgets == null) {
        return;
      }
      // ------ 주문의 결제 금액 설정 ------
      await widgets.setAmount(amount);

      await Promise.all([
        // ------  결제 UI 렌더링 ------
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          variantKey: "DEFAULT",
        }),
        // ------  이용약관 UI 렌더링 ------
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);

      await setReady(true);
    }

    renderPaymentWidgets();
  }, [widgets]);

  useEffect(() => {
    if (widgets == null) {
      return;
    }

    widgets.setAmount(amount);
  }, [widgets, amount]);

  /* 결제 모듈 연동 end */


    // 상품 그룹번호 변경시 상품정보 조회
    useEffect(() => {
        const fetchSearchData = async () => {
            const access_token =  sessionStorage.getItem("access_token");

            if (access_token) {
                await dispatch(getBuyKeyAsync.request(sltnId)); 
            }

            await dispatch(getProductAsync.request(sltnId));

            await dispatch(getProductItemAsync.request(sltnId));
        };

        fetchSearchData();

    },[sltnId]);
    
    const monthCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const radioArrt = e.target.id.split("|");
        
        const prdctSn = Number(radioArrt[0]);
        const month   = Number(radioArrt[1]);
        const prdctNm = radioArrt[2];
        const payment = Number(e.target.value);

        setAmount({currency: "KRW",
                   value: payment
                  });
                  
        setBuyProduct(prdctSn , prdctNm , month , payment , widgets,customerKey);

        setPrdctSn(prdctSn);
        setMonth(month);
    }

    const buyAct = () => {

    }

    var heightVal = "375px";
    var productHtml = [];
    
    if (product && product.data && product.data.data?.length > 0 ) {
        if (productDetail && productDetail.data && productDetail.data.data) {
            for (var i=0;i<product.data.data.length;i++) {
                var productInfo = product.data.data[i];
                var productItemHtml = [];
                var productItemTitleHtml = [];
                var classNm = "";
                if (i == 0) {
                    classNm = "basic";
                } else if (i == 1) {
                    classNm = "standard";
                } else if (i == 2) {
                    classNm = "premium";
                } else if (i == 3) {
                    classNm = "custom";
                }
                
                if (productDetail.data.data.length > 0) {
                    for (var j=0;j<productDetail.data.data.length;j++) {
                        var productItemInfo = productDetail.data.data[j];
                        var itemValueList = productItemInfo.itemValue.split("| ");
                        var itemValue = "";
                        if (itemValueList[i]) {
                            itemValue = itemValueList[i].trim();
                        }

                        var checkHtml = <dd></dd>
                        if (itemValue == "CHECK") {
                            checkHtml = <dd><img src="../../../images/mypage/check.png" alt="" /></dd>
                        }

                        if (j == productDetail.data.data.length-1) {
                            productItemTitleHtml.push(
                                <li style={{display: 'flex', alignItems: 'center', border:'1px solid #ecf0f2', borderRadius: '0 0 1.2rem 1.2rem'}}>{productItemInfo.itemName}</li>
                            );
                        } else {
                            productItemTitleHtml.push(
                                <li style={{display: 'flex', alignItems: 'center', border:'1px solid #ecf0f2'}}>{productItemInfo.itemName}</li>
                            );
                        }
    
                        if (productItemInfo.itemType == "OPT") {
                            productItemHtml.push(
                                <li>
                                    <dl><dt className="pc-none">{productItemInfo.itemName}</dt>
                                    { checkHtml }
                                    </dl>
                                </li>
                            );
                        } else {
                            productItemHtml.push(
                                <li><dl><dt className="pc-none">{productItemInfo.itemName}</dt><dd>{itemValue}</dd></dl></li>
                            );
                        }
                    }
                }

                if (buyPrdctId == "" || (buyPrdctId != "" && productInfo.prdctId != "PRDCT_GRP004" && buyPrdctId <= productInfo.prdctId && productInfo.prdctYearlyPrice-compAmt > 0 )) {
                    
                    if (compAmt > 0) {
                        heightVal = "455px";
                    }

                    productHtml.push(
                        <ul className={classNm+"-li card-li"}>
                            <li className={classNm+"-li-fir"} style={{height:heightVal}}>
                                <h2>{productInfo.prdctName}</h2>
                                <p>{productInfo.prdctDesc}</p>
                                {productInfo.prdctMonthlyPrice-compAmt > 0 ? (
                                    <dl>
                                        <dt><label className="label-radio" style={{cursor: "pointer"}}><input type="radio" id={productInfo.prdctSn + "|1|"+productInfo.prdctName} name="btnPrdcuMonth" value={productInfo.prdctMonthlyPrice-compAmt} checked={prdctSn == productInfo.prdctSn && month == 1 ? true : false } onChange={monthCheck} /><i></i><span>1개월</span></label></dt>
                                        <dd>{getFormatCurrency(productInfo.prdctMonthlyPrice-compAmt)} 원</dd>
                                    </dl>
                                ) : compAmt > 0 ? ( 
                                    <dl>
                                        <dt><label className="label-radio" style={{cursor: "pointer"}}><input type="radio" name="btnPrdcuMonth" disabled  /><i></i><span>1개월</span></label></dt>
                                        <dd>구매가 불가능 합니다.</dd>
                                    </dl>
                                 ) : (
                                    <dl>
                                        <dt><label className="label-radio" style={{cursor: "pointer"}}><input type="radio" id={productInfo.prdctSn + "|1|"+productInfo.prdctName} name="btnPrdcuMonth" value={0} checked={prdctSn == productInfo.prdctSn && month == 1 ? true : false } onChange={monthCheck} /><i></i><span>1개월</span></label></dt>
                                        <dd>-</dd>
                                    </dl>
                                 ) 

                                 }

                                {productInfo.prdctYearlyPrice > 0 ? (
                                    <dl>
                                        <dt><label className="label-radio" style={{cursor: "pointer"}}><input type="radio" id={productInfo.prdctSn + "|12|"+productInfo.prdctName}  name="btnPrdcuMonth" value={productInfo.prdctYearlyPrice-compAmt} checked={prdctSn == productInfo.prdctSn && month == 12 ? true : false } onChange={monthCheck} /><i></i><span>12개월</span></label></dt>
                                        <dd>{getFormatCurrency(productInfo.prdctYearlyPrice-compAmt)} 원</dd>
                                    </dl>
                                ) : compAmt > 0 ? ( 
                                    <dl>
                                        <dt><label className="label-radio" style={{cursor: "pointer"}}><input type="radio" name="btnPrdcuMonth" disabled /><i></i><span>12개월</span></label></dt>
                                        <dd>구매가 불가능 합니다.</dd>
                                    </dl>
                                ) : (
                                    <dl>
                                        <dt><label className="label-radio" style={{cursor: "pointer"}}><input type="radio" id={productInfo.prdctSn + "|12|"+productInfo.prdctName} name="btnPrdcuMonth" value={0} checked={prdctSn == productInfo.prdctSn && month == 12 ? true : false } onChange={monthCheck} /><i></i><span>12개월</span></label></dt>
                                        <dd>-</dd>
                                    </dl>
                                )}
                                
                                {compAmt > 0 ? (
                                    <dl>
                                        <dt><label className="label-radio" style={{cursor: "pointer"}}>보상금액</label></dt>
                                        <dd>{getFormatCurrency(compAmt)} 원</dd>
                                    </dl>
                                    ) : (
                                        null
                                    )
                                }
                            </li>
                            { productItemHtml }
                        </ul>
                    );
                }
            }
        }
    }

    return (
        <div className="product-li-wrap">
            <ul>
                <li style={{height:heightVal}}></li>
                { productItemTitleHtml }
            </ul>
            { productHtml }
        </div>
    );
};

export default Product;
