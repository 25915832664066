import { FC } from 'react';
import { Link } from "react-router-dom";
import Banners  from "../../containers/banner";
import './policy.scss';

interface PageProps {
    title: string;
}

const Email:FC<PageProps> = ({title}) => {
    return (
        <div className="">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <div className="cont-agg-all">
                        <section className="cont-agg-all_boxtext">"공공SaaS"에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하여, 이를 위반시 정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.</section>
                        <section>
                            <h3>정보통신망 이용촉진 및 정보 보호 등에 관한 법률</h3>
                            <div className="agg-item_text">[ 일부개정 2012.2.17 법률 제11322호 시행일 2012.8.18 ]</div>
                        </section>
                        <section>
                            <h3>50조의2 (전자우편주소의 무단 수집행위 등 금지)</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램이나 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니 된다.</li>
                                <li data-no="2">누구든지 제1항을 위반하여 수집된 전자우편주소를 판매·유통하여서는 아니 된다.</li>
                                <li data-no="3">누구든지 제1항과 제2항에 따라 수집·판매 및 유통이 금지된 전자우편주소임을 알면서 이를 정보 전송에 이용하여서는 아니 된다. [전문개정 2008.6.13]</li>
                            </ul>
                        </section>
                        <section>
                            <h3>제74조 (벌칙)</h3>
                            <ul className="agg-item_list1">
                                <li data-no="1">
                                    다음 각 호의 어느 하나에 해당하는 자는 1년 이하의 징역 또는 1천만원 이하의 벌금에 처한다. [개정 2012.2.17]
                                    <ul className="agg-item_list2">
                                        <li data-no="①">제8조제4항을 위반하여 비슷한 표시를 한 제품을 표시·판매 또는 판매할 목적으로 진열한 자</li>
                                        <li data-no="②">제44조의7제1항제1호를 위반하여 음란한 부호·문언·음향·화상 또는 영상을 배포·판매·임대하거나 공공연하게 전시한 자</li>
                                        <li data-no="③">제44조의7제1항제3호를 위반하여 공포심이나 불안감을 유발하는 부호·문언·음향·화상 또는 영상을 반복적으로 상대방에게 도달하게 한 자</li>
                                        <li data-no="④">제50조제6항을 위반하여 기술적 조치를 한 자</li>
                                        <li data-no="⑤">제50조의2를 위반하여 전자우편주소를 수집·판매·유통하거나 정보 전송에 이용한 자</li>
                                        <li data-no="⑥">제50조의8을 위반하여 광고성 정보를 전송한 자</li>
                                        <li data-no="⑦">제53조제4항을 위반하여 등록사항의 변경등록 또는 사업의 양도·양수 또는 합병·상속의 신고를 하지 아니한 자</li>
                                    </ul>
                                </li>
                                <li data-no="2">제1항 제3호의 죄는 피해자가 구체적으로 밝힌 의사에 반하여 공소를 제기할 수 없다. [전문개정 2008.6.13]</li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Email;