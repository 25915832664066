import { FC } from 'react';
import { Link } from "react-router-dom";
import '../../styles/layout/header-logo.scss';

const Logo:FC = () => {
    return <h1 className="brand">
        <Link to={'/'} title="홈 바로가기">
            <span className="sr-only">공공SaaS 영상관리 서비스</span>
        </Link>
    </h1>
}

export default Logo;