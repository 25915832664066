import { FC, useEffect,useState } from "react";
import { Link } from 'react-router-dom';
import MainHeaderGroup from './MainHeaderGroup'; 
import './main-section5.scss';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import { getFaqsAsync , getNoticesAsync } from '../../modules/support';

import 'swiper/css';
import 'swiper/css/pagination';


interface BoardItem {
    title: string;
    date: string;
}

interface BoardContent {
    id: string;
    items: BoardItem[];
}

const boardData: BoardContent[] = [
    {
        id: 'board-1',
        items: [
            { title: '11맵프라임 클라우드 SaaS 서비스 오픈', date: '2024-12-20' },
            { title: '22맵프라임 클라우드 SaaS 서비스 오픈', date: '2024-12-20' },
        ],
    },
    {
        id: 'board-2',
        items: [
            { title: '33맵프라임 클라우드 SaaS 서비스 오픈', date: '2024-12-20' },
            { title: '44맵프라임 클라우드 SaaS 서비스 오픈', date: '2024-12-20' },
        ],
    },
];
interface TabButtonProps {
    id: number;
    label: string;
    currentTab: number;
    onClick: (id: number) => void;
}

const TabButton:FC<TabButtonProps> = ({ id, label, currentTab, onClick }) => {
    return (
        <button
            type='button'
            className='tablist-item'
            role='tab'
            aria-controls={`board-${id}`}
            aria-selected={currentTab === id}
            onClick={() => onClick(id)}
        >
            {label}
        </button>
    );
};

const MainSection5:FC = () => {
    const dispatch = useDispatch();

    // 탭 상태관리
    const [currentTab, setCurrentTab] = useState<number>(1);
    const handleLatestClick = (index: number) => {
        setCurrentTab(index);

        if (index == 1) {
            dispatch(getFaqsAsync.request(searchParams));
        } else {
            dispatch(getNoticesAsync.request(searchParams));
        }
    }

    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy : "REG_DT"
      , currentPageNo : 1
      , pageUnit : 5
    });

    const supportInfo  = useSelector((state:RootState) => state.support.data);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getFaqsAsync.request(searchParams));
        };

        fetchData();
        
      },[]);
    
    return (
        <section className='main-section5'>
            <div className="row">
                <div className="container">
                    <MainHeaderGroup>
                        영상관리서비스의<br />
                        <b>주요 정보 및 알림</b>을 전달합니다.
                    </MainHeaderGroup>

                    <div className="wraps">
                        <div className='latest'>
                            <div className="tablist">
                                <TabButton id={1} label="자주하는 질문" currentTab={currentTab} onClick={handleLatestClick} />
                                <TabButton id={2} label="공지사항" currentTab={currentTab} onClick={handleLatestClick} />
                            </div>

                            <div className="latest-content" role="tabpanel" >
                                <ul>
                                {supportInfo.data && supportInfo.data.data.length > 0 ? ( 
                                    supportInfo.data.data && supportInfo.data.data.map((item: any, index: number) => 
                                        (currentTab == 1 ? (
                                            <li key={index}>
                                                <Link to={'/faq?id='+item.faqSn}>{item.faqTtl}</Link>
                                                <div className="date">
                                                    <span>등록일</span>
                                                    <span>{item.regDt}</span>
                                                </div>
                                            </li>) : 
                                            (
                                                <li key={index}>
                                                    <Link to={'/notice/' + item.ntcSn}>{item.ntcTtl}</Link>
                                                    <div className="date">
                                                        <span>등록일</span>
                                                        <span>{item.regDt}</span>
                                                    </div>
                                                </li>
                                            )
                                        )
                                    )
                                    ) : null }
                                </ul>
                            </div>
                            
                        </div>

                        <div className="banner-slide">
                            <div className="swpie">
                            <Swiper
                                modules={[Pagination]}
                                spaceBetween={0}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                            >
                                <SwiperSlide><Link to={'/'}><img src='../images/main/slide-img-1.png' alt='' /></Link></SwiperSlide>
                                <SwiperSlide><Link to={'/'}><img src='../images/main/slide-img-1.png' alt='' /></Link></SwiperSlide>
                                <SwiperSlide><Link to={'/'}><img src='../images/main/slide-img-1.png' alt='' /></Link></SwiperSlide>
                                <SwiperSlide><Link to={'/'}><img src='../images/main/slide-img-1.png' alt='' /></Link></SwiperSlide>
                                <SwiperSlide><Link to={'/'}><img src='../images/main/slide-img-1.png' alt='' /></Link></SwiperSlide>
                            </Swiper>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default MainSection5;