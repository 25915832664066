import { FC, useEffect,useState } from "react";
import Buttons from './Buttons';
import SelectBox from './SelectBox';
import { SearchSupportDto } from '../types/support/SearchSupportDto';
import '../styles/components/search.scss';
import { useDispatch, useSelector } from "react-redux"

interface SearchFormProps {
    onSearch: (newSearchParams: SearchSupportDto) => void;
    groupId : string;
}



const SearchForm:FC<SearchFormProps> = ({ onSearch , groupId }) => {
    const dispatch = useDispatch();

    const [searchParams, setFormData] = useState<SearchSupportDto>({});
    
    const handleSelectChange = (params : any , value: string) => {
        setFormData((prev) => ({ ...prev, searchType: value })); // 선택된 값 업데이트
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSearch(searchParams);
    }
    
    return (
        <div className='form-search'>
            <form className='form-fieldset' onSubmit={handleSubmit}>
                <fieldset>
                <legend className='sr-only'>프로젝트 검색</legend>
                <div className="search-input">
                <SelectBox 
                objectId="searchType"
                cdGrpId={groupId}
                defValue=""
                params={null}
                onChange={handleSelectChange}
                />
                <input type="text" id="searchKeyword" name="searchKeyword" className='form-control' title='검색어 입력' placeholder='검색어를 입력하세요' onChange={handleChange} />
                <Buttons text='검색' 
                    variant='primary' 
                    size='sm' 
                    type='submit'
                    />
                </div>
                </fieldset>
            </form>
        </div>
    )
}

export default SearchForm;