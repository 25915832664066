import { FC, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../modules/";
import Banners  from "../../containers/banner";
import SearchForm   from "../../components/Search";
import SortDisplay   from "../../components/SortDisplay";
import { SearchSupportDto } from '../../types/support/SearchSupportDto';
import Pagination from '../../components/paging';
import './faq.scss';
import { PotalCommDto } from '../../types/PotalCommDto';
import { getFaqsAsync } from '../../modules/support';
import { useQuery } from '../common/stringUtil';


interface PageProps {
    title: string;
}

interface FaqProps {
    question: string;
    answer: string;
    category: string;
    defOpen?:boolean;
}

const FaqItem:FC<FaqProps> = ({ question, answer, category,defOpen }) => {
    const [isOpen, setIsOpen] = useState(!defOpen);

    const toggleAnswer = () => {
        setIsOpen(!isOpen);
    };

    const MyComponent = (answer:string ) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        );
      };

    return (
        <div className="faq-item">
            <div className="faq-heading">
                <button type='button' onClick={toggleAnswer} aria-expanded={isOpen}>
                    <i>Q</i>
                    <span className="faq-category">{category}</span>
                    <span className="faq-title">{question}</span>
                </button>
            </div>
            <div className={`faq-answer ${isOpen ? '' : 'open'}`}>{ MyComponent( answer ) }</div>
        </div>
    );
};



const FAQ:FC<PageProps> = ({title}) => {
    const [pageingDto, setPageing] = useState<PotalCommDto>({
        currentPageNo : 1
      , pageUnit : 10
      , pageSize : 10
      , firstIndex : 0
      , totalCnt   : 0
      , firstPageNo : 0
      , lastPageNo : 0
      , prevPageNo : 0
      , nextPageNo : 0
      , listTotalCnt : 0
    });

    const dispatch = useDispatch();
    const supportInfo  = useSelector((state:RootState) => state.support.data);
    const [searchParams, setFormData] = useState<SearchSupportDto>({
        searchOrderBy : "REG_DT"
      , currentPageNo : 1
      , pageUnit : 10
    });

    const query = useQuery();
    const faqSn = query.get('id'); // 쿼리 파라미터에서 id 값을 가져옵니다.
    
    let listTotalCnt = 0;
    let totalCnt = 0;
    let pageUnit = 10;
    let currentPageNo = 1;

    if (supportInfo.data ) {
        if (supportInfo.data.meta) {
            totalCnt = Number(supportInfo.data.meta.totalCnt);
            pageUnit = supportInfo.data.meta.pageUnit;
            currentPageNo = supportInfo.data.meta.currentPageNo;
        }
        
        if (supportInfo.data.data) {
           listTotalCnt = Number(supportInfo.data.data.length);
        }

        console.log('리턴 결과:', JSON.stringify(supportInfo.data, null, 2));
    }

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getFaqsAsync.request(searchParams));
        };

        fetchData();
        if (supportInfo.data) {
            
            if (supportInfo.data.meta) {
                console.log('페이징 결과:', JSON.stringify(supportInfo.meta, null, 2));
                setPageing({ 
                    currentPageNo : supportInfo.data.meta.currentPageNo
                  , pageUnit : supportInfo.data.meta.pageUnit
                  , pageSize : supportInfo.data.meta.pageSize
                  , firstIndex : supportInfo.data.meta.firstIndex
                  , totalCnt   : supportInfo.data.meta.totalCnt
                  , firstPageNo : supportInfo.data.meta.firstPageNo
                  , lastPageNo : supportInfo.data.meta.lastPageNo
                  , prevPageNo : supportInfo.data.meta.prevPageNo
                  , nextPageNo : supportInfo.data.meta.nextPageNo
                  , listTotalCnt : supportInfo.data.data.length
              });
            }
            
        }
        
        
      },[searchParams,dispatch]);

     const searchHandleSearch = async (newSearchParams: SearchSupportDto) => {  
        await setFormData({...searchParams, searchType : newSearchParams.searchType , searchKeyword : newSearchParams.searchKeyword , currentPageNo: 1 });
    };

    const orderHandleSearch = async (newSearchParams: SearchSupportDto) => {  
        await setFormData({...searchParams, searchOrderBy : newSearchParams.searchOrderBy , pageUnit : newSearchParams.pageUnit , currentPageNo: 1 });
    }

    const onSearch = async (pageNo:number) => {  
        await setFormData({...searchParams , currentPageNo: pageNo });
    }

    let faqList = [];

    if (supportInfo.data ) {
        if (supportInfo.data.data && supportInfo.data.data.length > 0) {
            for (let i=0;i<supportInfo.data.data.length;i++) {
                let faqInfo = supportInfo.data.data[i];

                faqList.push(
                   <FaqItem
                    category={faqInfo.faqTypeNm}
                    question={faqInfo.faqTtl}
                    answer={faqInfo.faqCn}
                    defOpen={(faqSn && faqSn == faqInfo.faqSn ? true : false)}
                   />);
            }
        }

        console.log('리턴 결과:', JSON.stringify(supportInfo.meta, null, 2));
    }



    return (
        <div className='board'>
            <Banners title={title} />
            <div className="row">
                <div className="container">
                    <SearchForm onSearch={searchHandleSearch} groupId="FAQ_TYPE_CD" />
                    {/* search */}
                    <SortDisplay total={totalCnt} currentTotal={listTotalCnt} defSearchParams={searchParams} onSearch={orderHandleSearch} />
                    {/* board-controls */}

                    <div className="faq">
                        {faqList}
                    </div>

                    { supportInfo.data && supportInfo.data.meta  ? ( 
                    <Pagination key="1" pageingDto={supportInfo.data.meta} onClick={onSearch} />        
                       ) : (<span></span>) }
                </div>
            </div>
        </div>
        
    )
}

export default FAQ;