import { FC, useEffect,useState } from "react";
import '../styles/components/button.scss';
import AlertSmallDialog from "./AlertSmallDialog";

type ButtonsProps = {
    title: string;
    openDialog : boolean;
    context : string[];
    closeDialog: () => void;
};

const Alert: FC<ButtonsProps> = ({ title, openDialog , context , closeDialog }) => { 

    return (
        <AlertSmallDialog id="AlertModal"  isOpen={openDialog} onClose={closeDialog }>
            <p style={{textAlign: "center" , fontSize: "1.9rem" , paddingBottom: "2.5rem",fontWeight:"600"}}>{title}</p>
            <ul>
                {context && context.length > 1 ?  (
                 context && context.map((item: any, idx: number) => 
                    <li style={{lineHeight: "25px" , listStyle: "inside"}}>{item}</li> ) 
                ) : (context && context.length == 1 ? <li style={{lineHeight: "25px",textAlign:"center"}}>{context[0]}</li>  : null
                )}
            </ul>
            <div className="button-group center" style={{paddingTop: "25px"}}>
                <button onClick={closeDialog} type="button" value="N" className="button button--sm button--outline-black">확인</button>
            </div>
        </AlertSmallDialog>
    );
}

export default Alert;
