import axios from 'axios';
import config from '../../config';
import { useLocation } from 'react-router-dom';


// 이메일 포맷 체크
export const isEmailFormatChk = (email : string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       
    return emailPattern.test(email);
}

// 비밀번호 형식 체크 (영문/숫자/특수문자 중 2가지 이상 조화, 10자~16자로 구성된 문자열로 구성되며, 공백 입력 불가능, 연속된 문자, 숫자 사용 불가능)
export const isPassWdFormatChk = (password : string) => {
    const passwordPattern = /^(?!.*([a-zA-Z0-9])\1{2})(?=(?:.*[A-Za-z])|(?:.*\d)|(?:.*[@$!%*?&])){2}[A-Za-z\d@$!%*?&]{10,16}$/;
       
    return passwordPattern.test(password) && !/[.,]/.test(password);
}

// byte 값을 단위변경
export const getBytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
}

// byte 값을 단위변경
export const getBytesToSizeNumber = (bytes: number) : number => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 0;
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const returnV = Number((bytes / Math.pow(1024, i)).toFixed(1));

    return returnV;
}

export const getFormatCurrency = (amount: number) => {
    return new Intl.NumberFormat('ko-KR').format(amount);
}

export async function openInfoPage (pageNo: string) {
    const pageUrl = config.infoseedApiUrl + "/v1/page";
    const access_token  = sessionStorage.getItem('infoseed_token');
    
    try {
        const response = await fetch(pageUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${access_token}`,
                PAGE_CD : pageNo
            },
        });
    
        if (response.ok) {
            const data = await response.json();
            console.log('info api 리턴 결과:', JSON.stringify(data, null, 2));

            window.open(data.data.URL);
            
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
    } catch(e) {
        alert("페이지 연동시 오류가 발생하였습니다.");
    }
    
}

export async function moveInfoPage (pageUrl: string) {
    alert(pageUrl);
    const access_token  = sessionStorage.getItem('infoseed_token');
    
    try {
        const response = await fetch(pageUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        
    
        if (response.ok) {
            const data = await response.text();
            
            console.log('info api 리턴 결과:', JSON.stringify(data, null, 2));

            const newWindow = window.open(pageUrl);
            if (newWindow) {
                newWindow.document.write('<pre>' + data + '</pre>');
                newWindow.document.close();
            }
            
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
    } catch(e) {
        alert("페이지 연동시 오류가 발생하였습니다.");
    }
    
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
