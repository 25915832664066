import { GET_INFOSEED_GALLERY, GET_MAPPRIME_GALLERY, GET_NEOSPECTRA_GALLERY, getInfoseedGalleryAsync, getMapprimeGalleryAsync, getNeospectraGalleryAsync } from './actions';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';
import { getInfoseedGallery, getMapprimeGallery, getNeospectraGallery } from '../../api/project';

const getMapprimeProjsSaga = createAsyncSaga(getMapprimeGalleryAsync, getMapprimeGallery);
const getNeospectraProjsSaga = createAsyncSaga(getNeospectraGalleryAsync, getNeospectraGallery);
const getInfoseedProjsSaga = createAsyncSaga(getInfoseedGalleryAsync, getInfoseedGallery);


export function* projectsSaga() {
  yield takeEvery(GET_MAPPRIME_GALLERY, getMapprimeProjsSaga);
  yield takeEvery(GET_NEOSPECTRA_GALLERY, getNeospectraProjsSaga);
  yield takeEvery(GET_INFOSEED_GALLERY, getInfoseedProjsSaga);
}
