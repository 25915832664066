import { getNoticesAsync , getNoticeAsync, GET_NOTICES, GET_NOTICE_BY_ID, GET_FAQS } from './actions';
import {  getContactUsListAsync , getContactUsAsync , postContactUsAsync , putContactUsAsync , deleteContactUsAsync , GET_CONTACT_US , GET_CONTACT_US_BY_ID , POST_CONTACT_US , PUT_CONTACT_US , DELETE_CONTACT_US} from './actions';

import { getNotice, getNotices , getFaqs , getContactUsList , getContactUs , postContactUs , putContactUs , deleteContactUs} from '../../api/support';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../lib';

const getNoticesSaga = createAsyncSaga(getNoticesAsync, getNotices);
const getNoticeByIdSaga = createAsyncSaga(getNoticeAsync, getNotice);
const getFaqsSaga = createAsyncSaga(getNoticesAsync, getFaqs);

const getContactUsListSaga = createAsyncSaga(getContactUsListAsync , getContactUsList);
const getContactUsSaga     = createAsyncSaga(getContactUsAsync     , getContactUs);
const postContactUsSaga    = createAsyncSaga(postContactUsAsync    , postContactUs);
const putContactUsSaga     = createAsyncSaga(putContactUsAsync     , putContactUs);
const deleteContactUsSaga  = createAsyncSaga(deleteContactUsAsync  , deleteContactUs);

export function* supportSaga() {
  yield takeEvery(GET_NOTICES       , getNoticesSaga);
  yield takeEvery(GET_NOTICE_BY_ID  , getNoticeByIdSaga);

  yield takeEvery(GET_FAQS, getFaqsSaga);

  yield takeEvery(GET_CONTACT_US       , getContactUsListSaga);
  yield takeEvery(GET_CONTACT_US_BY_ID , getContactUsSaga);
  yield takeEvery(POST_CONTACT_US      , postContactUsSaga);
  yield takeEvery(PUT_CONTACT_US       , putContactUsSaga);
  yield takeEvery(DELETE_CONTACT_US    , deleteContactUsSaga);
   

}

