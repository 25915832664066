import { ChangeEvent, FC, useEffect, useState } from "react";
import Banners from "../../containers/banner";
import UserTypeSelection from './UserTypeSelection';
import TermsAgreement from './TermsAgreement';
import IdentityVerification from './IdentityVerification';
import UserInfoForm from './UserInfoForm';
import RegistrationComplete from './RegistrationComplete';
import StepNavigation from './StepNavigation';
import StepProgress from './StepProgress';
import { UserDto } from "../../types/UserDto";
import TestDialog from "./TestDiglog";
import { useLocation, useSearchParams } from "react-router-dom";
import { EncryptionService } from "../../types/EncryptedData";

interface PageProps {
    title: string;
}
const RegistrationForm: FC<PageProps> = ({ title }) => {
    const encryptionService = new EncryptionService();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [searchParams] = useSearchParams();
    // 현재 진행 단계 상태
    const [step, setStep] = useState(1);
    const [userName, setUserName] = useState('');
    const [userDto, setFormData] = useState<UserDto>({
        userId: '',
        userName: '',
        email: '',
        password: '',
        rePassword: '',
        userRoleCd: 'ADMIN',
        ognzName: '',
        ognzId: '',
        ognzUserGrpSn: '',
        roleCd: 'ADMIN',
    });
    const totalSteps = 3;

    const nextStep = (name?: string) => {
        if (name) {
            setUserName(name);
        }
        setStep(step + 1);
    };

    const prevStep = () => setStep(step - 1);

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | { target: { name: string; value: string } }
    ) => {
        const { name, value } = e.target;
        setFormData((prevUserDto) => ({
            ...prevUserDto,
            [name]: value,
        }));
    };

    const setUserDto = (updateDto: UserDto) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            ...updateDto,
        }));
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return <TermsAgreement nextStep={nextStep} prevStep={prevStep} />;
            case 2:
                return <UserInfoForm userDto={userDto} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />;
            case 3:
                return <RegistrationComplete userName={userName} />;
            default:
                return <h2>Something went wrong!</h2>;
        }
    };

    const [isOpenTest, setIsProjectCreateOpen] = useState(false);

    const openDialog = () => {
        setIsProjectCreateOpen(true);
    };
    const closeDialog = () => {
        setIsProjectCreateOpen(false);
    };

    useEffect(() => {
        const key = query.get('key');
        if (key) {
            try {
                // 복호화된 데이터를 UTF-8로 변환
                const decryptedData = encryptionService.decrypt(key);

                console.log(decryptedData);
                setUserDto({
                    ...userDto,
                    ognzUserGrpSn: decryptedData!.ognzGrpSn,
                    ognzId: decryptedData!.ognzId,
                    ognzName: decryptedData!.ognzNm,
                    userRoleCd: decryptedData!.userPermissions,
                    roleCd: 'USER'
                });
            } catch (error) {
                console.error("Error decrypting key:", error);
            }
        }
    }, []);

    return (
        <>
            <Banners title={title} />
            <StepNavigation currentStep={step} totalSteps={totalSteps} />
            <StepProgress currentStep={step} totalSteps={totalSteps} />
            {renderStep()}

            {/* <button onClick={openDialog}>테스트</button> */}
            <TestDialog isOpen={isOpenTest} onClose={closeDialog} />

        </>
    )
}

export default RegistrationForm;