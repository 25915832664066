import { FC, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Banners from "../../containers/banner";
import TabComponent from '../../components/Tab';
import './product.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import Product from '../../components/Product'
import { loadTossPayments, ANONYMOUS , TossPaymentsWidgets } from "@tosspayments/tosspayments-sdk";
import { CreateBuyDto } from "../../types/CreateBuyDto";
import { useNavigate } from 'react-router-dom';
import { postBuyAsync , getMapprimeDashboardAsync , getBuyUseAsync } from '../../modules/product';
import { useQuery } from '../common/stringUtil';

interface PageProps {
    title: string;
}

const tabs = [
    { id: 1, title: '영상촬영 서비스'           , control: 'content-1' , key: 'GEO_NIC' },
    { id: 2, title: 'AI 데이터 생성관리 서비스' , control: 'content-2' , key: 'SIMMETA' },
    { id: 3, title: '디지털트윈 지도 서비스'    , control: 'content-3' , key: 'MAPPRIME' },
];

const ProductGuide: FC<PageProps> = ({ title }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const query = useQuery();
    const tabSn = Number(query.get('tab')); // 쿼리 파라미터에서 id 값을 가져옵니다.

    const [currentTab, setCurrentTab] = useState<number>(tabs[tabSn].id);
    const [sltnId, setSltnId] = useState<string>(tabs[tabSn].key);
    const [buyYn, setBuyYn]   = useState<string>("none");
    const [widgets, setWidgets] = useState<TossPaymentsWidgets | null>(null);

    const buyInfo = useSelector((state:RootState) => state.product.buyInfo);
    const buyUser = useSelector((state:RootState) => state.product.buyUser);

    const [createBuyDto, setCreateBuyDto] = useState<CreateBuyDto>({
        stlmAmt : 0,
        customerMobilePhone : ""
    });

    const handleTabClick = (id: number , title:string) => {

        setSltnId(tabs[id-1].key);

        setCurrentTab(id);

        setBuyYn("none");
    };

    useEffect(() => {
        const access_token = sessionStorage.getItem('access_token');
        const userRoleCd   = sessionStorage.getItem('userRoleCd');
        
        const fetchBuyInfo = async () => {
            await dispatch(getBuyUseAsync.request(sltnId));
        };

        if (access_token && userRoleCd && userRoleCd == "ADMIN") {
            fetchBuyInfo();
        }

    }, [sltnId]);

    const setBuyProduct = (prdctSn: number , prdctNm:string , month : number , payment : number , widgets : TossPaymentsWidgets | null, customerKey : string) => {
       
        setWidgets(widgets);

        var scrtnTypeCd = "M"; 
        if (month == 12) {
            scrtnTypeCd = "Y"; 
        }

        const access_token = sessionStorage.getItem('access_token');
        const userRoleCd   = sessionStorage.getItem('userRoleCd');

        if (buyUser && buyUser.data && buyUser.data.data) {
            setBuyYn("none");
        } else {
            if (access_token && userRoleCd && userRoleCd == "ADMIN" && payment > 0) {
                setBuyYn("block");
            } else {
                setBuyYn("none");
            }

            setCreateBuyDto((prev) => ({ ...prev,
                stlmNm : prdctNm
              , prdctSn: prdctSn
              , scrtnTypeCd :scrtnTypeCd
              , stlmAmt : payment
              , stlmCustKey : customerKey
          }));
        }

        
        
    }

    // input 객체 변경시 
    const handleKeyUp = (event:React.KeyboardEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        input.value = input.value.replace(/[^0-9]/g, '');
    };

    const inputHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCreateBuyDto((prev) => ({ ...prev, [name]: value}));
    };

    const buyAct = () => {
        
        const access_token = sessionStorage.getItem('access_token');
        
        if (!access_token) {
            alert("로그인 후 결제가 가능합니다.");
            navigate('/login') 
        }
       
        if (createBuyDto == null) {
            alert("구매할 상품을 선택해 주십시오.");
        } else if (createBuyDto.prdctSn == null || createBuyDto.prdctSn == 0) {
            alert("구매할 상품을 선택해 주십시오.");
        } else if (createBuyDto.stlmAmt > 0 && (createBuyDto.customerMobilePhone == null || createBuyDto.customerMobilePhone == "")) {
            alert("결제 결과를 받을 핸드폰 번호를 입력해 주십시오.");
        } else if (createBuyDto.stlmAmt == 0) {
            navigate('/contact') 
        } else {
            
            const fetchBuyInfo = async () => {
                await dispatch(postBuyAsync.request(createBuyDto)); 
            };
    
            fetchBuyInfo();
        }
    }

    useEffect(() => {
        // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
        // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
        try {
            // console.log('createBuyDto parameter 결과:', JSON.stringify(createBuyDto, null, 2));

            if (widgets != null) {
                if (buyInfo && buyInfo.data ) {
                    
                    if (buyInfo.data.status == "error") {
                        if (buyInfo.data.error) {
                            alert(buyInfo.data.error.message);
                            return;
                        }
                    }
                    
                    
                    const orderId: string | undefined = buyInfo.data.data.stlmId as string;

                    if (createBuyDto) {
                        widgets.requestPayment({
                            orderId: orderId,
                            orderName: buyInfo.data.data.stlmNm,
                            successUrl: window.location.origin + "/buySuccess",
                            failUrl: window.location.origin + "/buyFail",
                            customerEmail: buyInfo.data.data.customerEmail,
                            customerName: buyInfo.data.data.customerName,
                            customerMobilePhone: createBuyDto.customerMobilePhone?.replaceAll("-",""),
                        });
                    }
                    
                }  
            }
        } catch (error) {
            // 에러 처리하기
            console.error(error);
        }
      }, [buyInfo]);


    return (
        <div className="features">
            <Banners title={title} />
            <div className='row'>
                <div className="container">
                    <TabComponent
                        tabs={tabs}
                        role='tab'
                        current={currentTab}
                        onClick={(index, tab) => handleTabClick(tab.id,tab.title)}
                    />
                    <div className="tabpanel" id='content-1' role='tabpanel' hidden={currentTab !== 1}>
                        <dl className='product-dl mb50'>
                            <dt>고객님과 함께하는 <span>요금제</span></dt>
                            <dd>
                                비행촬영 및 현장조사사진 촬영과 관리를 위한 서비스를 소개합니다.
                            </dd>
                        </dl>
                    </div>
                    <div className="tabpanel" id='content-2' role='tabpanel' hidden={currentTab !== 2}>
                        <dl className='product-dl mb50'>
                            <dt>고객님과 함께하는 <span>요금제</span></dt>
                            <dd>
                                기업의 업무 환경에 맞게 사용할 수 있도록 다양한 상품을 제공합니다.
                            </dd>
                        </dl>
                    </div>
                    <div className="tabpanel" id='content-3' role='tabpanel' hidden={currentTab !== 3}>
                        <dl className='product-dl mb50'>
                            <dt>고객님과 함께하는 <span>요금제</span></dt>
                            <dd>
                                영상정보 관리 및 분석 서비스를 위한 다양한 모델을 빠르고 효과적으로 구축할 수 있는 서비스를 소개합니다.
                            </dd>
                        </dl>
                    </div>

                    <Product sltnId={sltnId} compAmt={0} buyPrdctId="" setBuyProduct={setBuyProduct}  />

                    {/* 결제 UI */}
                    
                    
                        <div style={{display:buyYn}}>
                            <div id="payment-method" style={{display: createBuyDto.stlmAmt > 0 ? "" : "none" }} />
                            {createBuyDto.stlmAmt > 0 ? (
                            <div id="inputPhone" style={{display: createBuyDto.stlmAmt > 0 ? "flex" : "none" }}>
                                <div style={{paddingLeft:"30px",paddingTop: "13px"}}>
                                    <span style={{color:"red"}}><b> * </b></span>핸드폰 번호
                                </div>
                                <div style={{paddingLeft:"10px"}}>
                                    <input type="number" className="form-control " id="customerMobilePhone" placeholder="핸드폰 번호를 숫자만 입력해 주세요." name="customerMobilePhone" style={{width:"300px"}} value={createBuyDto.customerMobilePhone} onChange={inputHandleChange} onKeyUp={handleKeyUp} required />
                                </div>
                            </div>) : null }
                            {/* 이용약관 UI */}
                            <div id="agreement" style={{display: createBuyDto.stlmAmt > 0 ? "" : "none" }} />
                            <div className="flex justify-center" style={{marginTop: createBuyDto.stlmAmt > 0 ? "0px" : "50px" }}>
                                <button type="button" className="button button--outline-black button--round button--md" style={{ marginRight: "40px" }} onClick={buyAct}>{createBuyDto.stlmAmt > 0 ? "구매 하기" : "영업팀에 문의하기" }</button>
                            </div>
                        </div>
                    
            </div>
        </div>
    </div>
    );
};

export default ProductGuide;
